import { useEffect, useState, useRef } from "react";
import grapesjs from 'grapesjs';
import jobsIcon from "../../assets/images/Jobs.svg";

export default grapesjs.plugins.add('job-cards', (editor, options) => {
    editor.BlockManager.add('jobs', {
        label: `<div>Jobs Card</div>`,
        media: `<svg class="jobsIcon" width="64" height="84" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 7.33333V5.66667H10.6667V7.33333H14ZM5.66667 9V18.1667H19V9H5.66667ZM19 7.33333C19.925 7.33333 20.6667 8.075 20.6667 9V18.1667C20.6667 19.0917 19.925 19.8333 19 19.8333H5.66667C4.74167 19.8333 4 19.0917 4 18.1667L4.00833 9C4.00833 8.075 4.74167 7.33333 5.66667 7.33333H9V5.66667C9 4.74167 9.74167 4 10.6667 4H14C14.925 4 15.6667 4.74167 15.6667 5.66667V7.33333H19Z" fill="#808691"/>
        </svg>`,
        content: `<div style="height: auto" class="job-card-parent">
         
          <!-- Loading overlay -->
          <div draggable="false" id="lottie-container" class="lottie-container" style="position: fixed;width: 100%;height: 100%;top: 0;left: 0;display: flex;align-items: center;justify-content: center;z-index: 999999;background-color: rgba(0, 0, 0, 0.2);">
            <lottie-player  src="${process.env.REACT_APP_EDITOR_BASE_URL_GRAPEJS}/static/assets/loader.json"
                            background="transparent" 
                            speed="1" style="width: 15% !important;height: 15% !important;" loop autoplay
            >
            </lottie-player>
          </div>
        
          <!-- Content -->
          <div id="content" class="custom-job-card-content content-display" style="display: none;">
            <div id="jobs-preview" class="jobs-preview" style="display: none"></div>
<!--            Don't remove below div using for delete component-->
            <div id="delete-jobs" style="display: none"></div>
<!--            End -->

          <div id="jobs" class="jobs_section" style="font-family: 'Inter', sans-serif !important;overflow: hidden; margin-top: 45px; padding-left: 20px; padding-right: 20px; padding-bottom: 30px;">
          <div class="job_list static-jobs" style="display: flex; margin-top: 22px; overflow: hidden; padding-right: 15px;padding-left: 15px;margin-right: auto;margin-left: auto;">  
              <div className="jobs col" style="width: 422px;height: 253px;border-radius: 30px;background: #fff;box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);margin: 15px;">
                          <span style="cursor: pointer">
                              <div className="card-heading" style="display: flex !important;
                              padding-left: 30px !important;">
                                  <img src="https://i.ibb.co/c14gpC2/company-logo.png" alt="" style="height: 36px;
                                  width: 36px;
                                  margin-top: 30px;
                                  border: 1px solid #ebebeb;
                                  border-radius: 4px;"/>
                                  <div className='textEllipse' style=" background: none;font-style: normal;font-weight: 700;font-size: 24px;line-height: 150%;padding-left: 16px;overflow: hidden;text-overflow: ellipsis;display: block;white-space: nowrap;margin: 30px 0px 25px 0px;">Healthcare Executive</div>
                              </div> 
                              <div className="price" style="background: none;padding-left: 30px;font-weight: 400;font-size: 18px;line-height: 24px;letter-spacing: -0.5px;color: #3F3F46;margin-top: 0;margin-bottom: 20px;">$50 - $100 / hr</div><div className="city_tages" style="display: flex;background: none;justify-content: space-between;padding-left: 30px;padding-right: 25px;margin-top: -15px;">
                                  <div className="tags" style="background: none;
                                  padding-top: 15px;
                                  position: relative;">
                                  <div style="position: absolute;top: 15px;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 7C13 11.7613 8 14.5 8 14.5C8 14.5 3 11.7613 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                  </div>
                                      <p style="background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;margin-left: 22px;">Trafalgar</p>
                                  </div>
                                  <div className="tags" style="background: none;
                                  padding-top: 15px;
                                  position: relative;">
                                      <div style="position: absolute;top: 15px;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 13H13.5M1.5 1V13M8.5 1V13M12.5 4V13M3.5 3.5H4M3.5 5.5H4M3.5 7.5H4M6 3.5H6.5M6 5.5H6.5M6 7.5H6.5M3.5 13V10.75C3.5 10.336 3.836 10 4.25 10H5.75C6.164 10 6.5 10.336 6.5 10.75V13M1 1H9M8.5 4H13M10.5 6.5H10.5053V6.50533H10.5V6.5ZM10.5 8.5H10.5053V8.50533H10.5V8.5ZM10.5 10.5H10.5053V10.5053H10.5V10.5Z" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                      </div> 
                                          <p style="margin-left: 22px; background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;">Delhi, India</p>
                                  </div>
                                  <div className="tags" style="background: none;
                                  padding-top: 15px;
                                  position: relative;">
                                      <div style="position: absolute;top: 15px;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 8.43333V11.2667C12.5 11.996 11.9754 12.624 11.252 12.72C9.8607 12.9047 8.44137 13 7.00004 13C5.5587 13 4.13937 12.9047 2.74804 12.72C2.0247 12.624 1.50004 11.996 1.50004 11.2667V8.43333M12.5 8.43333C12.6583 8.29577 12.785 8.12557 12.8713 7.93442C12.9576 7.74328 13.0015 7.53572 13 7.326V4.804C13 4.08333 12.488 3.46067 11.7754 3.354C11.0202 3.24095 10.2613 3.15489 9.50004 3.096M12.5 8.43333C12.3707 8.54333 12.22 8.63 12.0514 8.68667C10.4222 9.22723 8.71656 9.50186 7.00004 9.5C5.2347 9.5 3.5367 9.214 1.9487 8.68667C1.78421 8.63195 1.63185 8.54592 1.50004 8.43333M1.50004 8.43333C1.34173 8.29577 1.21508 8.12557 1.12877 7.93442C1.04246 7.74328 0.998545 7.53572 1.00004 7.326V4.804C1.00004 4.08333 1.51204 3.46067 2.2247 3.354C2.97984 3.24094 3.73876 3.15489 4.50004 3.096M9.50004 3.096V2.5C9.50004 2.10218 9.342 1.72064 9.0607 1.43934C8.77939 1.15804 8.39786 1 8.00004 1H6.00004C5.60221 1 5.22068 1.15804 4.93938 1.43934C4.65807 1.72064 4.50004 2.10218 4.50004 2.5V3.096M9.50004 3.096C7.83585 2.96739 6.16422 2.96739 4.50004 3.096M7.00004 7.5H7.00537V7.50533H7.00004V7.5Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                      </div> 
                                      <p style="background: none;
                                      font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;margin-left: 22px;">Full time</p>
                                  </div>
                              </div>
                            </span>
                            <div className="button_group" style="width: 350px; display: flex;
                            background: none; padding-left: 30px !important;
                            margin-top: 15px !important;">
                                  <div className="button_all" style="display: inline-block; background: none;
                                  display: inline-flex;">
                                          <div className="skills" style="background: #F2F4F7;border-radius: 30px; text-decoration: none; align-items: center; font-weight: 400; font-size: 14px; padding: 5px 8px;
                                          color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">Nursing
                                          </div>
                                          <div className="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 400;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">Healthcare
                                          </div>
                                          <div className="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 400;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">Empathy
                                          </div>                             
                                    </div>
                        </div> 
                      </div>
                      <div className="jobs col" style="width: 422px;height: 253px;border-radius: 30px;background: #fff;box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);margin: 15px;">
                                  <span style="cursor: pointer">
                                      <div className="card-heading" style="display: flex !important;
                                      padding-left: 30px !important;">
                                          <img src="https://i.ibb.co/c14gpC2/company-logo.png" alt="" style="height: 36px;width: 36px;margin-top: 30px; border: 1px solid #ebebeb; border-radius: 4px;"/><div className='textEllipse' style=" background: none;font-style: normal;font-weight: 700;font-size: 24px;line-height: 150%;padding-left: 16px;overflow: hidden;text-overflow: ellipsis;display: block;white-space: nowrap;margin: 30px 0px 25px 0px;">Healthcare Executive
                                       </div>
                                      </div> 
                                      <div className="price" style="background: none;padding-left: 30px;font-weight: 400;font-size: 18px;line-height: 24px;letter-spacing: -0.5px;color: #3F3F46;margin-top: 0;margin-bottom: 20px;">$50 - $100 / hr</div><div className="city_tages" style="display: flex;background: none;justify-content: space-between;padding-left: 30px;padding-right: 25px;margin-top: -15px;">
                                          <div className="tags" style="background: none;
                                          padding-top: 15px;
                                          position: relative;">
                                          <div style="position: absolute;top: 15px;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 7C13 11.7613 8 14.5 8 14.5C8 14.5 3 11.7613 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                          </div> 
                                              <p style="background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;margin-left: 22px;">Trafalgar</p>
                                          </div>
                                          <div className="tags" style="background: none;
                                          padding-top: 15px;
                                          position: relative;">
                                              <div style="position: absolute;top: 15px;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 13H13.5M1.5 1V13M8.5 1V13M12.5 4V13M3.5 3.5H4M3.5 5.5H4M3.5 7.5H4M6 3.5H6.5M6 5.5H6.5M6 7.5H6.5M3.5 13V10.75C3.5 10.336 3.836 10 4.25 10H5.75C6.164 10 6.5 10.336 6.5 10.75V13M1 1H9M8.5 4H13M10.5 6.5H10.5053V6.50533H10.5V6.5ZM10.5 8.5H10.5053V8.50533H10.5V8.5ZM10.5 10.5H10.5053V10.5053H10.5V10.5Z" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/></svg></div> 
                                                  <p style="margin-left: 22px; background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;">Delhi, India</p>
                                          </div>
                                          <div className="tags" style="background: none;
                                          padding-top: 15px;
                                          position: relative;">
                                              <div style="position: absolute;top: 15px;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 8.43333V11.2667C12.5 11.996 11.9754 12.624 11.252 12.72C9.8607 12.9047 8.44137 13 7.00004 13C5.5587 13 4.13937 12.9047 2.74804 12.72C2.0247 12.624 1.50004 11.996 1.50004 11.2667V8.43333M12.5 8.43333C12.6583 8.29577 12.785 8.12557 12.8713 7.93442C12.9576 7.74328 13.0015 7.53572 13 7.326V4.804C13 4.08333 12.488 3.46067 11.7754 3.354C11.0202 3.24095 10.2613 3.15489 9.50004 3.096M12.5 8.43333C12.3707 8.54333 12.22 8.63 12.0514 8.68667C10.4222 9.22723 8.71656 9.50186 7.00004 9.5C5.2347 9.5 3.5367 9.214 1.9487 8.68667C1.78421 8.63195 1.63185 8.54592 1.50004 8.43333M1.50004 8.43333C1.34173 8.29577 1.21508 8.12557 1.12877 7.93442C1.04246 7.74328 0.998545 7.53572 1.00004 7.326V4.804C1.00004 4.08333 1.51204 3.46067 2.2247 3.354C2.97984 3.24094 3.73876 3.15489 4.50004 3.096M9.50004 3.096V2.5C9.50004 2.10218 9.342 1.72064 9.0607 1.43934C8.77939 1.15804 8.39786 1 8.00004 1H6.00004C5.60221 1 5.22068 1.15804 4.93938 1.43934C4.65807 1.72064 4.50004 2.10218 4.50004 2.5V3.096M9.50004 3.096C7.83585 2.96739 6.16422 2.96739 4.50004 3.096M7.00004 7.5H7.00537V7.50533H7.00004V7.5Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
                                              <p style="background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;margin-left: 22px;">Full time</p>
                                          </div>
                                      </div>
                                    </span>
                                    <div className="button_group" style="width: 350px; display: flex;
                                    background: none; padding-left: 30px !important;
                                    margin-top: 15px !important;">
                                          <div className="button_all" style="display: inline-block; background: none;
                                          display: inline-flex;">
                                                  <div className="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 400;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">Nursing
                                                  </div>
                                                  <div className="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 400;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">Healthcare
                                                  </div>
                                                  <div className="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 400;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">Empathy
                                                  </div>                             
                                            </div>
                                </div> 
                              </div>
                              <div className="jobs col" style="width: 422px;height: 253px;border-radius: 30px;background: #fff;box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);margin: 15px;">
                                          <span style="cursor: pointer">
                                              <div className="card-heading" style="display: flex !important;
                                              padding-left: 30px !important;">
                                                  <img src="https://i.ibb.co/c14gpC2/company-logo.png" alt="" style="height: 36px;width: 36px;margin-top: 30px; border: 1px solid #ebebeb; border-radius: 4px;"/><div className='textEllipse' style=" background: none;font-style: normal;font-weight: 700;font-size: 24px;line-height: 150%;padding-left: 16px;overflow: hidden;text-overflow: ellipsis;display: block;white-space: nowrap;margin: 30px 0px 25px 0px;">Healthcare Executive
                                               </div>
                                              </div> 
                                              <div className="price" style="background: none;padding-left: 30px;font-weight: 400;font-size: 18px;line-height: 24px;letter-spacing: -0.5px;color: #3F3F46;margin-top: 0;margin-bottom: 20px;">$50 - $100 / hr</div><div className="city_tages" style="display: flex;background: none;justify-content: space-between;padding-left: 30px;padding-right: 25px;margin-top: -15px;">
                                                  <div className="tags" style="background: none;
                                                  padding-top: 15px;
                                                  position: relative;">
                                                      <div style="position: absolute;top: 15px;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 7C13 11.7613 8 14.5 8 14.5C8 14.5 3 11.7613 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                                      </div> 
                                                      <p style="background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;margin-left: 22px;">Trafalgar</p>
                                                  </div>
                                                  <div className="tags" style="background: none;
                                                  padding-top: 15px;
                                                  position: relative;">
                                                      <div style="position: absolute;top: 15px;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 13H13.5M1.5 1V13M8.5 1V13M12.5 4V13M3.5 3.5H4M3.5 5.5H4M3.5 7.5H4M6 3.5H6.5M6 5.5H6.5M6 7.5H6.5M3.5 13V10.75C3.5 10.336 3.836 10 4.25 10H5.75C6.164 10 6.5 10.336 6.5 10.75V13M1 1H9M8.5 4H13M10.5 6.5H10.5053V6.50533H10.5V6.5ZM10.5 8.5H10.5053V8.50533H10.5V8.5ZM10.5 10.5H10.5053V10.5053H10.5V10.5Z" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
                                                          <p style="margin-left: 22px; background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;">Delhi, India</p>
                                                  </div>
                                                  <div className="tags" style="background: none;
                                                  padding-top: 15px;
                                                  position: relative;">
                                                      <div style="position: absolute;top: 15px;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 8.43333V11.2667C12.5 11.996 11.9754 12.624 11.252 12.72C9.8607 12.9047 8.44137 13 7.00004 13C5.5587 13 4.13937 12.9047 2.74804 12.72C2.0247 12.624 1.50004 11.996 1.50004 11.2667V8.43333M12.5 8.43333C12.6583 8.29577 12.785 8.12557 12.8713 7.93442C12.9576 7.74328 13.0015 7.53572 13 7.326V4.804C13 4.08333 12.488 3.46067 11.7754 3.354C11.0202 3.24095 10.2613 3.15489 9.50004 3.096M12.5 8.43333C12.3707 8.54333 12.22 8.63 12.0514 8.68667C10.4222 9.22723 8.71656 9.50186 7.00004 9.5C5.2347 9.5 3.5367 9.214 1.9487 8.68667C1.78421 8.63195 1.63185 8.54592 1.50004 8.43333M1.50004 8.43333C1.34173 8.29577 1.21508 8.12557 1.12877 7.93442C1.04246 7.74328 0.998545 7.53572 1.00004 7.326V4.804C1.00004 4.08333 1.51204 3.46067 2.2247 3.354C2.97984 3.24094 3.73876 3.15489 4.50004 3.096M9.50004 3.096V2.5C9.50004 2.10218 9.342 1.72064 9.0607 1.43934C8.77939 1.15804 8.39786 1 8.00004 1H6.00004C5.60221 1 5.22068 1.15804 4.93938 1.43934C4.65807 1.72064 4.50004 2.10218 4.50004 2.5V3.096M9.50004 3.096C7.83585 2.96739 6.16422 2.96739 4.50004 3.096M7.00004 7.5H7.00537V7.50533H7.00004V7.5Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
                                                      <p style="background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;display: inline;margin-left: 22px;">Full time</p>
                                                  </div>
                                              </div>
                                            </span>
                                            <div className="button_group" style="width: 350px; display: flex;
                                            background: none; padding-left: 30px !important;
                                            margin-top: 15px !important;">
                                                  <div className="button_all" style="display: inline-block; background: none;
                                                  display: inline-flex;">
                                                          <div className="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 400;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">
                                                          Nursing
                                                          </div>
                                                          <div className="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 400;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">
                                                          Healthcare
                                                          </div>
                                                          <div className="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 400;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;">Empathy
                                                          </div>                             
                                                    </div>
                                        </div> 
                                      </div>
                      </div> 
                      </div>
                      <div id="job-list-live" class="job-list-live"></div>
          </div>
          <script class="job-list-script" type="text/javascript" myscript>
            
            var job_card_count_str = "";
            if (document.getElementsByClassName("custom-job-card-content")?.length > 1) {
                job_card_count_str = "-" + document.getElementsByClassName("custom-job-card-content")?.length
            }
            console.log("job_card_count_str", job_card_count_str)
            console.log("len", document.getElementsByClassName("custom-job-card-content")?.length)
            
            
            // Load the script
              var script = document.createElement("SCRIPT");
              script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js';
              script.type = 'text/javascript';
              
            var lottieScript = document.createElement("SCRIPT");
            lottieScript.src = 'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js';
            lottieScript.type = 'text/javascript';
            document.getElementsByTagName("head")[0].appendChild(lottieScript);

            setTimeout(function() { 
                getJobsList();
            }, 2000);
            
              if(document.getElementsByClassName("jobsPreview")?.length == 0 && document.getElementById("live-jobs-check") == undefined){
                  for (let i = 0; i <  document.getElementsByClassName("job-list-live").length; i++) {
                        document.getElementsByClassName("job-list-live")[i].style.display = "none";
                  }
              } else {
                  for (let i = 0; i <  document.getElementsByClassName("job-list-live").length; i++) {
                        document.getElementsByClassName("job-list-live")[i].style.display = "block";
                  }
                  for (let i = 0; i <  document.getElementsByClassName("jobs_section").length; i++) {
                        document.getElementsByClassName("jobs_section")[i].style.display = "none";
                  }
                  
                  // On 2nd time all this content is going inside of first content divs
                  // document.getElementsByClassName("jobs_section")[0].style.display = "block";                  
                  for (let i = 0; i <  document.getElementsByClassName("static-jobs").length; i++) {
                        document.getElementsByClassName("static-jobs")[i].style.display = "none";
                  }
              }
          
          function getFormattedWorkType(type) {
            let formatted_workType = [];
            if (type?.length) {
              if (type.includes("FT")) {
                formatted_workType.push("Full Time");
              }
              if (type.includes("PT")) {
                formatted_workType.push("Part Time");
              }
              if (type.includes("FL")) {
                formatted_workType.push("Contract");
              }
              if (type.includes("IN")) {
                formatted_workType.push("Internship");
              }
              if (type.includes("CG")) {
                formatted_workType.push("Contingent");
              }
              if (type.includes("TP")) {
                formatted_workType.push("Temporary");
              }
              if (type.includes("AP")) {
                formatted_workType.push("Apprentice");
              }
              if (type.includes("OT")) {
                formatted_workType.push("Other");
              }
              if (formatted_workType.length > 1) {
                formatted_workType = formatted_workType.slice(0, 1);
              }
              return formatted_workType.join(", ");
            } else {
              return "-";
            }
          };
          function getLocation(job) {
            let location = "";
            if (job.city && job.state && job.country) {
              location = job.city + ", " + job.state + ", " + job.country;
            } else if (job.city && job.state && (job.country == null || job.country == "")) {
              location = job.city + ", " + job.state;
            } else if (job.state && job.country && (job.city == null || job.city == "")) {
              location = job.state + ", " + job.country;
            } else if (job.city && job.country && (job.state == null || job.state == "")) {
              location = job.city + ", " + job.country;
            } else if (job.state && (job.country == null || job.country == "") && (job.city == null || job.city == "")) {
              location = job.state;
            } else if (job.country && (job.city == null || job.city == "") && (job.state == null || job.state == "")) {
              location = job.country;
            } else if (job.city && (job.country == null || job.country == "") && (job.state == null || job.state == "")) {
              location = job.city;
            }
            return location;
          }
          function popuphandler1(id) {
            var details = document.getElementById('details-popup'+id);
            console.log(details);
            document?.addEventListener('click', function(event) {
              if (!details.contains(event.target)) {
                details.removeAttribute('open');
              }
            });
            details?.addEventListener('click', function(event) {
              event.stopPropagation();
            });
          }
          function getCards() {
              var $ = window.jQuery;
              document.addEventListener('click', function (e) {
                $("details").on("click", function () {
                  $("details[open]").not(this).removeAttr("open");
                });

                  if (e.target.parentElement?.id.length > 20 || e.target?.id.length > 20 || e.target.id == "see-all-jobs"){
                      let preivew_api_url = "${process.env.REACT_APP_CANDIDATE_PORTAL_URL}"
                      let publish_api_url = document.querySelector('meta[name="publish"]')
                      let url = preivew_api_url ? preivew_api_url : publish_api_url.content
                      if(e.target.id == "see-all-jobs" + job_card_count_str) {
                        e.target.href = url + "/job-list?paramsKey=${localStorage.getItem('encoded_domain')}"
                        // window.open(url + "/job-list?paramsKey=${localStorage.getItem('encoded_domain')}")
                      } else{
                        if(document.getElementsByClassName("search")[0]?.value == "" || document.getElementsByClassName("search")[0] == undefined) {
                          var el = e.target;
                          while (el.parentElement && el.tagName != 'A') {
                            el = el.parentElement;
                          }
                          el.href = url +'/job/'+ (e.target.id ? e.target.id : e.target.parentElement.id) + "?paramsKey=${localStorage.getItem('encoded_domain')}"
                         // window.open(url +'/job/'+ (e.target.id ? e.target.id : e.target.parentElement.id) + "?paramsKey=${localStorage.getItem('encoded_domain')}")
                      }
                      }
                  }
              });

            
              let str = '<div id="dynamic-jobs'+job_card_count_str+'" style="overflow: hidden; margin-top: 45px; padding-left: 10px; padding-right: 10px; padding-bottom: 30px;border-radius: 30px; border: 1px solid #eae9f2;background: ${localStorage.getItem("secondaryColor") ? localStorage.getItem("secondaryColor") : '#fff'};"><div class="job_list static-jobs" style="display: flex; margin-top: 22px; padding-right: 15px;padding-left: 15px;margin-right: auto;margin-left: auto; flex-wrap: wrap;">';
              $.ajax({
                  url: "${process.env.REACT_APP_API_URL.replace("api/v1", "public/api/v1")}candidates/jobs/0/6?validDomainNames=${localStorage.getItem('validDomainNames')}&domain=${localStorage.getItem("appOrigin") === "N" ? "2" : localStorage.getItem("appOrigin") === "H" ? "1" : "0"}&",
                  success: function(result) {
                      
                      
                      for (let i = 0; i <  document.getElementsByClassName("lottie-container").length; i++) {
                            document.getElementsByClassName("lottie-container")[i].style.display = "none";
                      }
                      
                      
                      for (let i = 0; i <  document.getElementsByClassName("content-display").length; i++) {
                            document.getElementsByClassName("content-display")[i].style.display = "block";
                      }
                      
                      if (document.getElementsByClassName("jobsPreview")?.length != 0 && document.getElementById("live-jobs-check") != undefined){
                          // On 2nd time all this content is going inside of first content divs
                          document.getElementsByClassName("jobs_section")[0].style.display = "block";                  
                          for (let i = 0; i <  document.getElementsByClassName("static-jobs").length; i++) {
                                document.getElementsByClassName("static-jobs")[i].style.display = "none";
                          }
                      }
   
                    
                    if (result.responsePayload?.length > 0) {
                      var jobs = result.responsePayload.map((job, index) => {
                          let logo_url = job['enterprisePublicDto'].logoFileSigned ? job['enterprisePublicDto'].logoFileSigned : "https://daas-prog-test.s3.amazonaws.com/default-logo.png"
                          str += '<div key='+index+' class="jobs-col cards" name="jobs-col"><a style="text-decoration: none;color:black" target="_blank" id='+job.id+' href="#"><div id='+job.id+' class="job-cards" style="border-radius: 30px;border: 1px solid #eae9f2;background: #fff;box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4); margin: 5px 10px;"><span class="jobClick" style="cursor: pointer"><div id='+job.id+' class="job-cards-heading" style="display: flex !important;padding-left: 15px !important; align-items: center;"><img id='+job.id+' src="'+logo_url+'" alt="" style="height: 36px; width: 36px; border: 1px solid #ebebeb; border-radius: 4px;"/><div id='+job.id+' style=" background: none;font-style: normal;font-weight: 700;font-size: 20px;line-height: 150%;padding-left: 16px;overflow: hidden;text-overflow: ellipsis;display: block;white-space: nowrap;width: 325px;margin: 20px 0px;color: black;" title="'+job.jobTitle+'">'+job.jobTitle+'</div></div><div id='+job.id+' class="job-cards_price" style="background: none;padding-left: 30px;font-weight: 400;font-size: 18px;line-height: 24px;letter-spacing: -0.5px;color: #3F3F46;margin-top: 0;margin-bottom: 20px;">'
                          if((job.budgetMinimumHourlyBasis == 0 && job.budgetMaximumHourlyBasis == 0) || (!job.budgetMinimumHourlyBasis && !job.budgetMaximumHourlyBasis)){
                            str += '</div><div id='+job.id+' class="job-cards_city_tages" style="display: flex;background: none;justify-content: flex-start;padding-left: 15px;padding-right: 5px;margin-top: -15px;"><div id='+job.id+' class="tags" style="background: none;padding-top: 15px;position: relative;display: flex;align-items: center;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 13H13.5M1.5 1V13M8.5 1V13M12.5 4V13M3.5 3.5H4M3.5 5.5H4M3.5 7.5H4M6 3.5H6.5M6 5.5H6.5M6 7.5H6.5M3.5 13V10.75C3.5 10.336 3.836 10 4.25 10H5.75C6.164 10 6.5 10.336 6.5 10.75V13M1 1H9M8.5 4H13M10.5 6.5H10.5053V6.50533H10.5V6.5ZM10.5 8.5H10.5053V8.50533H10.5V8.5ZM10.5 10.5H10.5053V10.5053H10.5V10.5Z" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/></svg><p style="background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;overflow: hidden;width: 90px;text-overflow: ellipsis;white-space: nowrap;margin-top:0px;margin-bottom: 0px;margin-left: 5px;padding-right: 5px;padding-bottom: 0px !important;" title="'+job['enterprisePublicDto'].establishmentName+'">'+job['enterprisePublicDto'].establishmentName+'</p></div>'
                          } else {
                            str += '' + job.currency + ' ' +job.budgetMinimumHourlyBasis+' - '+job.budgetMaximumHourlyBasis+' / hour</div><div id='+job.id+' class="job-cards_city_tages" style="display: flex;background: none;justify-content: flex-start;padding-left: 15px;padding-right: 5px;margin-top: -15px;"><div id='+job.id+' class="tags" style="background: none;padding-top: 15px;position: relative;display: flex;align-items: center;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 13H13.5M1.5 1V13M8.5 1V13M12.5 4V13M3.5 3.5H4M3.5 5.5H4M3.5 7.5H4M6 3.5H6.5M6 5.5H6.5M6 7.5H6.5M3.5 13V10.75C3.5 10.336 3.836 10 4.25 10H5.75C6.164 10 6.5 10.336 6.5 10.75V13M1 1H9M8.5 4H13M10.5 6.5H10.5053V6.50533H10.5V6.5ZM10.5 8.5H10.5053V8.50533H10.5V8.5ZM10.5 10.5H10.5053V10.5053H10.5V10.5Z" stroke="#667085" stroke-linecap="round" stroke-linejoin="round"/></svg><p style="background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;overflow: hidden;width: 90px;text-overflow: ellipsis;white-space: nowrap;margin-top:0px;margin-bottom: 0px;margin-left: 5px;padding-right: 5px;padding-bottom: 0px !important;" title="'+job['enterprisePublicDto'].establishmentName+'">'+job['enterprisePublicDto'].establishmentName+'</p></div>'
                          }
                              if(job.country || job.city || job.state){
                                  str += '<div id='+job.id+' class="tags" style="background: none;padding-top: 15px;position: relative;padding-right: 5px;display: flex;align-items: center;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 7C10 7.53043 9.78929 8.03914 9.41421 8.41421C9.03914 8.78929 8.53043 9 8 9C7.46957 9 6.96086 8.78929 6.58579 8.41421C6.21071 8.03914 6 7.53043 6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 7C13 11.7613 8 14.5 8 14.5C8 14.5 3 11.7613 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg><p style="margin-left: 5px; background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;overflow: hidden;width: 90px;text-overflow: ellipsis;white-space: nowrap;margin-top:0px;margin-bottom: 0px;" title="'+getLocation(job)+'">'+getLocation(job)+'</p></div>'
                              }
                              str += '<div id='+job.id+' class="tags" style="background: none;padding-top: 15px;display: flex;align-items: center;"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5 8.43333V11.2667C12.5 11.996 11.9754 12.624 11.252 12.72C9.8607 12.9047 8.44137 13 7.00004 13C5.5587 13 4.13937 12.9047 2.74804 12.72C2.0247 12.624 1.50004 11.996 1.50004 11.2667V8.43333M12.5 8.43333C12.6583 8.29577 12.785 8.12557 12.8713 7.93442C12.9576 7.74328 13.0015 7.53572 13 7.326V4.804C13 4.08333 12.488 3.46067 11.7754 3.354C11.0202 3.24095 10.2613 3.15489 9.50004 3.096M12.5 8.43333C12.3707 8.54333 12.22 8.63 12.0514 8.68667C10.4222 9.22723 8.71656 9.50186 7.00004 9.5C5.2347 9.5 3.5367 9.214 1.9487 8.68667C1.78421 8.63195 1.63185 8.54592 1.50004 8.43333M1.50004 8.43333C1.34173 8.29577 1.21508 8.12557 1.12877 7.93442C1.04246 7.74328 0.998545 7.53572 1.00004 7.326V4.804C1.00004 4.08333 1.51204 3.46067 2.2247 3.354C2.97984 3.24094 3.73876 3.15489 4.50004 3.096M9.50004 3.096V2.5C9.50004 2.10218 9.342 1.72064 9.0607 1.43934C8.77939 1.15804 8.39786 1 8.00004 1H6.00004C5.60221 1 5.22068 1.15804 4.93938 1.43934C4.65807 1.72064 4.50004 2.10218 4.50004 2.5V3.096M9.50004 3.096C7.83585 2.96739 6.16422 2.96739 4.50004 3.096M7.00004 7.5H7.00537V7.50533H7.00004V7.5Z" stroke="#667085" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg><p style="background: none;font-weight: 400;font-size: 12px;line-height: 150%;color: #3F3F46;margin-left: 5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-top:0px;margin-bottom: 0px;margin-right: 5px;padding-bottom: 0px !important;" title="'+getFormattedWorkType(job?.jobType != null ? job?.jobType[0] : [])+'">'
                                if (job.jobType == null) {
                                  str += '-'
                                } else {
                                  str += getFormattedWorkType(job?.jobType != null ? job?.jobType[0] : [])
                                }
                                if(job?.jobType?.length > 1){
                                  let y = job.jobType?.length - 1
                                  //condition for dropdown width
                                  if(result?.responsePayload[2] == job || result?.responsePayload[5] == job){
                                    str += '<div style="cursor: pointer; color: ${localStorage.getItem("exclusivePrimaryColor") ? localStorage.getItem("exclusivePrimaryColor") : localStorage.getItem("primaryColor")}; font-size: 12px; font-weight: 600;"><details onclick="popuphandler1('+index+')" data-popover="down" id="details-popup'+index+'"><summary style="list-style: none;">+ '+y+' more</summary><div style="position: absolute;display: block;z-index: 1;border: solid 1px teal;border-radius: 5%;padding: 10px;background: white;">'
                                  } else {
                                    str += '<div style="cursor: pointer; color: ${localStorage.getItem("exclusivePrimaryColor") ? localStorage.getItem("exclusivePrimaryColor") : localStorage.getItem("primaryColor")}; font-size: 12px; font-weight: 600;"><details onclick="popuphandler1('+index+')" data-popover="down" id="details-popup'+index+'"><summary style="list-style: none;">+ '+y+' more</summary><div style="position: absolute;display: block;z-index: 1;border: solid 1px teal;border-radius: 5%;padding: 10px;background: white;width:15%;">'
                                  }
                                  str += '<p style="margin-top: 0px;color: #344054;font-size: 16px;">Work Type</p>'
                                   job?.jobType?.map((skill, index) => {
                                    str += '<span key='+index+' class="job-modal-skills" style="align-items: center;font-weight: 400;font-size: 14px;padding: 4px 3px;color: #363F72;margin-bottom: 10px;display: inline-block;">'+getFormattedWorkType(skill)+''
                                    if(job?.jobType?.length - 1 == index){
                                      str += '</span>'
                                    } else {
                                      str += ', </span>'
                                    }
                                    })
                                    str += '</div></details></div>'
                                }
                              str += '</div></div></span><div class="job-cards_button_group" style="display: flex;background: none; padding-left: 15px !important;margin-top: 15px !important; padding-bottom: 10px;">'
                               if(job && job?.jobskills?.length == 0 || (job?.jobskills?.length == 1 && job?.jobskills?.filter(skill => skill.skills.keyword?.toLowerCase() === "english")?.length != 0)) {
                                  str += '<div class="button_all" style="display: inline-block; background: none;display: inline-flex;"><p style="margin-top: 10px;"></p>'
                               }else{
                                  str += '<div class="button_all" style="display: inline-block; background: none;display: inline-flex;">'
                               } 
                              job?.jobskills?.map((skill, index) => {
                              if(index < 3){
                                if(skill.skills.keyword.length >= 13) {
                                  str += '<div key='+index+' class="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 500;font-size: 14px;padding: 3px 8px;color: #363F72;margin-bottom: 10px;display: inline;margin-right: 10px;height: 26px;margin-top: 15px;overflow: hidden;width: 90px;text-overflow: ellipsis; white-space: nowrap;" title="'+skill.skills.keyword+'">'+skill.skills.keyword+'</div>'
                                } else {
                                  if (skill.skills.keyword?.toLowerCase() != "english") {
                                  str += '<div key='+index+' class="skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 500;font-size: 14px;padding: 5px 8px;color: #363F72;margin-bottom: 10px;display: flex;margin-right: 10px;height: 26px;margin-top: 15px;align-items: center;" title="'+skill.skills.keyword+'">'+skill.skills.keyword+'</div>'
                                } 
                              }
                              }
                          })
                          str += '</div>'
                          let count = (job?.jobskills?.filter(skill => skill.skills.keyword?.toLowerCase() != "english"))?.length
                          if(job?.jobskills?.length > 3){
                            let x = count - (job?.jobskills?.filter(skill => skill.skills.keyword?.toLowerCase() === "english")?.length == 0 ? 3 : 2);
                            //condition for dropdown width
                            if(result?.responsePayload[2] == job || result?.responsePayload[5] == job){
                              str += '<span style="cursor: pointer; color: ${localStorage.getItem("exclusivePrimaryColor") ? localStorage.getItem("exclusivePrimaryColor") : localStorage.getItem("primaryColor")}; font-size: 12px; font-weight: 600; margin-top: 20px;"><details onclick="popuphandler1('+index+')" data-popover="down" id="details-popup'+index+'"><summary style="list-style: none;">+ '+x+' more</summary><div style="position: absolute;display: block;z-index: 1;border: solid 1px teal;border-radius: 5%;padding: 10px;background: white;right: 35px;width: 15%;">'
                            } else {
                              str += '<span style="cursor: pointer; color: ${localStorage.getItem("exclusivePrimaryColor") ? localStorage.getItem("exclusivePrimaryColor") : localStorage.getItem("primaryColor")}; font-size: 12px; font-weight: 600; margin-top: 20px;"><details onclick="popuphandler1('+index+')" data-popover="down" id="details-popup'+index+'"><summary style="list-style: none;">+ '+x+' more</summary><div style="position: absolute;display: block;z-index: 1;border: solid 1px teal;border-radius: 5%;padding: 10px;background: white;width: 15%;">'
                            }
                            if (job?.jobskills?.length > 8) {
                              str += '<p style="margin-top: 0px;color: #344054;font-size: 16px;">Skills</p><div style="height: 150px;overflow: auto;">'
                            } else {
                              str += '<p style="margin-top: 0px;color: #344054;font-size: 16px;">Skills</p>'
                            }
                             job?.jobskills?.map((skill, index) => {
                              if (skill.skills.keyword?.toLowerCase() != "english") {
                              str += '<span key='+index+' class="job-modal-skills" style="background: #F2F4F7;border-radius: 30px;text-decoration: none;align-items: center;font-weight: 500;font-size: 14px;padding: 6px 14px;color: #363F72;margin-bottom: 10px;display: inline-block;margin-right: 10px;">'+skill.skills.keyword+'</span>'
                              }
                              })
                              if (job?.jobskills?.length > 8) {
                                str += '</div>'
                              }
                              str += '</div></details></span>'
                          }
                          str += '</div></div></a></div>'
                      })
                      str += '</div>'
                      str += '<p style="display: flex;justify-content: center;margin-top: 50px;"><a target="_blank" href="#" id="see-all-jobs'+job_card_count_str+'" style="text-decoration: none;background-color: ${localStorage.getItem("exclusivePrimaryColor") ? localStorage.getItem("exclusivePrimaryColor") : localStorage.getItem("primaryColor")};border: none;color: white;padding: 15px 50px;text-align: center;text-decoration: none;display: flex;font-size: 16px;margin-left: auto;margin-right: auto;cursor: pointer;border-radius: 12px;">See All</a></p>'
                      str += '</div>'
                      // if(document.querySelector("#job-list-live"+job_card_count_str)?.childNodes.length == 0 || document.querySelector("#job-list-live"+job_card_count_str)?.childNodes[0]?.nodeType == 3){
                          $(".job-list-live").empty();
                          $(".job-list-live").append(str);
                          
                      // }
                  } else {
                    let img = '<div style="text-align:center;">'
                    img +='<svg width="300" height="300" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">'
                    img +='<path d="M500 382.4H0V382.65H500V382.4Z" fill="#EBEBEB"/>'
                    img +='<path d="M237 337.8H43.91C42.3974 337.797 40.9476 337.195 39.8789 336.124C38.8102 335.053 38.21 333.603 38.21 332.09V60.66C38.2232 59.156 38.8292 57.718 39.8965 56.6582C40.9637 55.5985 42.406 55.0026 43.91 55H237C238.514 55 239.967 55.6016 241.038 56.6724C242.108 57.7433 242.71 59.1956 242.71 60.71V332.09C242.71 333.604 242.108 335.057 241.038 336.128C239.967 337.198 238.514 337.8 237 337.8ZM43.91 55.2C42.4637 55.2026 41.0775 55.7791 40.0557 56.8027C39.0339 57.8264 38.46 59.2136 38.46 60.66V332.09C38.46 333.536 39.0339 334.924 40.0557 335.947C41.0775 336.971 42.4637 337.547 43.91 337.55H237C238.447 337.547 239.835 336.971 240.858 335.948C241.881 334.924 242.457 333.537 242.46 332.09V60.66C242.457 59.2127 241.881 57.8255 240.858 56.8021C239.835 55.7787 238.447 55.2026 237 55.2H43.91Z" fill="#EBEBEB"/>'
                    img +='<path d="M453.31 337.8H260.21C258.696 337.797 257.246 337.195 256.175 336.125C255.105 335.054 254.503 333.604 254.5 332.09V60.66C254.516 59.1551 255.124 57.7171 256.193 56.6576C257.262 55.5981 258.705 55.0025 260.21 55H453.31C454.812 55.0052 456.252 55.6022 457.317 56.6617C458.382 57.7212 458.987 59.1578 459 60.66V332.09C459 333.601 458.401 335.05 457.335 336.121C456.268 337.191 454.821 337.795 453.31 337.8ZM260.21 55.2C258.763 55.2026 257.375 55.7787 256.352 56.8021C255.329 57.8255 254.753 59.2127 254.75 60.66V332.09C254.753 333.537 255.329 334.924 256.352 335.948C257.375 336.971 258.763 337.547 260.21 337.55H453.31C454.757 337.547 456.145 336.971 457.168 335.948C458.191 334.924 458.767 333.537 458.77 332.09V60.66C458.767 59.2127 458.191 57.8255 457.168 56.8021C456.145 55.7787 454.757 55.2026 453.31 55.2H260.21Z" fill="#EBEBEB"/>'
                    img +='<path d="M77.28 391.92H51.28V392.17H77.28V391.92Z" fill="#EBEBEB"/>'
                    img +='<path d="M190.18 389.21H145.15V389.46H190.18V389.21Z" fill="#EBEBEB"/>'
                    img +='<path d="M139.07 401.21H78.28V401.46H139.07V401.21Z" fill="#EBEBEB"/>'
                    img +='<path d="M448.72 399.53H429.49V399.78H448.72V399.53Z" fill="#EBEBEB"/>'
                    img +='<path d="M420.58 399.53H365.29V399.78H420.58V399.53Z" fill="#EBEBEB"/>'
                    img +='<path d="M331.52 395.31H257.05V395.56H331.52V395.31Z" fill="#EBEBEB"/>'
                    img +='<path d="M264.87 274.51H222.82V382.4H264.87V274.51Z" fill="#F0F0F0"/>'
                    img +='<path d="M72.36 382.4H222.82V274.51H72.36V382.4Z" fill="#F5F5F5"/>'
                    img +='<path d="M81.58 371.89H106.69V349.92H81.58V371.89Z" fill="white"/>'
                    img +='<path d="M81.58 341.86H106.69V319.89H81.58V341.86Z" fill="#EBEBEB"/>'
                    img +='<g style="mix-blend-mode:multiply" opacity="0.7">'
                    img +='<path d="M264.86 288.44H71.91V292.77H264.86V288.44Z" fill="#E6E6E6"/>'
                    img +='</g>'
                    img +='<path d="M267.19 272.42H222.82V289.89H267.19V272.42Z" fill="#F0F0F0"/>'
                    img +='<path d="M70.02 289.89H222.81V272.42H70.02V289.89Z" fill="#F5F5F5"/>'
                    img +='<path d="M248.93 239.44H216.25V272.69H248.93V239.44Z" fill="#F0F0F0"/>'
                    img +='<path d="M102.45 272.68H216.24V239.43H102.45V272.68Z" fill="#F5F5F5"/>'
                    img +='<g style="mix-blend-mode:multiply" opacity="0.7">'
                    img +='<path d="M248.93 244.45H102.12V247.14H248.93V244.45Z" fill="#E6E6E6"/>'
                    img +='</g>'
                    img +='<path d="M250.73 237.81H216.25V245.58H250.73V237.81Z" fill="#F0F0F0"/>'
                    img +='<path d="M100.66 245.58H216.25V237.81H100.66V245.58Z" fill="#F5F5F5"/>'
                    img +='<path d="M287.41 382.4H319.4V220.52H287.41V382.4Z" fill="#E6E6E6"/>'
                    img +='<path d="M294.26 382.4H287.41V370.92H301.44L294.26 382.4Z" fill="#F0F0F0"/>'
                    img +='<path d="M394.13 382.4H426.12V220.52H394.13V382.4Z" fill="#E6E6E6"/>'
                    img +='<path d="M287.41 380.08H400.1V220.52H287.41V380.08Z" fill="#F0F0F0"/>'
                    img +='<path d="M292.73 271.36H394.79V227.6H292.73V271.36Z" fill="#E6E6E6"/>'
                    img +='<path d="M350.46 248.54C350.46 249.867 350.066 251.164 349.329 252.268C348.592 253.371 347.544 254.231 346.318 254.739C345.092 255.247 343.743 255.38 342.441 255.121C341.139 254.862 339.944 254.223 339.005 253.285C338.067 252.346 337.428 251.151 337.169 249.849C336.91 248.547 337.043 247.198 337.551 245.972C338.059 244.746 338.919 243.698 340.022 242.961C341.126 242.224 342.423 241.83 343.75 241.83C344.632 241.829 345.505 242.001 346.319 242.338C347.134 242.675 347.874 243.169 348.498 243.792C349.121 244.416 349.615 245.156 349.952 245.971C350.289 246.785 350.461 247.658 350.46 248.54Z" fill="#FAFAFA"/>'
                    img +='<path d="M393.25 382.4H400.1V370.92H386.07L393.25 382.4Z" fill="#F0F0F0"/>'
                    img +='<path d="M394.78 278.42H292.72V373.01H394.78V278.42Z" fill="#E6E6E6"/>'
                    img +='<path d="M380.69 299.23C384.396 299.23 387.4 296.226 387.4 292.52C387.4 288.814 384.396 285.81 380.69 285.81C376.984 285.81 373.98 288.814 373.98 292.52C373.98 296.226 376.984 299.23 380.69 299.23Z" fill="#FAFAFA"/>'
                    img +='<path d="M415.25 202.51V71.53L86.21 71.53V202.51L415.25 202.51Z" fill="#E6E6E6"/>'
                    img +='<path d="M413.8 202.5V71.52L84.76 71.52V202.5L413.8 202.5Z" fill="#F0F0F0"/>'
                    img +='<path d="M405.43 79.9H93.13V194.13H405.43V79.9Z" fill="white"/>'
                    img +='<path d="M129.54 127.5L118.56 146.53H140.53L129.54 127.5Z" fill="#E6E6E6"/>'
                    img +='<path d="M186.55 128.04H168.61V145.98H186.55V128.04Z" fill="#E6E6E6"/>'
                    img +='<path d="M225.62 125.54L213.55 134.3L218.16 148.49H233.07L237.68 134.3L225.62 125.54Z" fill="#E6E6E6"/>'
                    img +='<path d="M280 126.03H267.31L260.96 137.01L267.31 148H280L286.34 137.01L280 126.03Z" fill="#E6E6E6"/>'
                    img +='<path d="M321.69 124.95L311.77 129.73L309.32 140.47L316.18 149.07H327.19L334.06 140.47L331.61 129.73L321.69 124.95Z" fill="#E6E6E6"/>'
                    img +='<path d="M374.58 125.29H364.87L358 132.16V141.87L364.87 148.74H374.58L381.44 141.87V132.16L374.58 125.29Z" fill="#E6E6E6"/>'
                    img +='<path d="M250 427.56C357.082 427.56 443.89 422.492 443.89 416.24C443.89 409.988 357.082 404.92 250 404.92C142.917 404.92 56.11 409.988 56.11 416.24C56.11 422.492 142.917 427.56 250 427.56Z" fill="#F5F5F5"/>'
                    img +='<path d="M239.41 129.51H404.06C404.884 129.499 405.7 129.66 406.458 129.982C407.216 130.304 407.899 130.78 408.464 131.38C409.028 131.98 409.461 132.691 409.736 133.467C410.011 134.244 410.121 135.069 410.06 135.89L396.63 352.22C396.501 353.939 395.733 355.547 394.479 356.73C393.225 357.912 391.574 358.582 389.85 358.61H225.19C224.368 358.612 223.555 358.445 222.8 358.119C222.046 357.793 221.366 357.316 220.803 356.717C220.241 356.118 219.807 355.41 219.53 354.636C219.252 353.862 219.137 353.04 219.19 352.22L232.61 135.89C232.744 134.17 233.516 132.563 234.774 131.383C236.032 130.203 237.685 129.535 239.41 129.51Z" fill="#4E667D"/>'
                    img +='<path opacity="0.4" d="M239.41 129.51H404.06C404.884 129.499 405.7 129.66 406.458 129.982C407.216 130.304 407.899 130.78 408.464 131.38C409.028 131.98 409.461 132.691 409.736 133.467C410.011 134.244 410.121 135.069 410.06 135.89L396.63 352.22C396.501 353.939 395.733 355.547 394.479 356.73C393.225 357.912 391.574 358.582 389.85 358.61H225.19C224.368 358.612 223.555 358.445 222.8 358.119C222.046 357.793 221.366 357.316 220.803 356.717C220.241 356.118 219.807 355.41 219.53 354.636C219.252 353.862 219.137 353.04 219.19 352.22L232.61 135.89C232.744 134.17 233.516 132.563 234.774 131.383C236.032 130.203 237.685 129.535 239.41 129.51Z" fill="black"/>'
                    img +='<path d="M235.63 129.51H400.28C401.104 129.499 401.92 129.66 402.678 129.982C403.436 130.304 404.119 130.78 404.683 131.38C405.248 131.98 405.681 132.691 405.956 133.467C406.231 134.244 406.341 135.069 406.28 135.89L392.85 352.22C392.72 353.941 391.952 355.55 390.695 356.733C389.439 357.915 387.785 358.585 386.06 358.61H221.41C220.588 358.612 219.775 358.445 219.02 358.119C218.266 357.793 217.586 357.316 217.023 356.717C216.461 356.118 216.027 355.41 215.75 354.636C215.472 353.862 215.357 353.04 215.41 352.22L228.83 135.89C228.962 134.17 229.733 132.561 230.992 131.38C232.25 130.2 233.905 129.532 235.63 129.51Z" fill="#4E667D"/>'
                    img +='<path opacity="0.4" d="M224.34 346.93L384.61 348.85L397.61 139.33H237.22L224.34 346.93Z" fill="black"/>'
                    img +='<path d="M219.66 344.81H382C390.94 262.9 393.69 218.68 394.91 136.81H232.57C231.35 218.68 228.61 262.9 219.66 344.81Z" fill="white"/>'
                    img +='<path opacity="0.3" d="M375.49 193.75H347.54C347.258 193.753 346.979 193.697 346.721 193.585C346.462 193.473 346.23 193.308 346.04 193.1C345.847 192.895 345.698 192.652 345.602 192.387C345.506 192.123 345.464 191.841 345.48 191.56L347.21 163.62C347.243 163.096 347.474 162.603 347.857 162.243C348.239 161.883 348.745 161.681 349.27 161.68H377.27C377.551 161.681 377.83 161.739 378.088 161.851C378.346 161.963 378.579 162.127 378.771 162.332C378.964 162.537 379.113 162.779 379.209 163.043C379.305 163.308 379.346 163.589 379.33 163.87L377.59 191.82C377.581 192.085 377.519 192.346 377.409 192.587C377.298 192.829 377.14 193.045 376.945 193.225C376.749 193.405 376.52 193.544 376.27 193.634C376.02 193.724 375.755 193.763 375.49 193.75ZM349.74 189.63H373.55L375 165.81H351.21L349.74 189.63Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M372.84 236.32H344.9C344.619 236.319 344.341 236.261 344.083 236.149C343.825 236.038 343.593 235.874 343.4 235.67C343.206 235.466 343.056 235.223 342.959 234.958C342.863 234.693 342.822 234.411 342.84 234.13L344.57 206.18C344.603 205.656 344.835 205.165 345.218 204.807C345.6 204.448 346.106 204.249 346.63 204.25H374.58C374.862 204.247 375.141 204.303 375.399 204.415C375.658 204.527 375.89 204.692 376.08 204.9C376.273 205.105 376.422 205.348 376.518 205.613C376.614 205.877 376.656 206.159 376.64 206.44L374.9 234.38C374.869 234.905 374.639 235.399 374.256 235.76C373.873 236.12 373.366 236.321 372.84 236.32ZM347.09 232.19H370.9L372.38 208.37H348.57L347.09 232.19Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M370.2 278.88H342.26C341.977 278.881 341.697 278.824 341.437 278.712C341.178 278.6 340.944 278.436 340.75 278.23C340.555 278.027 340.405 277.784 340.31 277.519C340.216 277.253 340.178 276.971 340.2 276.69L341.93 248.75C341.963 248.226 342.194 247.733 342.577 247.373C342.959 247.013 343.465 246.811 343.99 246.81H371.94C372.221 246.811 372.499 246.869 372.757 246.981C373.015 247.092 373.247 247.256 373.44 247.46C373.631 247.666 373.779 247.909 373.876 248.173C373.972 248.438 374.014 248.719 374 249L372.26 277C372.215 277.515 371.978 277.993 371.597 278.342C371.215 278.69 370.717 278.882 370.2 278.88ZM344.45 274.76H368.26L369.74 250.94H345.93L344.45 274.76Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M367.56 321.45H339.62C339.338 321.449 339.058 321.391 338.799 321.279C338.539 321.167 338.305 321.004 338.11 320.8C337.915 320.597 337.765 320.354 337.67 320.089C337.576 319.823 337.538 319.541 337.56 319.26L339.29 291.31C339.325 290.787 339.558 290.298 339.94 289.939C340.322 289.581 340.826 289.381 341.35 289.38H369.3C369.582 289.377 369.861 289.433 370.12 289.545C370.378 289.657 370.61 289.822 370.8 290.03C370.993 290.235 371.142 290.478 371.238 290.743C371.334 291.007 371.376 291.289 371.36 291.57L369.62 319.51C369.587 320.034 369.356 320.527 368.973 320.887C368.591 321.247 368.086 321.449 367.56 321.45ZM341.81 317.32H365.62L367.1 293.5H343.29L341.81 317.32Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M259.88 174.06H323.17C324.112 174.045 325.015 173.679 325.701 173.034C326.387 172.388 326.808 171.509 326.88 170.57C326.913 170.121 326.853 169.67 326.703 169.245C326.552 168.821 326.316 168.432 326.007 168.104C325.699 167.776 325.326 167.515 324.911 167.339C324.497 167.163 324.05 167.075 323.6 167.08H260.32C259.377 167.09 258.471 167.455 257.784 168.101C257.097 168.748 256.678 169.629 256.61 170.57C256.577 171.018 256.637 171.468 256.787 171.892C256.936 172.316 257.172 172.704 257.48 173.032C257.787 173.36 258.159 173.621 258.572 173.798C258.985 173.975 259.431 174.064 259.88 174.06Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M271 188.36H322.25C323.192 188.345 324.095 187.979 324.781 187.334C325.467 186.688 325.888 185.809 325.96 184.87C325.995 184.421 325.936 183.97 325.787 183.545C325.638 183.12 325.402 182.73 325.094 182.401C324.787 182.072 324.414 181.81 324.001 181.633C323.587 181.455 323.14 181.366 322.69 181.37H271.46C270.516 181.385 269.612 181.752 268.926 182.4C268.239 183.048 267.82 183.929 267.75 184.87C267.717 185.317 267.777 185.765 267.925 186.188C268.073 186.61 268.308 186.997 268.613 187.325C268.918 187.653 269.287 187.914 269.698 188.092C270.109 188.27 270.552 188.361 271 188.36Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M256.79 223.94H320.08C320.529 223.936 320.975 224.025 321.388 224.202C321.801 224.379 322.173 224.64 322.481 224.968C322.788 225.296 323.024 225.684 323.173 226.108C323.323 226.532 323.383 226.982 323.35 227.43C323.278 228.369 322.857 229.248 322.171 229.894C321.485 230.539 320.582 230.905 319.64 230.92H256.35C255.901 230.924 255.455 230.835 255.042 230.658C254.629 230.481 254.257 230.22 253.95 229.892C253.642 229.564 253.406 229.176 253.257 228.752C253.107 228.328 253.047 227.878 253.08 227.43C253.152 226.491 253.573 225.612 254.259 224.966C254.945 224.321 255.848 223.955 256.79 223.94Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M269.71 209.64H321C321.45 209.635 321.897 209.723 322.311 209.899C322.726 210.075 323.099 210.336 323.407 210.664C323.716 210.992 323.952 211.381 324.103 211.805C324.253 212.23 324.313 212.681 324.28 213.13C324.21 214.071 323.791 214.952 323.104 215.6C322.418 216.248 321.514 216.615 320.57 216.63H269.28C268.829 216.636 268.381 216.547 267.966 216.37C267.551 216.193 267.177 215.932 266.869 215.603C266.56 215.273 266.324 214.883 266.174 214.458C266.025 214.032 265.965 213.58 266 213.13C266.072 212.191 266.493 211.312 267.179 210.666C267.865 210.021 268.768 209.655 269.71 209.64Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M254.6 259.19H317.89C318.832 259.175 319.735 258.809 320.421 258.164C321.107 257.518 321.528 256.639 321.6 255.7C321.633 255.251 321.573 254.8 321.423 254.375C321.272 253.951 321.036 253.562 320.727 253.234C320.419 252.906 320.046 252.645 319.631 252.469C319.217 252.293 318.77 252.205 318.32 252.21H255C254.058 252.225 253.155 252.591 252.469 253.236C251.783 253.882 251.362 254.761 251.29 255.7C251.257 256.152 251.318 256.605 251.47 257.032C251.622 257.459 251.861 257.849 252.173 258.177C252.485 258.506 252.862 258.766 253.28 258.94C253.697 259.114 254.147 259.2 254.6 259.19Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M291.54 273.49H317C317.942 273.475 318.845 273.109 319.531 272.464C320.217 271.818 320.638 270.939 320.71 270C320.743 269.55 320.683 269.099 320.533 268.674C320.382 268.249 320.146 267.86 319.838 267.531C319.529 267.202 319.156 266.94 318.742 266.763C318.327 266.586 317.881 266.496 317.43 266.5H292C291.057 266.517 290.154 266.885 289.468 267.533C288.782 268.18 288.362 269.06 288.29 270C288.257 270.447 288.317 270.895 288.465 271.318C288.613 271.74 288.848 272.127 289.153 272.455C289.458 272.783 289.827 273.044 290.238 273.222C290.649 273.4 291.092 273.491 291.54 273.49Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M265.75 273.49H279.18C280.122 273.475 281.025 273.109 281.711 272.464C282.397 271.818 282.818 270.939 282.89 270C282.923 269.55 282.863 269.099 282.713 268.674C282.563 268.249 282.326 267.86 282.018 267.531C281.709 267.202 281.336 266.94 280.922 266.763C280.508 266.586 280.061 266.496 279.61 266.5H266.18C265.237 266.517 264.334 266.885 263.648 267.533C262.962 268.18 262.542 269.06 262.47 270C262.437 270.449 262.497 270.9 262.647 271.325C262.798 271.749 263.035 272.138 263.343 272.466C263.651 272.794 264.025 273.055 264.439 273.231C264.853 273.407 265.3 273.495 265.75 273.49Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M252 301.76H315.29C316.232 301.745 317.135 301.379 317.821 300.734C318.507 300.088 318.928 299.209 319 298.27C319.033 297.82 318.973 297.369 318.823 296.944C318.673 296.519 318.436 296.13 318.128 295.801C317.819 295.472 317.446 295.21 317.032 295.033C316.617 294.856 316.171 294.766 315.72 294.77H252.39C251.446 294.785 250.542 295.152 249.856 295.8C249.169 296.448 248.75 297.329 248.68 298.27C248.646 298.722 248.708 299.177 248.861 299.604C249.013 300.032 249.254 300.422 249.566 300.751C249.879 301.08 250.257 301.339 250.676 301.513C251.096 301.687 251.546 301.771 252 301.76Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M263.11 316.06H314.36C315.303 316.043 316.206 315.675 316.892 315.027C317.578 314.38 317.998 313.5 318.07 312.56C318.103 312.111 318.043 311.66 317.893 311.235C317.742 310.811 317.506 310.422 317.197 310.094C316.889 309.766 316.516 309.505 316.101 309.329C315.687 309.153 315.24 309.065 314.79 309.07H263.54C262.598 309.085 261.695 309.451 261.009 310.096C260.323 310.742 259.902 311.621 259.83 312.56C259.797 313.01 259.857 313.461 260.007 313.886C260.158 314.311 260.394 314.7 260.702 315.029C261.011 315.358 261.384 315.62 261.798 315.797C262.213 315.974 262.659 316.064 263.11 316.06Z" fill="#4E667D"/>'
                    img +='<path d="M360.11 186.1C359.695 186.103 359.29 185.977 358.95 185.74L348.18 178.39C347.769 178.065 347.496 177.596 347.417 177.078C347.338 176.56 347.459 176.031 347.755 175.598C348.05 175.166 348.499 174.861 349.011 174.746C349.522 174.632 350.058 174.715 350.51 174.98L359.71 181.27C363.07 177.22 372.65 166.27 380.62 162.68C380.868 162.57 381.134 162.509 381.405 162.502C381.676 162.495 381.946 162.541 382.199 162.638C382.452 162.735 382.683 162.881 382.88 163.068C383.077 163.254 383.235 163.477 383.345 163.725C383.455 163.973 383.516 164.239 383.523 164.51C383.53 164.781 383.484 165.051 383.387 165.304C383.29 165.557 383.144 165.788 382.957 165.985C382.771 166.182 382.548 166.34 382.3 166.45C373.6 170.32 361.85 185.17 361.74 185.32C361.544 185.564 361.296 185.761 361.014 185.896C360.732 186.031 360.423 186.101 360.11 186.1Z" fill="#4E667D"/>'
                    img +='<path d="M355.1 227.84C354.565 227.841 354.049 227.637 353.66 227.27L344.29 218.27C343.892 217.889 343.662 217.366 343.65 216.816C343.637 216.266 343.844 215.733 344.225 215.335C344.606 214.937 345.129 214.707 345.679 214.695C346.23 214.682 346.762 214.889 347.16 215.27L355.16 222.96C359.11 219.43 370.16 210.03 378.52 207.75C378.783 207.678 379.057 207.659 379.327 207.694C379.597 207.728 379.858 207.815 380.094 207.95C380.33 208.086 380.538 208.266 380.704 208.481C380.871 208.696 380.993 208.942 381.065 209.205C381.137 209.468 381.156 209.742 381.121 210.012C381.087 210.282 381 210.543 380.865 210.779C380.73 211.015 380.549 211.223 380.334 211.389C380.119 211.556 379.873 211.678 379.61 211.75C370.49 214.24 356.68 227.18 356.54 227.31C356.347 227.489 356.12 227.628 355.873 227.719C355.626 227.81 355.363 227.851 355.1 227.84Z" fill="#4E667D"/>'
                    img +='<path opacity="0.4" d="M337.57 141.73H340.57C341.374 141.738 342.171 141.587 342.917 141.287C343.662 140.987 344.341 140.543 344.915 139.98C345.489 139.417 345.946 138.747 346.261 138.008C346.576 137.268 346.742 136.474 346.75 135.67V130.09C346.75 130.009 346.734 129.929 346.703 129.854C346.671 129.78 346.626 129.712 346.568 129.655C346.51 129.598 346.442 129.554 346.367 129.524C346.291 129.493 346.211 129.479 346.13 129.48H337.65C337.468 129.483 337.294 129.556 337.165 129.685C337.036 129.814 336.963 129.988 336.96 130.17L337 141.12C337 141.275 337.058 141.424 337.164 141.538C337.27 141.651 337.415 141.72 337.57 141.73Z" fill="black"/>'
                    img +='<path d="M291.55 119.39H342.61C343.339 119.369 344.064 119.511 344.733 119.804C345.401 120.097 345.995 120.535 346.474 121.086C346.952 121.637 347.302 122.288 347.499 122.99C347.695 123.693 347.733 124.431 347.61 125.15L346.08 136.52C345.831 138.092 345.042 139.527 343.848 140.579C342.654 141.63 341.13 142.232 339.54 142.28H288.46C287.731 142.301 287.006 142.159 286.337 141.866C285.669 141.573 285.075 141.135 284.596 140.584C284.118 140.033 283.768 139.382 283.571 138.68C283.375 137.977 283.337 137.239 283.46 136.52L285 125.15C285.249 123.577 286.04 122.14 287.236 121.088C288.432 120.036 289.958 119.436 291.55 119.39Z" fill="#4E667D"/>'
                    img +='<path opacity="0.6" d="M291.55 119.39H342.61C343.339 119.369 344.064 119.511 344.733 119.804C345.401 120.097 345.995 120.535 346.474 121.086C346.952 121.637 347.302 122.288 347.499 122.99C347.695 123.693 347.733 124.431 347.61 125.15L346.08 136.52C345.831 138.092 345.042 139.527 343.848 140.579C342.654 141.63 341.13 142.232 339.54 142.28H288.46C287.731 142.301 287.006 142.159 286.337 141.866C285.669 141.573 285.075 141.135 284.596 140.584C284.118 140.033 283.768 139.382 283.571 138.68C283.375 137.977 283.337 137.239 283.46 136.52L285 125.15C285.249 123.577 286.04 122.14 287.236 121.088C288.432 120.036 289.958 119.436 291.55 119.39Z" fill="white"/>'
                    img +='<path opacity="0.5" d="M341.29 124.89H318.29C318.077 124.907 317.862 124.88 317.66 124.81C317.457 124.74 317.272 124.629 317.115 124.484C316.957 124.339 316.832 124.163 316.746 123.967C316.66 123.771 316.616 123.559 316.616 123.345C316.616 123.131 316.66 122.919 316.746 122.723C316.832 122.527 316.957 122.351 317.115 122.206C317.272 122.061 317.457 121.95 317.66 121.88C317.862 121.81 318.077 121.783 318.29 121.8H341.29C341.678 121.831 342.041 122.008 342.305 122.294C342.569 122.58 342.716 122.955 342.716 123.345C342.716 123.735 342.569 124.11 342.305 124.396C342.041 124.682 341.678 124.859 341.29 124.89Z" fill="white"/>'
                    img +='<path opacity="0.5" d="M310.64 124.89H308.22C308.007 124.907 307.792 124.88 307.59 124.81C307.387 124.74 307.202 124.629 307.045 124.484C306.887 124.339 306.762 124.163 306.676 123.967C306.59 123.771 306.546 123.559 306.546 123.345C306.546 123.131 306.59 122.919 306.676 122.723C306.762 122.527 306.887 122.351 307.045 122.206C307.202 122.061 307.387 121.95 307.59 121.88C307.792 121.81 308.007 121.783 308.22 121.8H310.64C310.853 121.783 311.068 121.81 311.27 121.88C311.473 121.95 311.658 122.061 311.816 122.206C311.973 122.351 312.098 122.527 312.184 122.723C312.27 122.919 312.314 123.131 312.314 123.345C312.314 123.559 312.27 123.771 312.184 123.967C312.098 124.163 311.973 124.339 311.816 124.484C311.658 124.629 311.473 124.74 311.27 124.81C311.068 124.88 310.853 124.907 310.64 124.89Z" fill="white"/>'
                    img +='<path d="M169.46 346.65L179.33 348.54L202.66 238.82L188.17 236.05L169.46 346.65Z" fill="#4E667D"/>'
                    img +='<path d="M204.15 162.64L215.97 158.75L216.79 154.46L205.78 154.12L204.15 162.64Z" fill="#263238"/>'
                    img +='<path d="M203.85 160.14L217.31 155.76L203.48 234.52L188.99 231.76L203.85 160.14Z" fill="#4E667D"/>'
                    img +='<path d="M189.821 236.178L203.1 238.752L203.93 234.47L190.651 231.895L189.821 236.178Z" fill="#263238"/>'
                    img +='<path d="M171.24 359.64C171.11 360.29 171.38 360.89 171.84 360.98C172.3 361.07 172.78 360.61 172.9 359.98C173.02 359.35 172.75 358.73 172.29 358.64C171.83 358.55 171.36 359 171.24 359.64Z" fill="#4E667D"/>'
                    img +='<path d="M170.56 359.72L173.5 360.28L179.33 348.54L169.46 346.65L170.56 359.72Z" fill="#263238"/>'
                    img +='<path d="M188.34 211.82C188.131 211.819 187.926 211.765 187.743 211.665C187.56 211.564 187.405 211.419 187.293 211.243C187.181 211.067 187.114 210.865 187.1 210.657C187.085 210.449 187.123 210.24 187.21 210.05C193.41 196.76 198.44 169 199.12 162.4C199.217 161.443 199.569 160.529 200.139 159.753C200.708 158.978 201.475 158.369 202.36 157.99L205.63 156.58C205.78 156.514 205.941 156.479 206.104 156.475C206.268 156.472 206.43 156.501 206.583 156.56C206.735 156.62 206.874 156.709 206.992 156.822C207.11 156.935 207.204 157.07 207.27 157.22C207.336 157.37 207.371 157.531 207.375 157.694C207.378 157.858 207.349 158.02 207.29 158.173C207.23 158.325 207.141 158.464 207.028 158.582C206.915 158.7 206.78 158.794 206.63 158.86L203.36 160.27C202.882 160.477 202.468 160.807 202.161 161.228C201.853 161.648 201.663 162.142 201.61 162.66C200.9 169.59 195.91 197.34 189.49 211.1C189.388 211.318 189.225 211.502 189.021 211.63C188.817 211.757 188.581 211.823 188.34 211.82Z" fill="#263238"/>'
                    img +='<path d="M142.27 158.79C139.78 163.06 137.27 167.59 134.9 172.03C133.73 174.27 132.59 176.51 131.5 178.76C130.41 181.01 129.39 183.28 128.5 185.54L128.33 185.95C128.294 186.015 128.27 186.086 128.26 186.16C128.203 186.49 128.18 186.825 128.19 187.16C128.233 188.151 128.374 189.136 128.61 190.1C129.182 192.403 129.917 194.662 130.81 196.86C131.7 199.16 132.72 201.47 133.81 203.79C134.9 206.11 136.01 208.43 137.1 210.69L132.35 213.93C128.805 209.811 125.655 205.368 122.94 200.66C121.498 198.186 120.276 195.589 119.29 192.9C118.742 191.368 118.34 189.788 118.09 188.18C117.956 187.209 117.916 186.228 117.97 185.25C118.01 184.672 118.091 184.097 118.21 183.53C118.27 183.24 118.37 182.91 118.46 182.6L118.62 182.09C120.242 176.879 122.247 171.795 124.62 166.88C126.945 162.006 129.546 157.268 132.41 152.69L142.27 158.79Z" fill="#FFC3BD"/>'
                    img +='<path d="M136.35 209.25L140.82 212.73L134 218.37C134 218.37 130.89 215.37 131.37 211.68L136.35 209.25Z" fill="#FFC3BD"/>'
                    img +='<path d="M142.64 217.81L138.09 223.66L133.99 218.37L140.82 212.73L142.64 217.81Z" fill="#FFC3BD"/>'
                    img +='<path d="M168 117.11C168.13 117.71 168.55 118.11 168.94 118.11C169.33 118.11 169.56 117.47 169.44 116.87C169.32 116.27 168.89 115.87 168.5 115.92C168.11 115.97 167.92 116.5 168 117.11Z" fill="#263238"/>'
                    img +='<path d="M168.53 118.15C169.631 119.853 170.96 121.397 172.48 122.74C172.125 123.12 171.692 123.418 171.21 123.613C170.728 123.809 170.209 123.896 169.69 123.87L168.53 118.15Z" fill="#ED847E"/>'
                    img +='<path d="M165.14 114.2C165.051 114.197 164.965 114.161 164.9 114.1C164.865 114.067 164.837 114.028 164.818 113.984C164.8 113.94 164.79 113.893 164.79 113.845C164.79 113.797 164.8 113.75 164.818 113.706C164.837 113.662 164.865 113.623 164.9 113.59C165.277 113.154 165.755 112.818 166.291 112.608C166.828 112.399 167.408 112.324 167.98 112.39C168.027 112.398 168.071 112.415 168.111 112.44C168.151 112.465 168.186 112.498 168.213 112.536C168.241 112.575 168.26 112.618 168.271 112.665C168.281 112.711 168.283 112.758 168.275 112.805C168.267 112.852 168.25 112.896 168.225 112.936C168.2 112.976 168.167 113.011 168.129 113.038C168.09 113.066 168.047 113.085 168 113.096C167.954 113.106 167.907 113.108 167.86 113.1C167.405 113.06 166.946 113.13 166.523 113.304C166.101 113.478 165.726 113.751 165.43 114.1C165.392 114.137 165.346 114.165 165.296 114.182C165.246 114.2 165.193 114.206 165.14 114.2Z" fill="#263238"/>'
                    img +='<path d="M150.79 125.62C151.89 131.23 152.99 141.5 149.07 145.24C149.07 145.24 150.6 150.92 161.01 150.92C172.46 150.92 166.49 145.24 166.49 145.24C160.24 143.74 160.4 139.11 161.49 134.75L150.79 125.62Z" fill="#FFC3BD"/>'
                    img +='<path d="M146.85 145.66C146.02 143.66 147.85 137.66 149.7 137.09C151.79 136.45 159.7 136.81 163.7 138.46C166.51 141.3 167.32 147.19 167.32 147.19L146.85 145.66Z" fill="#263238"/>'
                    img +='<path d="M114.15 407.52C114.457 407.63 114.784 407.678 115.11 407.661C115.436 407.644 115.756 407.562 116.05 407.42C116.166 407.318 116.247 407.181 116.279 407.03C116.311 406.878 116.294 406.721 116.23 406.58C116.195 406.462 116.135 406.352 116.054 406.259C115.973 406.166 115.873 406.091 115.76 406.04C114.76 405.55 112.35 406.52 112.25 406.56C112.217 406.572 112.19 406.594 112.17 406.623C112.15 406.651 112.14 406.685 112.14 406.72C112.139 406.755 112.149 406.789 112.166 406.819C112.184 406.849 112.209 406.874 112.24 406.89C112.851 407.171 113.492 407.382 114.15 407.52ZM115.39 406.3L115.61 406.37C115.675 406.399 115.732 406.442 115.779 406.495C115.826 406.549 115.86 406.612 115.88 406.68C115.98 407.01 115.88 407.11 115.82 407.14C115.41 407.49 113.88 407.14 112.82 406.74C113.633 406.403 114.511 406.252 115.39 406.3Z" fill="#4E667D"/>'
                    img +='<path d="M112.28 406.91H112.37C113.24 406.66 115.09 405.34 115.11 404.47C115.113 404.335 115.069 404.203 114.985 404.097C114.901 403.991 114.782 403.918 114.65 403.89C114.518 403.843 114.378 403.824 114.239 403.834C114.099 403.844 113.963 403.884 113.84 403.95C112.84 404.47 112.17 406.6 112.15 406.69C112.133 406.717 112.124 406.748 112.124 406.78C112.124 406.812 112.133 406.843 112.15 406.87L112.28 406.91ZM114.45 404.2H114.53C114.75 404.28 114.75 404.39 114.75 404.43C114.75 404.95 113.51 405.98 112.62 406.43C112.856 405.587 113.34 404.834 114.01 404.27C114.145 404.198 114.3 404.173 114.45 404.2Z" fill="#4E667D"/>'
                    img +='<path d="M169.44 408.81C170.44 408.81 171.38 408.67 171.69 408.23C171.774 408.115 171.819 407.977 171.819 407.835C171.819 407.693 171.774 407.555 171.69 407.44C171.628 407.334 171.544 407.241 171.444 407.169C171.344 407.097 171.23 407.046 171.11 407.02C169.84 406.67 167.16 408.19 167.05 408.26C167.017 408.278 166.991 408.306 166.974 408.34C166.958 408.375 166.953 408.413 166.96 408.45C166.966 408.485 166.982 408.517 167.007 408.542C167.033 408.568 167.065 408.584 167.1 408.59C167.872 408.733 168.655 408.806 169.44 408.81ZM170.66 407.32C170.778 407.321 170.896 407.337 171.01 407.37C171.083 407.382 171.153 407.411 171.214 407.455C171.275 407.498 171.324 407.554 171.36 407.62C171.49 407.84 171.45 407.96 171.36 408.02C171 408.52 169.07 408.53 167.64 408.33C168.57 407.797 169.596 407.454 170.66 407.32Z" fill="#4E667D"/>'
                    img +='<path d="M167.13 408.6H167.2C168.13 408.19 169.96 406.53 169.82 405.66C169.82 405.46 169.64 405.21 169.14 405.16C168.961 405.138 168.779 405.155 168.607 405.208C168.435 405.262 168.275 405.351 168.14 405.47C167.14 406.27 166.96 408.33 166.95 408.42C166.945 408.451 166.949 408.482 166.961 408.51C166.974 408.539 166.994 408.563 167.02 408.58C167.053 408.601 167.092 408.608 167.13 408.6ZM169 405.49H169.11C169.43 405.49 169.45 405.65 169.46 405.69C169.55 406.21 168.3 407.51 167.36 408.08C167.443 407.209 167.792 406.385 168.36 405.72C168.54 405.571 168.766 405.49 169 405.49Z" fill="#4E667D"/>'
                    img +='<path d="M158.71 408.42H166.37L167.13 390.68H159.47L158.71 408.42Z" fill="#FFC3BD"/>'
                    img +='<path d="M103.43 404.87L111.12 406.49L116.55 389.97L108.85 388.35L103.43 404.87Z" fill="#FFC3BD"/>'
                    img +='<path d="M112 405.74L103.59 403.92C103.442 403.887 103.286 403.906 103.15 403.974C103.014 404.041 102.905 404.152 102.84 404.29L99.92 410.63C99.8506 410.785 99.817 410.953 99.8217 411.123C99.8264 411.293 99.8692 411.459 99.9471 411.61C100.025 411.761 100.136 411.892 100.271 411.994C100.407 412.096 100.564 412.167 100.73 412.2C103.68 412.78 105.14 412.92 108.84 413.72C111.11 414.21 116.25 415.46 119.39 416.14C122.53 416.82 123.62 413.8 122.39 413.24C116.9 410.73 114.6 408.52 113.12 406.46C112.847 406.091 112.449 405.835 112 405.74Z" fill="#263238"/>'
                    img +='<path d="M166.32 407.53H158C157.849 407.531 157.702 407.582 157.584 407.677C157.466 407.771 157.384 407.903 157.35 408.05L155.84 414.86C155.8 415.024 155.798 415.196 155.835 415.361C155.872 415.526 155.947 415.681 156.053 415.812C156.16 415.944 156.295 416.049 156.449 416.12C156.603 416.191 156.771 416.225 156.94 416.22C159.94 416.17 164.32 416 168.11 416C172.54 416 170.82 416.24 176.02 416.24C179.17 416.24 180.02 413.06 178.72 412.77C172.72 411.46 173.39 411.32 168.21 408.13C167.651 407.752 166.995 407.544 166.32 407.53Z" fill="#263238"/>'
                    img +='<path d="M135.35 147C128.17 148.64 122.26 167 122.26 167L136.67 178C140.403 174.022 143.039 169.143 144.32 163.84C146.79 154.17 142.69 145.28 135.35 147Z" fill="#263238"/>'
                    img +='<path opacity="0.2" d="M141.51 160.45C137.61 164.3 136.34 171.33 136.03 177.45L136.67 177.94C140.407 173.965 143.043 169.084 144.32 163.78C144.47 163.21 144.59 162.63 144.7 162.07L141.51 160.45Z" fill="black"/>'
                    img +='<path d="M178.27 147.36C178.27 147.36 186.42 156.57 178.72 213.57H137.72C137.44 207.28 141.39 176.57 135.32 146.97C139.851 146.097 144.434 145.52 149.04 145.24C154.842 144.9 160.658 144.9 166.46 145.24C170.446 145.638 174.395 146.347 178.27 147.36Z" fill="#263238"/>'
                    img +='<path opacity="0.2" d="M167.13 390.68L166.74 399.83H159.07L159.46 390.68H167.13Z" fill="black"/>'
                    img +='<path opacity="0.2" d="M108.85 388.35L116.55 389.97L113.74 398.49L106.05 396.87L108.85 388.35Z" fill="black"/>'
                    img +='<path d="M147.59 119.93C149.59 127.27 150.33 131.67 154.86 134.72C161.68 139.32 170.17 134.05 170.43 126.26C170.67 119.26 167.43 108.43 159.53 106.95C157.794 106.614 156.002 106.728 154.323 107.281C152.644 107.833 151.134 108.807 149.937 110.108C148.741 111.409 147.897 112.994 147.486 114.713C147.075 116.433 147.111 118.228 147.59 119.93Z" fill="#FFC3BD"/>'
                    img +='<path d="M154.91 133.87H154.81C153.626 133.877 152.458 133.601 151.402 133.065C150.346 132.529 149.434 131.749 148.74 130.79C146.44 127.58 143.21 122.01 142.84 115.58C145.76 112.5 156.73 110.47 161.62 111.58C165.69 112.51 165.1 124.1 161.25 130.37C160.577 131.443 159.642 132.327 158.532 132.94C157.423 133.552 156.177 133.872 154.91 133.87Z" fill="#FFC3BD"/>'
                    img +='<path opacity="0.8" d="M154.91 133.87H154.81C153.626 133.877 152.458 133.601 151.402 133.065C150.346 132.529 149.434 131.749 148.74 130.79C146.44 127.58 143.21 122.01 142.84 115.58C145.76 112.5 156.73 110.47 161.62 111.58C165.69 112.51 165.1 124.1 161.25 130.37C160.577 131.443 159.642 132.327 158.532 132.94C157.423 133.552 156.177 133.872 154.91 133.87Z" fill="#263238"/>'
                    img +='<path d="M147.29 118.19C145.979 118.154 144.709 117.728 143.64 116.968C142.572 116.208 141.754 115.147 141.29 113.92C140.42 111.32 141.34 105.83 146.57 105.67C151.8 105.51 152.34 102.53 150.73 101.92C154.15 102.17 153.07 104.56 153.07 104.56C153.98 104.56 154.84 102.11 160.07 102.31C165.3 102.51 167.85 101.39 167.18 100.22C169.65 100.72 168.96 103.72 166.87 104.53C166.87 104.53 171.82 108.28 172.99 106.22C173.82 107.72 172.18 109.52 165.93 108.7C168.42 112.19 162.52 111.41 160.01 112.99C157.5 114.57 150.74 118.44 147.29 118.19Z" fill="#263238"/>'
                    img +='<path d="M160.08 119.59C160.584 121.409 161.669 123.014 163.17 124.16C165.17 125.64 166.65 124.16 166.51 121.92C166.39 119.92 165.19 116.67 163 116.07C160.81 115.47 159.51 117.38 160.08 119.59Z" fill="#FFC3BD"/>'
                    img +='<path d="M164.19 213.57C164.19 213.57 155.49 272.11 150.72 294.04C145.5 318.04 117.47 397.51 117.47 397.51L103.76 394.51C103.76 394.51 123.36 329.75 127.76 296.18C131.13 270.39 137.76 213.53 137.76 213.53L164.19 213.57Z" fill="#263238"/>'
                    img +='<path d="M120.76 392.94L118.2 397.94L102.77 394.6L104.07 389.96L120.76 392.94Z" fill="#4E667D"/>'
                    img +='<path opacity="0.3" d="M158.69 234.43C148.64 236.43 149.69 273.88 150.92 293.16C153.81 279.53 158.06 253.31 160.92 234.7C160.227 234.364 159.443 234.269 158.69 234.43Z" fill="black"/>'
                    img +='<path d="M178.72 213.57C178.72 213.57 179.78 270.84 178.94 294.2C178.08 318.5 169.59 398.06 169.59 398.06H156.59C156.59 398.06 156.12 319.92 155.54 296.06C154.91 270.06 151.77 213.57 151.77 213.57H178.72Z" fill="#263238"/>'
                    img +='<path d="M172.09 392.89C172.15 392.89 171.35 398.27 171.35 398.27H155.57L155.14 393.47L172.09 392.89Z" fill="#4E667D"/>'
                    img +='<path d="M178.46 146.35C180.46 147.18 182.39 148.67 182.97 153.62C183.53 158.37 182.45 200 181.15 213.29C181.132 213.746 180.952 214.181 180.642 214.516C180.332 214.852 179.913 215.066 179.46 215.12C171.27 215.47 145.9 212.05 137.34 213.55C136.34 213.73 135.47 212.63 135.53 211.63C136.2 198.68 135.23 177.78 133.65 164.51C132.65 155.84 132.65 146.77 139.88 145.23C145.26 144.09 170.64 143.09 178.46 146.35Z" fill="#4E667D"/>'
                    img +='<path d="M181.63 157.85C182.09 160.05 182.63 162.44 183.13 164.72C183.63 167 184.25 169.31 184.89 171.56C185.53 173.81 186.19 176.02 186.95 178.13C187.633 180.131 188.489 182.068 189.51 183.92C189.882 184.631 190.328 185.302 190.84 185.92C191.03 186.13 191.18 186.27 191.22 186.31C191.26 186.35 191.22 186.4 191.41 186.46C192.054 186.673 192.733 186.761 193.41 186.72C194.416 186.676 195.418 186.569 196.41 186.4C200.925 185.449 205.359 184.145 209.67 182.5L212.58 187.5C208.447 190.818 203.817 193.464 198.86 195.34C197.477 195.848 196.051 196.233 194.6 196.49C192.844 196.822 191.045 196.849 189.28 196.57C188.145 196.378 187.048 196.006 186.03 195.47C185.112 194.957 184.26 194.336 183.49 193.62C182.27 192.481 181.192 191.198 180.28 189.8C177.455 185.157 175.266 180.155 173.77 174.93C173.02 172.46 172.33 169.99 171.77 167.5C171.21 165.01 170.65 162.59 170.22 159.94L181.63 157.85Z" fill="#FFC3BD"/>'
                    img +='<path d="M164.78 154.85C158.56 167.36 173.34 181.5 173.34 181.5L189.82 172.9C188.669 166.27 186.781 159.79 184.19 153.58C179.39 142.9 168.36 147.66 164.78 154.85Z" fill="#263238"/>'
                    img +='<path d="M208.14 183.2L213.73 179.52L216.05 187.88C216.05 187.88 213.55 190.81 208.97 187.62L208.14 183.2Z" fill="#FFC3BD"/>'
                    img +='<path d="M218.6 182.9L218.42 184.66C218.329 185.505 217.922 186.284 217.28 186.84L216.05 187.84L213.73 179.48L214.44 179.3C214.964 179.164 215.513 179.157 216.04 179.28C216.567 179.403 217.057 179.653 217.466 180.007C217.875 180.361 218.192 180.81 218.39 181.314C218.587 181.818 218.66 182.362 218.6 182.9Z" fill="#FFC3BD"/>'
                    img +='</svg>'
                  img += '<h3 style="text-align: center;">Currently no open jobs</h3>'
                  img += '</div>'
                  // $(".job-list-live").append(img);
                  $(".job-list-live").empty();
                  $(".job-list-live").append(img);
                    // $("#job-list-live"+job_card_count_str).append(img);
                  }
                }
              });
          }
          function getJobsList() {
              var styleNode = document.createElement("style");
              document.head.appendChild(styleNode);
              styleNode.innerHTML = ".job-list-live { font-family: 'Inter', sans-serif !important; }";
              styleNode.innerHTML += ".cards { width: 33%;height: 253px; }";
              styleNode.innerHTML += ".job-cards { height: 215px; }";
              styleNode.innerHTML += "@media screen and (min-width: 688px) and (max-width: 1024px) { .cards { width: 50%;height: 253px; } }";
              styleNode.innerHTML += "@media screen and (max-width: 689px) { .cards { width: 100%;height: 253px; } }";
              styleNode.innerHTML += "@media screen and (max-width: 768px) { #dynamic-jobs { padding-left: 0px !important; padding-right: 0px !important; } }";
              styleNode.innerHTML += "@media screen and (max-width: 768px) { .job_list { padding-left: 0px !important; padding-right: 0px !important; } }";
              styleNode.innerHTML += "@media screen and (max-width: 768px) { .job-cards { margin: 5px 0px !important; } }";
              styleNode.innerHTML += ".job-cards:hover { border: 0.5px solid ${localStorage.getItem("exclusivePrimaryColor") ? localStorage.getItem("exclusivePrimaryColor") : localStorage.getItem("primaryColor")}; box-shadow: 0px 18px 14px -20px #00005e;border-radius: 8px;transition: all .3s ease-in-out;animation: pulse 1.5s;}"
              styleNode.innerHTML += "::-webkit-scrollbar-thumb {background: #888 !important;}";
              styleNode.innerHTML += "::-webkit-scrollbar-track {background: #f1f1f1 !important;}";
              if (document.getElementById("pool-list-live") == null) {
                script.onload = function() {
                  getCards();
                }
              } else {
                getCards();
              }
              document.getElementsByTagName("head")[0].appendChild(script);
          }
          </script>
          </div>
          `,
    });
});
