import Keycloak from "keycloak-js";
import { logout } from "./Views/helpers/helper";

const _kc = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

// Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
      .init({
        onLoad: "check-sso",
        checkLoginIframe: false,
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
      })
      .then((authenticated) => {
        if (!authenticated) {
          console.info("KEYCLOAK ----------------> user is not authenticated..!");
        }

        onAuthenticatedCallback();
      })
      .catch((err) => {
        console.error('error initilizing keycloak ->', err);
      });

  _kc.onAuthSuccess = () => {
    const TOKEN_CHECK_RETRY_COUNT_KEY = 'token-check-retry';

    let retryCount = parseInt(localStorage.getItem(TOKEN_CHECK_RETRY_COUNT_KEY)) || 0;

    if (!_kc.tokenParsed?.dsaascandidateid) {
      if (retryCount < 2) {
        localStorage.setItem(TOKEN_CHECK_RETRY_COUNT_KEY, retryCount + 1);
        window.location.reload();
      } else {
        logout();
      }
      return;
    }

    localStorage.removeItem(TOKEN_CHECK_RETRY_COUNT_KEY);

    setLocalStorage();

    setInterval(async () => {
      try {
        // Calling this API to check if user is logged in.
        await _kc.loadUserInfo();
        const refreshed = await _kc.updateToken(60);
        if (refreshed) {
          console.info("Token refreshed" + refreshed);
          setLocalStorage();
        } else {
          console.warn(
              "Token not refreshed, valid for " +
              Math.round(
                  _kc.tokenParsed.exp +
                  _kc.timeSkew -
                  new Date().getTime() / 1000
              ) +
              " seconds"
          );
        }
      } catch (err) {
        console.error("Failed to refresh token or user logged out ->", err);
        logout();
      }
    }, 10000);
  };
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getIdToken = () => _kc.idToken;

const isLoggedIn = () => !!_kc.token;

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const userInfo = () => _kc.idTokenParsed;

const setLocalStorage = () => {
  localStorage.setItem("token", getToken());
  localStorage.setItem("accessToken", getIdToken());
  localStorage.setItem("email", userInfo()?.email || null);
};

const getUserAccountInfo = () => new Promise((resolve, reject) => {
  var url = `${_kc.authServerUrl}/realms/${_kc.realm}/account`;
  var req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.setRequestHeader('Accept', 'application/json');
  req.setRequestHeader('Authorization', 'Bearer ' + _kc.token);

  req.onreadystatechange = function () {
    if (req.readyState == 4) {
      if (req.status >= 200 && req.status < 300) {
        resolve(JSON.parse(req.responseText));
      } else {
        reject(new Error('unable to fetch user profile'));
      }
    }
  };

  req.send();
});

const updateUserAccountInfo = (updatedAccountInfo = {}) => new Promise((resolve, reject) => {
  getUserAccountInfo().then((accountInfo) => {
    var url = `${_kc.authServerUrl}/realms/${_kc.realm}/account`;
    var req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Accept', 'application/json');
    req.setRequestHeader('Content-Type', 'application/json');
    req.setRequestHeader('Authorization', 'Bearer ' + _kc.token);

    req.onreadystatechange = function () {
      if (req.readyState == 4) {
        if (req.status >= 200 && req.status < 300) {
          resolve('updated');
        } else {
          reject(new Error('unable to fetch user profile'));
        }
      }
    };

    for (const key in updatedAccountInfo) {
      if (Object.hasOwnProperty.call(updatedAccountInfo, key)) {
        accountInfo[key] = updatedAccountInfo[key];
      }
    }

    req.send(JSON.stringify(accountInfo));
  });
});

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getIdToken,
  getUsername,
  hasRole,
  userInfo,
  updateUserAccountInfo,
};

export default KeycloakService;
