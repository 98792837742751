import React from "react";
import './App.scss';
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Views/Loader";
import CareerPortal from "./Views/CareerPortal";
import { useEffect } from "react";
import HeaderBg from "./assets/images/bg-1.svg";
import HeaderBg1 from "./assets/images/bg-2.svg";
import HeaderBg3 from "./assets/images/bg-4.svg";
import HeaderBg4 from "./assets/images/bg-5.svg";
import HeaderBg5 from "./assets/images/bg-6.svg";

import HeaderNeuroBg from "./assets/images/bg-neuro.svg";
import HeaderNeuroBg1 from "./assets/images/bg-neuro2.svg";
import HeaderNeuroBg2 from "./assets/images/bg-neuro4.svg";
import HeaderNeuroBg3 from "./assets/images/bg-neuro5.svg";
import HeaderNeuroBg4 from "./assets/images/bg-neuro6.svg";
import KeycloakService from "./keycloak";
import ErrorBoundary from "./ErrorBoundary";
import { useDispatch, useSelector } from "react-redux";
import { getPageNotFoundRecode } from "./redux/pageNotFoundReducer.js";
const NotFound = React.lazy(() => import("./PageNotFound.jsx"));

const PublicPortal = React.lazy(() => import("./Views/PublicPortal"));

document.addEventListener('mousedown', function (e) {
    toast?.dismiss();
});

function App() {
    const origin = process.env.REACT_APP_ORIGIN;
    const dispatch = useDispatch();
    const pageNotFoundError = useSelector((state) => state.pageNotFound.error);
    useEffect(() => {
        if (pageNotFoundError) {
            dispatch(getPageNotFoundRecode(false));
        }
    }, [window.location]);
    useEffect(() => {
        if (process.env.REACT_APP_ORIGIN === "neurodiversify") {
          document.querySelector("link[title=icon]").setAttribute("href", "/neuro-favicon.png");
        } else {
          document.querySelector("link[title=icon]").setAttribute("href", "/favicon.png");
        }
        if (process.env.REACT_APP_ORIGIN === "neurodiversify") {
          document.documentElement.style.setProperty("--primary-color", "#055094", "important");
          document.documentElement.style.setProperty("--secondary-color", "#EAF4FF", "important");
          document.documentElement.style.setProperty("--font-family", "Lato", "important");
          document.documentElement.style.setProperty("--font-size-10", "12px", "important");
          document.documentElement.style.setProperty("--font-size-12", "14px", "important");
          document.documentElement.style.setProperty("--font-size-14", "16px", "important");
          document.documentElement.style.setProperty("--font-size-16", "18px", "important");
          document.documentElement.style.setProperty("--font-size-18", "20px", "important");
          document.documentElement.style.setProperty("--font-size-20", "24px", "important");
          document.documentElement.style.setProperty("--font-size-24", "26px", "important");
          document.documentElement.style.setProperty("--font-size-28", "30px", "important");
          document.documentElement.style.setProperty("--font-size-36", "38px", "important");
          document.documentElement.style.setProperty("--gray6", "#344054", "important");
          document.documentElement.style.setProperty("--gray7", "#344054", "important");
          document.documentElement.style.setProperty("--gray8", "#1D2939", "important");
          document.documentElement.style.setProperty("--gray9", "#1D2939", "important");
          document.documentElement.style.setProperty("--gray10", "#1D2939", "important");
          document.documentElement.style.setProperty("--gray11", "#1D2939", "important");
          document.documentElement.style.setProperty("--font-size-header", "20px", "important");
          document.documentElement.style.setProperty("--toast-image-height", "25px", "important");
          document.documentElement.style.setProperty("--bgImage", `url(${HeaderNeuroBg})`, "important");
          document.documentElement.style.setProperty("--bgImage1", `url(${HeaderNeuroBg1})`, "important");
          document.documentElement.style.setProperty("--bgImage2", `url(${HeaderNeuroBg2})`, "important");
          document.documentElement.style.setProperty("--bgImage3", `url(${HeaderNeuroBg3})`, "important");
          document.documentElement.style.setProperty("--bgImage4", `url(${HeaderNeuroBg4})`, "important");
        } else {
          document.documentElement.style.setProperty("--bgImage", `url(${HeaderBg })`, "important");
          document.documentElement.style.setProperty("--bgImage1", `url(${HeaderBg1})`, "important");
          document.documentElement.style.setProperty("--bgImage2", `url(${HeaderBg3})`, "important");
          document.documentElement.style.setProperty("--bgImage3", `url(${HeaderBg4})`, "important");
          document.documentElement.style.setProperty("--bgImage4", `url(${HeaderBg5})`, "important");
        }
      }, []);
    return (
        <main>
            <React.Suspense
                fallback={
                    <>
                        <Loader />
                    </>
                }
            >
                <ErrorBoundary>
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <PublicPortal />
                        }
                    />
                    <Route path="candidate/*" element={
                        <React.Suspense fallback={<></>}>
                            {pageNotFoundError ?
                                <NotFound />
                                :
                                <AuthGuard >
                                    <CareerPortal />
                                </AuthGuard>
                            }
                        </React.Suspense>
                        }
                    />
                </Routes>
                </ErrorBoundary>
                <ToastContainer hideProgressBar position="top-right" autoClose={15000} />
            </React.Suspense>
        </main>
    );
}

export default App;

function AuthGuard({ children }) {
    const location = useLocation();
    const path = location.pathname;

    const isAuthenticated = KeycloakService.isLoggedIn();
    if (!isAuthenticated) {
        KeycloakService.doLogin({ redirectUri: `${window.location.origin}${path}${window.location.search}` });
        return (<div></div>);
    }

    return children;
}

function AuthGuardEnterpriseRedirect({ children, redirectTo }) {
    const fullURL = window.location.href;
    const domain = window.location.protocol + "//" + window.location.host;
    // Get the URL without the domain
    const urlWithoutDomain = fullURL.replace(domain, '');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const is_admin = ["T", "H", "A", "Z"];
    let isAuthenticated = KeycloakService.isLoggedIn() && is_admin.indexOf(localStorage.getItem("userType")) == -1;
    let updateMsp = params.get("childEnterpriseId") != null ? (params.get("childEnterpriseId") == localStorage.getItem("client_name")) : true; // updating child enterprise details

    let emailQP = params.get('email');
    if (!KeycloakService.isLoggedIn() && urlWithoutDomain !== "/") {
        console.log('window location ->', window.location.search);
        KeycloakService.doLogin({ redirectUri: `${window.location.origin}/candidate/homepage${window.location.search}`, loginHint: emailQP });
        return (<div></div>);
    }

    return (isAuthenticated && updateMsp) ? <Navigate to={redirectTo} /> : children;
}
