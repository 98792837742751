import React from "react";
import { Route, Routes } from "react-router-dom";

import LaunchPage from "./Launchpage";
import BlankTemplate from "./BlankTemplate";
import MyCareerSite from "./MyCareerSite";
import SuccessScreen from "./PublishSuccess";
import Header from "./Header";
import AdminAccessPage from "./AdminAccessPage";
const NotFound = React.lazy(() => import("../../PageNotFound.jsx"));

const CareerPortal = () => {
    return (
        <div>
            <Header />
            <div style={{ marginTop: "62px" }}>
                <Routes>
                    <Route
                        path="/homepage"
                        element={
                            <React.Suspense fallback={<></>}>
                                <LaunchPage />
                            </React.Suspense>
                        }/>
                    <Route
                        path="/MyCareerSite"
                        element={
                            <React.Suspense fallback={<></>}>
                                <MyCareerSite />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/template"
                        element={
                            <React.Suspense fallback={<></>}>
                                <BlankTemplate />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/PublishSucess"
                        element={
                            <React.Suspense fallback={<></>}>
                                <SuccessScreen />
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/admin-access"
                        element={
                            <React.Suspense fallback={<></>}>
                                <AdminAccessPage />
                            </React.Suspense>
                        }
                    />
                    <Route path="*" element={<NotFound showPageNotFoundError={true} />} />
                </Routes>
            </div>
        </div>
    );
};
export default CareerPortal;
