import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet";
import AppBar from '@mui/material/AppBar';
import Modal from "@mui/material/Modal";
import { success, danger } from "../helpers/message-box";
import DefaultTempaltes from "./defaultTemplates";
import MyCareerSite from "./MyCareerSite";
import ApiCalls from "../../api/customAxios";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Footer from "../shared/Footer";
import EditorApiCalls from "../../api/customAxiosEditor";
import { functiondisable, functionenable } from "../helpers/helper";
import { storeIntoLocal, saveUserData, saveCookie } from '../helpers/helper-data';
import KeycloakService from "../../keycloak";
const Constant = require("../helpers/constants");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Box sx={{ py: 2, px: 5 }}>
            <Typography>{children}</Typography>
          </Box>
          <Footer></Footer>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LaunchPage = (props) => {
  let navigate = useNavigate();
  const { state, search } = useLocation();
  const [enterpriseUrl, setEnterpriseUrl] = useState(process.env.REACT_APP_ENTERPRISE_URL);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const [uploadFile, setUploadFile] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [openUploadScreen, setOpenUploadScreen] = useState(false);
  const [value, setValue] = React.useState(state?.afterSuccess ? state?.afterSuccess : 0);

  const [templates, setTemplates] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isloggedIn, setLoggedIn] = useState(true);
  const [_keyword, setKeyword] = useState("");
  const [searhKeyword, setSearchKeyword] = useState("");
  const inputRef = React.useRef();
  const [noSearchResultsFound, setNoSearchResultsFound] = useState(false);
  const [openGetStarted, setOpenGetStarted] = useState(false);
  const [category, setCategory] = useState("All");
  const [myCareerSite_data, setMyCareerSite_data] = useState([]);
  const is_admin = ["T", "H", "A", "Z"];

  const params = new URLSearchParams(search);

  useEffect(() => {
    if (process.env.REACT_APP_ORIGIN === "neurodiversify") {
      document.querySelector("link[title=icon]").setAttribute("href", "/neuro-favicon.png");
    } else {
      document.querySelector("link[title=icon]").setAttribute("href", "/favicon.png");
    }
    // document.documentElement.style.setProperty("--loader-bg", '#d6d7d9', "important");
  }, []);

  useEffect(() => {
    window.onbeforeunload = null;
    // if (localStorage.getItem("token") && localStorage.getItem("accessToken")) {
    //   setIsLoading(true);
    //   getUserDetails();
    // } else {
    //   navigate("/");
    // }
      const asyncOps = async () => {
          try {
              const is_homepage = Array.from(params.keys()).length > 0;
              let is_msp = params.get("childEnterpriseId") && params.get("childEnterpriseId") != "null";
              if (!localStorage.getItem('client_name') || is_msp) {
                  await getUserDetails();
              } else {
                  await getEnterpriceTemplates();
              }
              await removeQueryParams();
          } catch (err) {
              console.error('error fetching enterprise templates ->', err);
          }
      };

    if (KeycloakService.isLoggedIn()) {
      if (params.get("enterprise_id")) {
        localStorage.removeItem("client_name");
        localStorage.setItem("enterprise_id", params.get("enterprise_id"));
        saveCookie("enterprise_id", params.get("enterprise_id"), 365);
      }
      asyncOps();
    }
  }, []);

    const removeQueryParams = async () => {
        // Get the current URL without query parameters
        const baseUrl = window.location.origin + window.location.pathname;

        // Update the URL without query parameters
        window.history.replaceState({}, document.title, baseUrl);
    };
  const getUserDetails = () => new Promise((resolve, reject) => {
    ApiCalls
      .get('enterprises/user/0/1')
      .then(async (response) => {
        let enterpriseUser = response[0];
          if (params.get("childEnterpriseId") && params.get("childEnterpriseId") != "null" && enterpriseUser?.managedEnterprises != null) {
            enterpriseUser?.managedEnterprises?.length > 0 && enterpriseUser?.managedEnterprises?.filter((e) => {
              if (e.id == params.get("childEnterpriseId")) {
                  localStorage.setItem("client_name", e.id);
                storeIntoLocal(e);
              }
            });
          } else {
            localStorage.setItem("client_name", enterpriseUser.enterpriseId);
            storeIntoLocal(enterpriseUser);
            localStorage.removeItem("childEnterpriseId");
            localStorage.removeItem("MSPName");
          };
          saveUserData(enterpriseUser);
          localStorage.setItem("mspEnterprsieName", params.get("mspName") ? params.get("mspName") : enterpriseUser.establishmentName);
          localStorage.setItem("appOrigin", params.get("appOrigin") ? params.get("appOrigin") : process.env.REACT_APP_ORIGIN === "neurodiversify" ? "N" : "D");
        if (is_admin.indexOf(enterpriseUser.userType) == -1) {
          navigate("/");
        }
        setLoggedIn(true);
        await getEnterpriceTemplates();
        setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        if (error.statusCode == 403) {
          localStorage.removeItem("enterprise_id");
          localStorage.removeItem("client_name");
          navigate("../admin-access");
        } else {
          localStorage.clear();
        }
        setIsLoading(false);
        reject(error);
      });
  });
  const handleChange = (event, newValue) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (newValue == 0) {
      getSavedTemplates(1);
    }
    if (newValue == 2) {
      getEnterpriceTemplates();
    }
    setValue(newValue);
    if (searhKeyword || searhKeyword != "") {
      clearSearch();
    }
  };

  let url = process.env.REACT_APP_EDITOR_URL_GRAPEJS;
  let baseUrl = process.env.REACT_APP_EDITOR_BASE_URL_GRAPEJS;

  useEffect(() => {
    getSavedTemplates();
  }, []);

  const getEnterpriceTemplates = async () => {
      console.log("Getting enterprise templates --------------");
    await EditorApiCalls.get(`/templates?page=1`)
      .then((res) => {
          console.log("Enterprise templates synced successfully.");
        setMyCareerSite_data(res.data.data);
        if (res.data.data.data.length > 0) {
          setValue(2);
        } else if (res.data.data.published_data != null) {
          setValue(2);
        }
      }).catch(function (error) {
        if (error?.statusCode != 500) {
          danger(error);
        }
      });
  };
  const getSavedTemplates = (number) => {
    setIsLoading(true);
    setPageNumber(1);
    EditorApiCalls.get(`/default-templates?page=${number ? number : pageNumber}`)
      .then((res) => {
        const { data } = res.data.data;
        setTemplates(data);
        setNoSearchResultsFound(false);
        setPageNumber(res.data.data.page_number);
        setPageCount(res.data.data.page_count);
        setTotalCount(res.data.data.total_count);
        setTotalPage(res.data.data.total_pages);
        setIsLoading(false);
      }).catch(function (error) {
        if (error?.statusCode != 500) {
          danger(error);
        }
        setIsLoading(false);
      });
  };

  const handlePageChange = (event, value) => {
    setIsLoading(true);
    let paginate = searhKeyword != "" ? `/default-templates?page=${value}&search=${searhKeyword}` : `${url}/default-templates?page=${value}`;
    EditorApiCalls.get(paginate)
      .then((res) => {
        setIsLoading(false);
        const { data } = res.data.data;
        setTemplates(data);
        setPageNumber(res.data.data.page_number);
        setPageCount(res.data.data.page_count);
        setTotalCount(res.data.data.total_count);
        setTotalPage(res.data.data.total_pages);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }).catch(function (error) {
        if (error?.statusCode != 500) {
          danger(error);
        }
        setIsLoading(false);
      });
  };

  //  search functionality
  useEffect(() => {
    if (searhKeyword || searhKeyword != "") {
      setIsLoading(true);
      setPageNumber(1);
      EditorApiCalls.get(`/default-templates?page=1&search=${searhKeyword}`)
        .then((res) => {
          setIsLoading(false);
          const { data } = res.data.data;
          setTemplates(data);
          data.length === 0
            ? setNoSearchResultsFound(true)
            : setNoSearchResultsFound(false);
          setPageNumber(res.data.data.page_number);
          setPageCount(res.data.data.page_count);
          setTotalCount(res.data.data.total_count);
          setTotalPage(res.data.data.total_pages);
        }).catch(function (error) {
          if (error?.statusCode != 500) {
            danger(error);
          }
          setIsLoading(false);
        });
    }
  }, [searhKeyword]);

  const handleEnterKey = (e) => {
    setKeyword(e.target.value);
    const _keyword = e.target.value;
    if (e.key === "Enter") {
      e.preventDefault();
      setPageNumber(1);
      setSearchKeyword(_keyword);
    }
    if (e.key === "Backspace" && e.target.value?.length <= 1) {
      e.preventDefault();
      setPageNumber(1);
      setSearchKeyword("");
      setKeyword("");
      getSavedTemplates(1);
      setNoSearchResultsFound(false);
    }
  };
  const clearSearch = () => {
    setPageNumber(1);
    setSearchKeyword("");
    setKeyword("");
    getSavedTemplates(1);
    setNoSearchResultsFound(false);
  };
  const ClickKeyword = () => {
    setPageNumber(1);
    setSearchKeyword(_keyword);
  };

  const handleFileReader = (event) => {
    // Update the state
    setUploadFile(event.target.files[0]);
  };

  const uploadHandler = () => {
    console.log(uploadFile);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      uploadFile,
      uploadFile.name
    );
    axios.post(url + '/upload', formData, {

    })
      .then(function (response) {
        success('Uploaded successfuly.');
        handleClose();
        getSavedTemplates();
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const blankTemplate = () => {
    localStorage.removeItem('enterprise_template_id');
    localStorage.removeItem('template_id');
    localStorage.setItem('create_from_scratch', 1);
    navigate('../template');
  };
  // TODO fix url for view base url
    const viewTemplate = (template) => {
        let path = template.complete_path;
        if (template.folder_name == "CP00201") {
            let appOrigin = localStorage.getItem("appOrigin") === "N" ? "2" : localStorage.getItem("appOrigin") === "H" ? "1" : "0";
            path = path + '?domain=' + localStorage.getItem('encoded_domain') + '&origin=' + appOrigin;
        }
        window.open(`${baseUrl}${path}`, "_blank");
    };

  function fetchHtml(template) {
    localStorage.removeItem('enterprise_template_id');
    localStorage.removeItem('create_from_scratch');
    localStorage.setItem('template_id', template?.template_id);
    setIsLoading(true);
    EditorApiCalls.get(`/default-templates/${template.template_id}`)
      .then((res) => {
        const { data } = res.data;
        setIsLoading(false);
        navigate('../template', {
          state: {
            "html": data.html,
            'template_name': template?.folder_name,
            "css": "",
            "template_overview": template.overview,
            "mode": "Templates"
          }
        });
      })
      .catch(function (error) {
        if (error?.statusCode != 500) {
          danger(error);
        }
      });
  }

  const categoryFilter = (e) => {
    setCategory(e.target.value);
    setIsLoading(true);
    setPageNumber(1);
    EditorApiCalls.get(`/default-templates?page=1&category=${e.target.value == "All" ? "" : e.target.value}`)
      .then((res) => {
        setIsLoading(false);
        const { data } = res.data.data;
        setTemplates(data);
        data.length === 0
          ? setNoSearchResultsFound(true)
          : setNoSearchResultsFound(false);
        setPageNumber(res.data.data.page_number);
        setPageCount(res.data.data.page_count);
        setTotalCount(res.data.data.total_count);
        setTotalPage(res.data.data.total_pages);
      }).catch(function (error) {
        if (error?.statusCode != 500) {
          danger(error);
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="">
      <Helmet>
        {localStorage.getItem("appOrigin") === "N" ? <title>Career Portal | {Constant.APP_NAME_NEURODIVERSIFY}</title>:
        localStorage.getItem("appOrigin") === "H" ? <title>Career Portal | {Constant.APP_NAME_DIRECT_HEALTH}</title> : <title>Career Portal | {Constant.APP_NAME_GENERAL}</title>}
        <meta name="description" content={"Career Portal" | `${Constant.APP_NAME_GENERAL}`} />
        <link
          rel={Constant.APP_NAME_DIRECT_HEALTH}
          href="https://candidate-dsaas.simplifycareers.com/"
        />
      </Helmet>
      {isLoading && <Loader />}

      {isloggedIn &&
        <>
          <div className="Home">
            <header className="Home-header">
              <AppBar className="header-margin" style={{ boxShadow: "0 0px 15px rgb(0 0 0 / 20%)" }}>
                <Box className="primaryColor" sx={{ width: '100%' }}>
                  <Box>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" indicatorColor="white" disableRipple={true}
                      disableFocusRipple={true}
                      textColor="primary"
                      className="career-tabs px-30">
                      <Tab label={<span className={`${value == 0 && "header-Button"}`}>Templates</span>} {...a11yProps(0)} className="font-weight-600" style={{ textTransform: "none", color: "#FFF", fontSize: "16px", letterSpacing: "0em" }} />
                      <Tab label={<span className={`${value == 1 && "header-Button"}`}>Create From Scratch</span>} onClick={() => blankTemplate()} className="font-weight-600" style={{ textTransform: "none", color: "#FFF", fontSize: "16px", letterSpacing: "0em" }} />
                      <Tab label={<span className={`${value == 2 && "header-Button"}`}>My Career Site</span>} {...a11yProps(2)} className="font-weight-600" style={{ textTransform: "none", color: "#FFF", fontSize: "16px", letterSpacing: "0em" }} />
                    </Tabs>
                  </Box>
                  {/* {value == 0 && <Grid item lg={2} md={2} xs={2}>
                    <Typography className="pr-50">
                      <Select
                        align="left"
                        className="form-control p-0 mb-0 campaign"
                        placeholder="test"
                        value={category}
                        onChange={(e) => categoryFilter(e)}
                        onMouseDown={(e) => {
                            e.stopPropagation(); functiondisable();
                        }}
                        onClick={(e) => functionenable()}
                      >
                        <MenuItem value="All">All Templates</MenuItem>
                        <MenuItem value="Ecommerce">Ecommerce</MenuItem>
                        <MenuItem value="Healthcare">Healthcare</MenuItem>
                        <MenuItem value="IT">Information Technology</MenuItem>
                        <MenuItem value="Light Engineering">Light Engineering</MenuItem>
                        <MenuItem value="Logistics">Logistics</MenuItem>
                        <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                        <MenuItem value="Retail">Retail</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </Typography>
                  </Grid>} */}
                </Box>
              </AppBar>
            </header>
          </div>


          <Box style={{ marginTop: "137px" }} id='my-templates'>
            <TabPanel value={value} index={0} className="career-site">
              <DefaultTempaltes
                templates={templates}
                totalCount={totalCount}
                totalPage={totalPage}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
                viewTemplate={viewTemplate}
                fetchHtml={fetchHtml}
                inputRef={inputRef}
                _keyword={_keyword}
                clearSearch={clearSearch}
                handleEnterKey={handleEnterKey}
                ClickKeyword={ClickKeyword}
                setOpenGetStarted={setOpenGetStarted}
                noSearchResultsFound={noSearchResultsFound}
                baseUrl={baseUrl}
              />
            </TabPanel>
            <TabPanel value={value} index={1} ></TabPanel>
            <TabPanel value={value} index={2}>
              <MyCareerSite openUploadScreen={openUploadScreen} getEnterpriceTemplates={getEnterpriceTemplates}
                setOpenUploadScreen={setOpenUploadScreen} myCareerSite_data={myCareerSite_data}></MyCareerSite>
            </TabPanel>
          </Box>
          <Modal
            open={openGetStarted}
            onClose={(e) => setOpenGetStarted(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="career-modal-content inputcontrasts">
              <Typography id="modal-title" className="d-flex align-between">
                <Typography variant="h5" className="f-20 font-weight-600">
                  Choose an option to start
                </Typography>
                <Typography align="right" className="cursor-hover sub-text-color" aria-label="Close icon">
                  <CloseIcon onClick={(e) => setOpenGetStarted(false)} onKeyPress={(e) => setOpenGetStarted(false)} tabIndex={0} aria-label="Close icon" />
                </Typography>
              </Typography>
              <Grid item>
                <Grid item id="modal-description" className="text-center mt-40 mb-30 p-0">
                  <Typography className="post-button ml-10 mb-20 job-detail-matching">
                    <button
                      type="button"
                      className="btn profile-button small-text-font position-issue postJob-btn modal-buttons"
                      onClick={() => navigate('../template')}
                      aria-label="Upload job"
                    >
                      Create From Scratch
                    </button>
                  </Typography>
                  <Typography className="post-button ml-10 job-detail-matching">
                    <button
                      type="button"
                      className="btn profile-button small-text-font position-issue postJob-btn modal-buttons"
                      onClick={() => {
                          setValue(2); window.scrollTo({ top: 0, behavior: "smooth" }); setOpenGetStarted(false); setOpenUploadScreen(true);
                      }}
                      aria-label="Upload job"
                    >
                      Upload Template&nbsp;&nbsp;&nbsp;&nbsp;
                    </button>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="modalStyle inputcontrasts">
              <Grid container id="modal-title">
                <Grid item xs={6}>
                  <Typography className="f-20 font-weight-600 modalTitle">
                    Upload Template
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" className="cursor-hover sub-text-color">
                    <CloseIcon aria-label="Close icon" tabIndex="0" onClick={handleClose} />
                  </Typography>
                </Grid>
              </Grid>
              <div className="App">

                <input className="primary-text-color"
                  onChange={handleFileReader}
                  type="file"
                  accept=".zip,.rar,.7zip"
                />
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                <Button
                  type="button"
                  style={{ width: "40%" }}
                  onClick={uploadHandler}
                  className="btn primary-button w-40"
                  variant="contained"
                  disableRipple={true}
                  aria-label="button"
                >
                  Upload
                </Button>
              </Typography>
            </Box>
          </Modal> </>}
    </div>
  );
};

export default LaunchPage;
