import axios from "axios";
import { useNavigate } from "react-router-dom";
import { authHeader } from "../Views/helpers/auth-header";
import { logout, isSessionValid } from "../Views/helpers/helper";
import KeycloakService from "../keycloak";
import { extractValueFromCookie } from "../Views/helpers/helper-data";
import { danger } from "../Views/helpers/message-box";
import store from "../redux/store";
import { getPageNotFoundRecode } from "../redux/pageNotFoundReducer";

let baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL,
  timeout: 600000,
});
// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    // let userToken = authHeader();
    // await userToken
    //   .then((res) => {
        config.headers["Authorization"] = `Bearer ${KeycloakService.getToken()}`;
        config.headers["x-access-token"] = KeycloakService.getIdToken();
        if (localStorage.getItem("enterprise_id") || extractValueFromCookie("enterprise_id", document.cookie)) {
          config.headers["enterpriseId"] = localStorage.getItem("enterprise_id") ? localStorage.getItem("enterprise_id") : extractValueFromCookie("enterprise_id", document.cookie);
        }
      // })
      // .catch((e) => {
      //   // signOut();
      // });
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      // isSessionValid().then((res) => {
      //   if (res) {
      //     let navigate = useNavigate();
      //     navigate("/enterprise/admin-access");
      //   } else {
      //   }
      // });
      signOut();
    } else if (status === 404) {
      store.dispatch(getPageNotFoundRecode(true));
    } else if (status === 500) {
      danger("Oops! Something went wrong. Please try again later.");
    }
    return Promise.reject(error);
  }
);

const signOut = async () => {
  await logout();
  window.location.href = "/";
};
export default class ApiCalls {
  static get(url) {
    return new Promise((resolve, reject) => {
      return api
        .get(url)
        .then((res) => resolve(res.data.responsePayload))
        .catch((err) => reject(err?.response?.data));
    });
  }

  static getEntireResponseData(url) {
    return new Promise((resolve, reject) => {
      return api
        .get(url)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err?.response?.data));
    });
  }
  static postEntireResponseData(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .post(url, payload)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err?.response?.data));
    });
  }
  static post(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .post(url, payload)
        .then((res) => resolve(res.data.responsePayload,))
        .catch((err) => reject(err?.response?.data));
    });
  }

  static put(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .put(url, payload)
        .then((res) => resolve(res.data.responsePayload))
        .catch((err) => reject(err?.response?.data));
    });
  }

  static patch(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .patch(url, payload)
        .then((res) => resolve(res.data.responsePayload))
        .catch((err) => reject(err?.response?.data));
    });
  }

  static delete(url, payload) {
    return new Promise((resolve, reject) => {
      return api
        .delete(url, payload)
        .then((res) => resolve(res.data.responsePayload))
        .catch((err) => reject(err?.response?.data));
    });
  }
}
