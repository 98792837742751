import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Box, TextareaAutosize, Input, TextField, InputAdornment } from "@mui/material";
import { ReactComponent as PublishIcon } from "../../assets/images/publication.svg";
import PublishImage from "../../assets/images/Published-screen.svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { success } from "../helpers/message-box";
import Loader from '../Loader';

const SuccessScreen = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("published_url", location.state?.published_url);
        console.log("is_publishing", localStorage.getItem('is_publishing'));

        window.onbeforeunload = null;
        var storedTimer = localStorage.getItem('timer');
        if (localStorage.getItem('is_publishing') == 'true') {
            localStorage.setItem('is_publishing', false);
            storedTimer = 30;
        }

        if (storedTimer) {
            const parsedTimer = parseInt(storedTimer, 10);
            if (!isNaN(parsedTimer)) {
                setTimer(parsedTimer);
            }
        }
        setTimeout(() => {
            setLoading(false);
        }, 400);
    }, []);

    useEffect(() => {
        localStorage.setItem('timer', timer.toString());
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else {
            success('Template published successfully.');
        }
    }, [timer]);

    const viewSite = () => {
        window.open(`${location.state?.published_url}`, "_blank");
    };

    return (
        <Grid>
            {loading && <Loader />}
            <Grid container item spacing={1} lg={12} md={12} xs={12}>
                <Grid
                    item lg={4} md={4} xs={6}
                    className="m-auto text-center" id='publish-sucess'>
                    <img src={PublishImage} className="mt-30" alt="img"/>
                    <Typography variant="h5" className="f-20 mb-20 align-center d-flex font-weight-600">
                        <PublishIcon className="mr-10 fill-svg-icons fill-svg-circle" />
                        <span>Publication</span>
                    </Typography>
                    <Typography className='text-left'>
                        {timer > 0 &&
                            <Button className="publish-button" aria-label="button">Publishing</Button>
                        }
                        {!timer &&
                            <Button className="publish-button" aria-label="button">Published</Button>
                        }
                    </Typography>
                    <Typography className='sub-text-color mt-20 mb-20 text-left'>Click on the below link to view your site. You can also access it from the Dashboard &gt; Your career site</Typography>
                    <Typography className='sub-text-color mt-20 mb-20 text-left'>You have to add DNS (A) record to point to this IP {process.env.REACT_APP_PUBLISH_IP}</Typography>
                    <Typography className='sub-text-color mt-20 mb-20 text-left'>Published URL: {location.state?.published_url}</Typography>
                    <Typography className='sub-text-color mt-20 mb-20 text-left'>
                        {timer > 0 ? (
                            <>
                                Your portal is getting published, please wait...{" "}
                                <span className="brand-color font-weight-500"> {timer}s </span>
                            </>
                        ) : (
                            <>
                                Your portal is published. <span style={{ cursor: "pointer" }} className="brand-color font-weight-500" onClick={() => viewSite()}>View Career Site</span>
                            </>
                        )}
                    </Typography>
                    <Typography className='text-left mb-20'>
                        <Button
                            type="submit"
                            className="btn profile-button mr-10 font-weight-600"
                            variant="contained"
                            aria-label="Submit"
                            onClick={() => navigate("../homepage", { state: { afterSuccess: 2 } })}
                        >
                            Close
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SuccessScreen;
