import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

function ConfirmDialog({
    open,
    setOpen,
    dialogTitle,
    description,
    handleConfirmYes,
}) {
    const location = useLocation();
    let navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (location.pathname === "/candidate/complete-profile") {
            if (reason && reason === "backdropClick") {
                return;
            }
            setOpen(false);
            navigate("/candidate/create-profile");
        } else {
            setOpen(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="">
                {dialogTitle}
            </DialogTitle>
            <DialogContent className="">
                <DialogContentText
                    id="alert-dialog-description"
                    className="f-14 font-weight-400"
                >
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="pb-20">
                <Button
                    className="confirm-link dialog-secondary font-weight-600"
                    onClick={handleClose}
                    disableRipple="true"
                    onKeyPress={handleClose}
                    tabIndex={0}
                    aria-label="button"
                >
                    No
                </Button>
                <Button
                    className="confirm-link dialog-primary"
                    onClick={handleConfirmYes}
                    autoFocus
                    disableRipple="true"
                    onKeyPress={handleConfirmYes}
                    tabIndex={0}
                    aria-label="button"
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
