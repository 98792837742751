import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography, MenuItem, Tooltip
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { ReactComponent as LogoutNavIcon } from "../../assets/images/logout-navIcon.svg";
import Loader from "../Loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { logout } from "../helpers/helper";
import ApiCalls from "../../api/customAxios";
import { ReactComponent as SupportIcon } from "../../assets/images/Support.svg";
import FeedBackModal from "./FeedBackModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeycloakService from "../../keycloak";
import { getUserType, setPlatformTheme, updateData } from '../helpers/helper-data';
const Constant = require("../helpers/constants");

const Header = (props) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const URLPath = location.pathname;

  const [enterpriseUrl, setEnterpriseUrl] = useState(process.env.REACT_APP_ENTERPRISE_URL);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [enterpriseUser, setEnterpriseUser] = React.useState({});
  const [isloggedIn, setLoggedIn] = useState(false);
  const [isChildEnterprise, setChildEnterprise] = useState(false);
  const [logoUrl, setLogoUrl] = useState();
  const open2 = Boolean(anchorE2);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openfeedback, setOpenFeedback] = useState(false);
  const is_admin = ["T", "H", "A", "Z"];

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  useEffect(() => {
    if (process.env.REACT_APP_ORIGIN === "neurodiversify") {
      document.querySelector("link[title=icon]").setAttribute("href", "/neuro-favicon.png");
    } else {
      document.querySelector("link[title=icon]").setAttribute("href", "/favicon.png");
    }
  }, []);
  const getUserDetails = () => {
    ApiCalls
      .get('enterprises/user/0/1')
      .then((response) => {
        let enterpriseUser = response[0];
        let loaderColor = "";

          if (localStorage.getItem("client_name") && enterpriseUser?.managedEnterprises != null) {
          enterpriseUser?.managedEnterprises?.length > 0 && enterpriseUser?.managedEnterprises?.filter((e) => {
            if (e.id == localStorage.getItem("client_name")) {
              // setLogoUrl(e.navBarLogoSignedUrl);
              updateData(e);
              setPlatformTheme(e.theme, process.env.REACT_APP_ORIGIN);
              setEnterpriseUser(e);
              setChildEnterprise(true);
            }
          });
          } else {
            updateData(enterpriseUser);
            // setLogoUrl(enterpriseUser.navBarLogoSignedUrl);
            setPlatformTheme(enterpriseUser.theme, process.env.REACT_APP_ORIGIN);
            setEnterpriseUser(response[0]);
            setChildEnterprise(false);
          }
          if (is_admin.indexOf(enterpriseUser.userType) != -1) {
              setLoggedIn(true);
          } else {
            navigate("/");
          }
        setTimeout(() => {
        setIsLoading(false);
        document.documentElement.style.setProperty("--loader-bg", 'rgba(0, 0, 0, 0.2)', "important");
        }, 3000);
      })
      .catch((error) => {
        if (error.statusCode == 403) {
          localStorage.removeItem("enterprise_id");
          localStorage.removeItem("client_name");
          navigate("./admin-access");
        }  else {
          localStorage.clear();
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (KeycloakService.isLoggedIn()) {
      document.documentElement.style.setProperty("--loader-bg", '#d6d7d9', "important");
      setIsLoading(true);
      getUserDetails();
    } else {
      navigate("/");
    }
  }, [localStorage.getItem('enterprise_id')]);
  const Logout = async () => {
    // localStorage.clear();
    let text = "Reload Site?\nChanges you made may not be saved.";
    if (URLPath === '/candidate/template' && localStorage.getItem('isTemplateSaved') == 0) {
      const leavePage = window.confirm(text);
      if (leavePage) {
        setIsLoading(true);
        await logout();
        navigate("/");
        setIsLoading(false);
        localStorage.removeItem('isTemplateSaved');
      }
    } else {
      setIsLoading(true);
      await logout();
      navigate("/");
      setIsLoading(false);
      localStorage.removeItem('isTemplateSaved');
    }
    document.documentElement.style.setProperty("--primary-color", (process.env.REACT_APP_ORIGIN === "neurodiversify" ? "#055094" : process.env.REACT_APP_ORIGIN === "healthcare" ? "#45AC8B" : "#0071E3"), 'important');
    document.documentElement.style.setProperty("--secondary-color", (process.env.REACT_APP_ORIGIN === "neurodiversify" ? "#EAF4FF" : process.env.REACT_APP_ORIGIN === "healthcare" ? "#F0FFFA" : "#E5f0FC"), 'important');
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (event) => {
    setAnchorEl(null);
  };

  const goToHomePage = async () => {
    let text = "Reload Site?\nChanges you made may not be saved.";
    if (URLPath === '/candidate/template' && localStorage.getItem('isTemplateSaved') == 0) {
      const leavePage = window.confirm(text);
      if (leavePage) {
        navigate("/candidate/homepage");
        localStorage.removeItem('isTemplateSaved');
      }
    } else {
      navigate("/candidate/homepage");
      localStorage.removeItem('isTemplateSaved');
    };
  };
  const handleOpenFeedBack = () => {
    setOpenFeedback(true);
  };
  const handleFeebackModalClose = () => {
    setOpenFeedback(false);
  };
  return (
    <div className="">
      {isLoading && <Loader />}
      <Grid className={`navbar-fixed ${!isloggedIn ? "pt-10 pb-12" : ""}`}>
        <Grid container spacing={1} style={{ marginTop: "0px" }}>
          <Grid
            container
            spacing={1}
            item
            lg={3}
            md={3}
            xs={3}
            className="align-center"
          >
            <Box className="anchorlogo pl-10" style={{ cursor: "pointer" }}
              onClick={() => goToHomePage()}
            >
              <img
                className="logoImg-header contrasts"
                src={(localStorage.getItem("logo") != null && localStorage.getItem("logo") != "null" && localStorage.getItem("logo") != "") ? localStorage.getItem("logo") : localStorage.getItem("appOrigin") === "N" ? Constant.APP_LOGO_NEURODIVERSIFY : Constant.APP_LOGO_GENERAL}
                alt="Logo"
                tabIndex="-1"
              />
            </Box>
          </Grid>
          <Grid container item lg={5} md={5} xs={1}></Grid>
          <Grid
            container
            spacing={4}
            lg={4}
            md={4}
            xs={8}
            className="align-center justify-flex-end" style={{ marginLeft: "0px" }}
          >{isMobile ?
          <>
              <Box
                tabIndex={0}
                onKeyPress={handleOpenFeedBack}
                className="ml-10 m-r-10 mt-30 cursor-hover headerIcon"
                onClick={handleOpenFeedBack}
              >
              <Tooltip title="Feedback" placement="bottom">
                <SupportIcon
                  style={{ width: "24px" }}
                  aria-label="Message icon"
                  className="contrasts"
                />
                </Tooltip>
              </Box>
              <Button
                    id="basic-button2"
                    aria-controls={open2 ? "account-menu" : undefined}
                    aria-expanded={open2 ? "true" : "false"}
                    aria-haspopup="true"
                    className="dropdown-arrow text-transform-none"
                    onClick={handleClick2}
                    role="button"
                    aria-label="Profile picture"
                    disableRipple={true}
                  ><Grid className="d-flex align-center">
                      <Box>
                      {localStorage.getItem("childEnterpriseId") == null ?
                        <img
                          width="40px"
                          height="40px"
                          className="default-img-border mr-5"
                          src={(localStorage.getItem("profile_image") && localStorage.getItem("profile_image") != "null") ? localStorage.getItem("profile_image") : Constant.DEFAULT_LOGO}
                          alt="Profile picture"
                          style={{ marginTop: "33px" }}
                        />
                          : (localStorage.getItem("profile_image") != "null" ?
                            <img
                          width="40px"
                          height="40px"
                          className="default-img-border mr-5"
                          src={localStorage.getItem("profile_image")}
                          alt="Profile picture"
                          style={{ marginTop: "33px" }}
                        /> :
                          <Typography
                            className="default-img-border mr-10 justify-center align-center d-flex primary-shadow-color default-img-border f-14 font-weight-600 text-capitalize"
                            width="40px"
                            height="40px"
                            aria-label="Default image"
                            style={{ marginTop: "33px" }}
                          >
                            {localStorage.getItem("enterprise_name")?.trim().substring(0, 1)}
                          </Typography>
                          )}
                      </Box>
                      <Box style={{ marginTop: '32px', textAlign: "center" }}>
                        <Tooltip
                          title={
                            localStorage.getItem("childEnterpriseId") == null ? <div>
                                {localStorage.getItem("enterprise_name")} <br />
                                {localStorage.getItem("user_name")}<br />
                                {localStorage.getItem("profileTitle") && localStorage.getItem("profileTitle") != 'null' && (
                                    <div>
                                      {localStorage.getItem("profileTitle")}
                                      <br/>
                                    </div>
                                )}
                                {localStorage.getItem("email") && localStorage.getItem("email") != 'null' && (
                                    <div>
                                      {localStorage.getItem("email")}
                                    </div>
                                )}
                              </div> :<div>
                                {localStorage.getItem("MSPName")}
                                <Box>
                                  <span className="mr-5">via</span>
                                  {localStorage.getItem("mspEnterprsieName")}
                                </Box>
                              </div>
                            }
                          placement="top"
                        >
                          <Typography
                                variant="h5"
                                className="w-110 gray9 small-text-font font-weight-500 cursor-hover enterprise-name-width enterprise-user-name align-left textEllipse"
                                // sx={{ pb: 1 }}
                              >
                               {localStorage.getItem("enterprise_name")}
                               {localStorage.getItem("enterprise_name") !== localStorage.getItem("mspEnterprsieName") && <Typography className="f-12 gray7 font-weight-500 mt-5 textEllipse enterprise-name-width">Via {localStorage.getItem("mspEnterprsieName")}</Typography>}
                              </Typography>
                        </Tooltip>
                      </Box>
                       <ExpandMoreIcon
                          className="ml-10 mt-35 cursor-hover inputcontrasts fill-svg-icons gray9"
                          aria-label="Dropdown icon"
                          style={{ marginBottom: '7px' }}
                        />
                      </Grid></Button>
                      <Menu
                    id="account-menu"
                    anchorEl={anchorE2}
                    open={open2}
                    onClose={handleClose2}
                    onClick={handleClose2}
                    onKeyPress={handleClose2}
                    className="logout-icon"
                    disableScrollLock={true}
                    MenuListProps={{
                      "aria-labelledby": "basic-button2",
                    }}
                    KeepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <MenuItem className="pl-25 pr-25">
                    <Grid item className="justify-end align-center d-flex cursor-hover">
                    <Box>
                    {localStorage.getItem("childEnterpriseId") == null ?
                        <img
                          width="42px"
                          height="42px"
                          className="default-img-border mr-5 mt-5"
                          src={(localStorage.getItem("profile_image") && localStorage.getItem("profile_image") != "null") ? localStorage.getItem("profile_image") : Constant.DEFAULT_LOGO}
                          alt="Profile picture"
                        />
                        : (localStorage.getItem("profile_image") != "null" ?
                        <img
                          width="40px"
                          height="40px"
                          className="default-img-border mr-5"
                          src={localStorage.getItem("profile_image")}
                          alt="Profile picture"
                          style={{ marginTop: "33px" }}
                        /> :
                          <Typography
                            className="default-img-border mr-10 justify-center align-center d-flex primary-shadow-color default-img-border f-14 font-weight-600 text-capitalize"
                            width="40px"
                            height="40px"
                            aria-label="Default image"
                          >
                            {localStorage.getItem("enterprise_name")?.trim().substring(0, 1)}
                          </Typography>
                          )}
                      </Box>
                    {/* <Typography
                                variant="h5"
                                className="gray9 small-text-font font-weight-500 cursor-hover enterprise-name-width enterprise-user-name align-left"
                                // sx={{ pb: 1 }}
                              >
                               {enterpriseUser.establishmentName ? enterpriseUser.establishmentName : localStorage.getItem("enterprise_name")}
                               {isChildEnterprise && <Typography className="f-12 font-weight-500 textEllipse enterprise-name-width">via {localStorage.getItem("mspEnterprsieName")}</Typography>}
                              </Typography> */}
                               <Grid className="p-r-15 gray9 f-14 mt-5 mb-5">
                                <span className="gray9 f-14">
                                  {localStorage.getItem("user_name")
                                    ? localStorage.getItem("user_name")
                                    : localStorage
                                      .getItem("email")
                                      ?.split("@")[0]}{" "}
                                </span>
                                <br />
                                 {localStorage.getItem("profileTitle") && localStorage.getItem("profileTitle") != 'null' && (
                                     <div>
                                       {localStorage.getItem("profileTitle")}
                                       <br/>
                                     </div>
                                 )}
                                 {localStorage.getItem("email") && localStorage.getItem("email") != 'null' && (
                                     <div>
                                       {localStorage.getItem("email")}
                                     </div>
                                 )}
                              </Grid >
                              </Grid>
                    </MenuItem>
                  </Menu>
          <Box className="d-flex align-item-center" aria-label="Menu icon">
            <Button variant="text" onClick={handleMenu} aria-label="Menu icon" disableRipple={true} style={{ marginTop: "33px" }}>
              {" "}
              <MenuIcon className="svg-icons f-25 menuIcon" aria-label="Menu icon" />
            </Button>
          </Box>
            <Menu
              className="navbar-responsive"
              id="menu-appbar"
              anchorEl={anchorEl}
              disableScrollLock={true}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              KeepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={open}
            >
              <Box className="justify-end">
                <Box className="align-right">
                  <CloseIcon
                    className="f-25 font-weight-600 p-3px cursor-hover sub-text-color"
                    onClick={handleCloseMenu}
                    onKeyPress={handleCloseMenu}
                    aria-label="Close icon"
                    tabIndex="0"
                  />
                </Box>
                {!isloggedIn ? <>
                  <Box style={{ marginTop: '34px' }}>
                    <a href="#home" onClick={handleCloseMenu}
                      className="sub-text-color cursor-hover small-text-font text-decoration-none d-flex responsive-font font-weight-600"
                    >
                      Home
                    </a>
                  </Box>
                  <Box style={{ marginTop: '34px' }}>
                    <a href="#templates" onClick={handleCloseMenu}
                      className="sub-text-color cursor-hover small-text-font text-decoration-none d-flex responsive-font font-weight-600"
                    >
                      Website Templates
                    </a>
                  </Box>
                  {/* <Box style={{ marginTop: '34px' }}>
                    <a
                      className="sub-text-color cursor-hover small-text-font text-decoration-none d-flex responsive-font font-weight-600"
                      href="/signin"
                    >
                      Sign In
                    </a>
                  </Box> */}
                  </> : (<>
                    <Box className="danger-color icon-button f-12 align-center d-flex" onClick={() => Logout()}>
                      <LogoutNavIcon
                        className="mr-6 icon-button"
                        alt="Logout icon"
                        width="15px"
                        aria-label="Logout icon"
                      />
                      Logout
                    </Box></>)}
              </Box>
            </Menu>
          </>
            :
            <>
              {(!isloggedIn && localStorage.getItem("email") == null) ?
                <>
                  <Box style={{ marginTop: '34px' }}>
                    <a href="#home"
                      className="c-white cursor-hover small-text-font text-decoration-none d-flex responsive-font"
                    >
                      Home
                    </a>
                  </Box>
                  <Box style={{ marginTop: '34px', marginLeft: "55px" }}>
                    <a href="#templates"
                      className="c-white cursor-hover small-text-font text-decoration-none d-flex responsive-font"
                    >
                      Website Templates
                    </a>
                  </Box>
                  {/* <Box style={{ marginTop: '34px', marginLeft: "55px" }}>
                    <a
                      className="c-white cursor-hover small-text-font text-decoration-none d-flex responsive-font"
                      href="/signin"
                    >
                      Sign In
                    </a>
                  </Box> */}
                </> :
                <>
                {isloggedIn && <Box
                tabIndex={0}
                onKeyPress={handleOpenFeedBack}
                className="ml-10 m-r-10 mt-30 cursor-hover headerIcon"
                onClick={handleOpenFeedBack}
              >
              <Tooltip title="Feedback" placement="bottom">
                <SupportIcon
                  style={{ width: "24px" }}
                  aria-label="Message icon"
                  className="contrasts"
                />
                </Tooltip>
              </Box>}
                <Grid>
                  <Button
                    id="basic-button2"
                    aria-controls={open2 ? "account-menu" : undefined}
                    aria-expanded={open2 ? "true" : "false"}
                    aria-haspopup="true"
                    className="dropdown-arrow text-transform-none"
                    onClick={handleClick2}
                    role="button"
                    aria-label="Profile picture"
                    disableRipple={true}
                  ><Grid className="d-flex align-center">
                      <Box>
                      {localStorage.getItem("childEnterpriseId") == null ?
                        <img
                          width="40px"
                          height="40px"
                          className="default-img-border mr-5"
                          src={(localStorage.getItem("profile_image") && localStorage.getItem("profile_image") != "null") ? localStorage.getItem("profile_image") : Constant.DEFAULT_LOGO}
                          alt="Profile picture"
                          style={{ marginTop: "33px" }}
                        />
                          : (localStorage.getItem("profile_image") != "null" ?
                            <img
                          width="40px"
                          height="40px"
                          className="default-img-border mr-5"
                          src={localStorage.getItem("profile_image")}
                          alt="Profile picture"
                          style={{ marginTop: "33px" }}
                        /> :
                          <Typography
                            className="default-img-border mr-10 justify-center align-center d-flex primary-shadow-color default-img-border f-14 font-weight-600 text-capitalize"
                            width="40px"
                            height="40px"
                            aria-label="Default image"
                            style={{ marginTop: "33px" }}
                          >
                            {localStorage.getItem("enterprise_name")?.trim().substring(0, 1)}
                          </Typography>
                          )}
                      </Box>
                      <Box style={{ marginTop: '32px', textAlign: "center" }}>
                        <Tooltip
                         title={
                          localStorage.getItem("childEnterpriseId") == null ? <div>
                              {localStorage.getItem("enterprise_name")}
                              <br />
                              {localStorage.getItem("user_name")
                                  ? localStorage.getItem("user_name")
                                  : localStorage
                                      .getItem("email")
                                      ?.split("@")[0]}{" "}
                              <br />
                              {localStorage.getItem("profileTitle") && localStorage.getItem("profileTitle") != 'null' && (
                                  <div>
                                    {localStorage.getItem("profileTitle")}
                                    <br/>
                                  </div>
                              )}
                              {getUserType(localStorage.getItem("userType"))}
                              <br />
                              {localStorage.getItem("email") && localStorage.getItem("email") != 'null' && (
                                  <div>
                                    {localStorage.getItem("email")}
                                  </div>
                              )}
                            </div> :<div>
                              {localStorage.getItem("MSPName")}
                              <Box className="mt-5">
                                <span className="mr-5">via</span>
                                {localStorage.getItem("mspEnterprsieName")}
                              </Box>
                            </div>
                          }
                         position="top"
                         trigger="mouseenter"
                         animation="fade"
                         arrow
                        >
                          <Typography
                                variant="h5"
                                className="w-110 gray9 small-text-font font-weight-500 cursor-hover enterprise-name-width enterprise-user-name align-left textEllipse"
                                // sx={{ pb: 1 }}
                              >
                               {localStorage.getItem("MSPName") ? localStorage.getItem("MSPName") : localStorage.getItem("enterprise_name")}
                               {localStorage.getItem("MSPName") && localStorage.getItem("mspEnterprsieName") != 'undefined' && <Typography className="f-12 gray7 font-weight-500 mt-5 textEllipse enterprise-name-width">Via {localStorage.getItem("mspEnterprsieName")}</Typography>}
                              </Typography>
                        </Tooltip>
                      </Box>
                      {/* <ArrowDropDownIcon className="f-20 ml-10 mb-10 inputcontrasts" style={{ marginTop: "44px" }} aria-label="Dropdown icon" /> */}
                      {/* <ChevronDown
                        className="f-20 c-white mb-10 mt-35 inputcontrasts"
                        aria-label="Dropdown icon"
                        style={{ width: "16px" }}
                      /> */}
                       <ExpandMoreIcon
                          className="ml-10 mt-35 cursor-hover inputcontrasts fill-svg-icons gray9"
                          aria-label="Dropdown icon"
                          style={{ marginBottom: '7px' }}
                        />
                      </Grid></Button>
                  <Menu
                    id="account-menu"
                    anchorEl={anchorE2}
                    open={open2}
                    onClose={handleClose2}
                    onClick={handleClose2}
                    onKeyPress={handleClose2}
                    className="logout-icon"
                    disableScrollLock={true}
                    MenuListProps={{
                      "aria-labelledby": "basic-button2",
                    }}
                    KeepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <MenuItem className="border-bottom-gray4 pl-25 pr-25">
                    <Grid item className="justify-end align-center d-flex cursor-hover">
                    <Box>
                    {localStorage.getItem("childEnterpriseId") == null ?
                        <img
                          width="42px"
                          height="42px"
                          className="default-img-border mr-5 mt-5"
                          src={(localStorage.getItem("profile_image") && localStorage.getItem("profile_image") != "null") ? localStorage.getItem("profile_image") : Constant.DEFAULT_LOGO}
                          alt="Profile picture"
                        />
                        : (localStorage.getItem("profile_image") != "null" ?
                        <img
                          width="40px"
                          height="40px"
                          className="default-img-border mr-5"
                          src={localStorage.getItem("profile_image")}
                          alt="Profile picture"
                          style={{ marginTop: "33px" }}
                        /> :
                          <Typography
                            className="default-img-border mr-10 justify-center align-center d-flex primary-shadow-color default-img-border f-14 font-weight-600 text-capitalize"
                            width="40px"
                            height="40px"
                            aria-label="Default image"
                          >
                            {localStorage.getItem("enterprise_name")?.trim().substring(0, 1)}
                          </Typography>
                          )}
                      </Box>
                    {/* <Typography
                                variant="h5"
                                className="gray9 small-text-font font-weight-500 cursor-hover enterprise-name-width enterprise-user-name align-left"
                                // sx={{ pb: 1 }}
                              >
                               {enterpriseUser.establishmentName ? enterpriseUser.establishmentName : localStorage.getItem("enterprise_name")}
                               {isChildEnterprise && <Typography className="f-12 font-weight-500 textEllipse enterprise-name-width">via {localStorage.getItem("mspEnterprsieName")}</Typography>}
                              </Typography> */}
                               <Grid className="p-r-15 gray9 f-14 mt-5 mb-5">
                                  <span className="gray9 f-14">
                                    {localStorage.getItem("user_name")
                                        ? localStorage.getItem("user_name")
                                        : localStorage
                                            .getItem("email")
                                            ?.split("@")[0]}{" "}
                                  </span>
                                 <br />
                                 {localStorage.getItem("profileTitle") && localStorage.getItem("profileTitle") != 'null' && (
                                     <div>
                                       {localStorage.getItem("profileTitle")}
                                       <br/>
                                     </div>
                                 )}
                                 {getUserType(localStorage.getItem("userType"))}
                                 <br />
                                 {localStorage.getItem("email") && localStorage.getItem("email") != 'null' && (
                                     <div>
                                       {localStorage.getItem("email")}
                                     </div>
                                 )}
                              </Grid >
                              </Grid>
                    </MenuItem>
                    <MenuItem className="danger-color icon-button pl-25 f-14 mt-10" onClick={() => Logout()} aria-label="Logout icon">
                      <LogoutNavIcon
                        className="mr-6 icon-button"
                        alt="Logout icon"
                        width="20px"
                        aria-label="Logout icon"
                      />
                      Logout
                    </MenuItem>
                  </Menu></Grid></>}
            </>}
          </Grid>
        </Grid>
      </Grid>
      <FeedBackModal
        open={openfeedback}
        onClose={handleFeebackModalClose}
      />
    </div>
  );
};

export default Header;
