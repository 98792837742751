import { logout } from "./helper";
import KeycloakService from "../../keycloak";

export function authHeader() {
  // return authorization header with jwt token
  return new Promise((resolve, reject) => {
    resolve({
      Authorization: `Bearer ${KeycloakService.getToken()}`,
      "x-access-token": KeycloakService.getIdToken(),
    });
  });
}

function parseJwt(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getDSaaSCandidateIdFromToken() {
  // return authorization header with jwt token
  return new Promise((resolve, reject) => {
    if (!KeycloakService.isLoggedIn()) reject(new Error('user is not logged in'));
    resolve(KeycloakService.userInfo()?.dsaascandidateid);
  });
}
