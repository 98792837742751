const Constant = require("../helpers/constants");

export const formatDate = (date) => {
  var d;
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  } else {
    return null;
  }
};

export const formatDateYYYYMM = (date) => {
  var d;
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month].join("");
  } else {
    return null;
  }
};

export const handleSpaces = (event) => {
  var e = window.event || event;

  var key = e.keyCode;

  //space pressed

  if (key === 32) {
    //space

    if (event.target.value < 1) event.preventDefault();
  }
};
export const dateDisplayFormat = (date) => {
  var d;
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = monthNames[d.getMonth()];
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (day.length < 2) day = "0" + day;

    return month + " " + [day, year].join(", ");
  } else {
    return null;
  }
};

export const formatMoney = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const convertHtmlToText = (passHtmlBlock) => {
  const text = passHtmlBlock.toString();
  return text.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
};

export const checkIsImage = (mediaFile) => {
  let x = mediaFile != undefined && mediaFile?.split(".").pop().split("?")[0];
  // let format = x[x.length - 1];
  if (["jpg", "jpeg", "png", "gif"].includes(x)) {
    return true;
  }
  return false;
};

export const getFormattedLocation = (city, state, country) => {
  if (city || state || country) {
    return (
      (city ? city : "") +
      (city && state ? ", " : "") +
      (state ? state : "") +
      (city || state ? ", " : "") +
      (country ? country : "")
    );
  }
  return "-";
};
export const storeIntoLocal = (data) => {
  localStorage.setItem("validDomainNames", data.validDomainNames?.includes(",") ? data.validDomainNames?.split(",")[0] : data.validDomainNames);
  localStorage.setItem("encoded_domain", btoa(JSON.stringify({ "validDomainNames": data.validDomainNames?.includes(",") ? data.validDomainNames?.split(",")[0] : data.validDomainNames })));
  localStorage.setItem("childEnterpriseId", data.id);
  localStorage.setItem("enterprise_name", data.establishmentName);
  localStorage.setItem("profile_image", data.logoFileSignedUrl ? data.logoFileSignedUrl : Constant.DEFAULT_LOGO);
  localStorage.setItem("MSPName", data.establishmentName);
  localStorage.setItem("logo", data.navBarLogoSignedUrl);
  // if (localStorage.getItem("primaryColor") == null) {
  //   localStorage.setItem("primaryColor", process.env.REACT_APP_ORIGIN === "neurodiversify" ? "#055094" : process.env.REACT_APP_ORIGIN === "healthcare" ? "#45AC8B" : "#228B22");
  // } else {
  //   localStorage.setItem("primaryColor", data.primaryColor == null ? (process.env.REACT_APP_ORIGIN === "neurodiversify" ? "#055094" : process.env.REACT_APP_ORIGIN === "healthcare" ? "#45AC8B" : "#228B22") : "#" + data.primaryColor);
  // }
  setPlatformTheme(data.theme, process.env.REACT_APP_ORIGIN);
  if (data?.exclusiveCandidateTheme) {
    setExclusiveTheme(data?.exclusiveCandidateTheme, process.env.REACT_APP_ORIGIN);
  }
  // document.documentElement.style.setProperty("--primary-color", (data.primaryColor == null || data.primaryColor == undefined) ? (process.env.REACT_APP_ORIGIN === "neurodiversify" ? "#055094" : process.env.REACT_APP_ORIGIN === "healthcare" ? "#45AC8B" : "#228B22") : "#" + data.primaryColor, 'important');
  // document.documentElement.style.setProperty("--secondary-color", (data.secondaryColor == null || data.secondaryColor == undefined) ? (process.env.REACT_APP_ORIGIN === "neurodiversify" ? "#EAF4FF" : process.env.REACT_APP_ORIGIN === "healthcare" ? "#F0FFFA" : "#EBFFEB") : "#" + data.secondaryColor, 'important');
};
export const saveUserData = (data) => {
  // localStorage.setItem("logo", data.navBarLogoSignedUrl);
  localStorage.setItem("user_name", data.firstName + " " + (data.lastName == null ? "" : data.lastName));
  localStorage.setItem("email", data.emailAddress);
  localStorage.setItem("userType", data.userType);
};
export const updateData = (data) => {
  // document.documentElement.style.setProperty("--primary-color", (data.primaryColor == null || data.primaryColor == undefined) ? (process.env.REACT_APP_ORIGIN === "neurodiversify" ? "#055094" : process.env.REACT_APP_ORIGIN === "healthcare" ? "#45AC8B" : "#228B22") : "#"+data.primaryColor, 'important');
  // document.documentElement.style.setProperty("--secondary-color", (data.secondaryColor == null || data.secondaryColor == undefined) ? (process.env.REACT_APP_ORIGIN === "neurodiversify" ? "#EAF4FF" : process.env.REACT_APP_ORIGIN === "healthcare" ? "#F0FFFA" : "#EBFFEB") : "#"+data.secondaryColor, 'important');
  setPlatformTheme(data.theme, process.env.REACT_APP_ORIGIN);
  if (data?.exclusiveCandidateTheme) {
    setExclusiveTheme(data?.exclusiveCandidateTheme, process.env.REACT_APP_ORIGIN);
  }
  localStorage.setItem("logo", data.navBarLogoSignedUrl);
  localStorage.setItem("profile_image", data.logoFileSignedUrl ? data.logoFileSignedUrl : Constant.DEFAULT_LOGO);
  localStorage.setItem("enterprise_name", data.establishmentName);
  localStorage.setItem("profileTitle", data.profileTitle);
};

export const getUserType = (userType) => {
  switch (userType) {
    case "Z":
      return "Super Administrator";
    case "A":
      return "Administrator";
    case "S":
      return "Associate";
    case "R":
      return "Recruiter";
    case "H":
      return "HR Manager";
    case "T":
      return "Talent Acquisition Manager";
    case "E":
      return "Executive Leader";
    default:
      return "-";
  }
};
export const setPlatformTheme = (theme_obj, appOrigin) => {
  let primaryColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#0071E3");
  let secondaryColor = (appOrigin === "N" ? "#EAF4FF" : appOrigin === "H" ? "#F0FFFA" : "#E5f0FC");
  let hoverButtonColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#0071E3");
  let hoverButtonTextColor = "#FFFFFF";
  let headingTextColor = "#101828";
  let mainTextColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#0071E3");
  let listHoverColor = "#F9FAFB";
  try {
    let themes = theme_obj ? JSON.parse(theme_obj) : [];
    let selectedTheme = themes?.filter((theme)=>(theme.isSelected));
    if (selectedTheme.length) {
      selectedTheme = selectedTheme[0];
      primaryColor = "#" + selectedTheme.primaryColor;
      secondaryColor = "#" + selectedTheme.secondaryColor;
      hoverButtonColor = "#" + selectedTheme.hoverButtonColor;
      hoverButtonTextColor = "#" + selectedTheme.hoverButtonTextColor;
      headingTextColor = "#" + selectedTheme.headingTextColor;
      mainTextColor = "#" + selectedTheme.mainTextColor;
      listHoverColor = "#" + selectedTheme.listHoverColor;
      localStorage.setItem("theme_obj", JSON.stringify({ "p_color": primaryColor, "s_color": secondaryColor }));
      localStorage.setItem("secondaryColor", secondaryColor);
      localStorage.setItem("primaryColor", primaryColor);
    }
  } catch (err) {}
  if (theme_obj == null) {
    localStorage.setItem("secondaryColor", secondaryColor);
    localStorage.setItem("primaryColor", primaryColor);
  }
  document.documentElement.style.setProperty(
    "--primary-color",
    primaryColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    secondaryColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--hover-button-color",
    hoverButtonColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--hover-button-text-color",
    hoverButtonTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--heading-text-color",
    headingTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--main-text-color",
    mainTextColor,
    "important"
  );
  document.documentElement.style.setProperty(
    "--list-hover-color",
    listHoverColor,
    "important"
  );
};
export const setExclusiveTheme = (theme_obj, appOrigin) => {
  try {
    let themes = theme_obj ? JSON.parse(theme_obj) : [];
    let selectedTheme = themes?.filter((theme)=>(theme.isSelected));
    if (selectedTheme.length) {
      selectedTheme = selectedTheme[0];
      let primaryColor = "#" + selectedTheme.primaryColor;
      let secondaryColor = "#" + selectedTheme.secondaryColor;
      localStorage.setItem("exclusivePrimaryColor", primaryColor);
      localStorage.setItem("exclusiveSecondaryColor", secondaryColor);
    }
  } catch (err) {}
};
export const extractValueFromCookie = (cookieName, cookieString) => {
  const matchedCookie = cookieString.split(';').find((cookie) => cookie.includes(cookieName));
  if (matchedCookie) {
      return matchedCookie.trim().split('=')[1];
  }
  return null;
};
export const saveCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
