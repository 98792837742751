import React from 'react';
import { Box, Button, Card, Grid, InputAdornment, MenuItem, Popover, ClickAwayListener, Popper, Paper, Grow, TextField, Typography, Tooltip } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EmptyScreen from "../../assets/images/undraw_blank_canva1.svg";
import CloseIcon from "@mui/icons-material/Close";
import { functiondisable, functionenable } from "../helpers/helper";
import { Link, useNavigate } from "react-router-dom";
import PaginationItem from "@mui/material/PaginationItem";
import { ReactComponent as ArrowBackIcon } from "../../assets/images/arrow-left.svg";
import { ReactComponent as ArrowForwardIcon } from "../../assets/images/arrow-right.svg";
import { ReactComponent as ActionIcon } from "../../assets/images/action-cta-icon.svg";
import MenuList from "@mui/material/MenuList";
import { ReactComponent as Eye } from "../../assets/images/eye.svg";
import { ReactComponent as Edit } from "../../assets/images/edit-new-icon.svg";

const Templates = (props) => {
    const {
        templates,
        totalCount,
        totalPage,
        pageNumber,
        handlePageChange,
        viewTemplate,
        fetchHtml,
        handleEnterKey,
        clearSearch,
        _keyword,
        setOpenGetStarted,
        ClickKeyword,
        inputRef,
        noSearchResultsFound,
        baseUrl
    } = props;
    const [templateTags, setTemplateTags] = React.useState([]);
    const [anchorE, setAnchorE] = React.useState(null);
    const open = Boolean(anchorE);
    const navigate = useNavigate();
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [placement, setPlacement] = React.useState();
    const [optionsData, setOptionsData] = React.useState();
    const [open1, setOpen1] = React.useState(false);
    const open2 = Boolean(anchorEl2);
    const id = open2 ? 'simple-popper' : undefined;

    const handleViewAllTags = (event, tags) => {
        setTemplateTags(tags);
        setAnchorE(event.currentTarget);
        functiondisable();
    };
    const handleClosePopover = () => {
        setTemplateTags([]);
        setAnchorE(null);
        functionenable();
    };
    const handleClick = (event, data, newPlacement) => {
        setAnchorEl2(anchorEl2 ? null : event.currentTarget);
        setOptionsData(data);
      setPlacement(newPlacement);
      };
      React.useEffect(() => {
        document.addEventListener("scroll", () => {
            setAnchorEl2(null);
        });
    });
    function handleListKeyDown(event) {
        if (event.key === "Tab") {
          event.preventDefault();
          setOpen1(false);
        } else if (event.key === "Escape") {
          setOpen1(false);
        }
    }
    return (
        <Grid container lg={12} md={12} xs={12} className="">
            <Grid item lg={12} md={12} xs={12} className="d-flex px-10 responsive-block">
                <Grid item lg={8} md={8} xs={12}>
                    <Typography variant="h5" className="font-weight-600 heading-text-color">
                        Pick the Career Site template you like
                    </Typography>
                    <Typography className="f-20 mt-10" style={{ color: "#00000080" }}>
                        All Career Site Templates
                    </Typography>
                </Grid>
                <Grid item lg={4} md={4} xs={12} className="justify-end d-flex">
                    <Box className="inputGroup d-flex align-center matching-details-align">
                    <Tooltip role="tooltip" title="Search templates by name, overview and tags"
                             placement="top">
                        <TextField
                            inputRef={inputRef}
                            type="search"
                            name="searhKeyword"
                            className="inputcontrasts position-issue postJob-search search-id"
                            variant="outlined"
                            margin="normal"
                            placeholder="Search templates by name..."
                            sx={{ minWidth: "280px" }}
                            onKeyPress={handleEnterKey}
                            onInput={handleEnterKey}
                            onKeyDown={handleEnterKey}
                            value={_keyword}
                            InputProps={{
                                autoComplete: 'off',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {_keyword !== "" &&
                                        <ClearIcon
                                        onClick={() => {
                                            inputRef.current.value = "";
                                            clearSearch();
                                        }}
                                        style={{ marginRight: "20px" }}
                                        aria-label="Clear icon"
                                        className="cursor-hover"
                                        />
                                    }
                                        <Typography className="search-byId">
                                            <Button className="btn primary-button" onClick={ClickKeyword} aria-label="Search icon" disableRipple={true}>
                                                <SearchIcon className="c-white f-22" aria-label="Search icon" />
                                            </Button>
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Tooltip>
                    </Box>
                </Grid>
            </Grid>
            {templates?.length > 0 && <>
            <Grid container item lg={12} md={12} xs={12} className='d-flex pt-20 mb-30' sx={{ flexWrap: 'wrap' }}>
            {templates && templates.map( (template, index) => (
                <Grid item lg={4} md={4} xs={12} key={index} style={{ marginBottom: "25px" }}>
                    <Grid class="container-section p-10 pb-0">
                        <Card className="image">
                            <CardMedia
                                sx={{ minHeight: 200 }}
                                aria-label="image"
                                image={baseUrl + template.screenshot_url} />

                            {/* <CardActions sx={{ display: 'flex', justifyContent: 'center' }} className="overlay">
                                <Button className="btn view-button" onClick={() => viewTemplate(template)}>View</Button>
                                <Button className="btn use-button" onClick={() => fetchHtml(template)}>Use Template</Button>
                                <Link
                                    style={{textDecoration: "None", marginLeft: "8px"}}
                                    className="btn use-button"
                                    to={{
                                        pathname: '/candidate/template',
                                        search: `template_id=${template.template_id}`
                                    }}
                                    target="_blank"
                                >Use Template</Link>
                            </CardActions> */}
                        </Card>
                        <Grid className='font-weight-600 d-flex justify-between mt-10'>
                    <Typography className='font-weight-600 main-text-color' style={{ alignItems: "flex-start" }}>
                        <span>{template?.folder_name}</span>
                    </Typography>
                    <Button aria-describedby={id} type="button" aria-label="button" className='p-0 d-flex justify-end' disableRipple="true" onClick={(e) => handleClick(e, template, 'bottom-end')}>
                    <Typography className='secondary-bg-color border-radius-4 p-10 align-right font-weight-600 d-flex justify-end cursor-hover link-color publish-options' style={{ alignItems: "flex-start" }}>
                        <ActionIcon className="svg-icons"/>
                    </Typography>
                    </Button>
                    </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} align="left" sx={{ pr: 5 }} className="mt-5 ml-10">
                                    <Grid className="d-flex align-center">
                                        {template?.tags?.map((tag, index) => {
                                            return index < 3 ? (<span key={index} className="skills textEllipse" title={tag}>{tag}</span>) : null;
                                        })}
                                        <span>
                                            {template?.tags?.length > 3 &&
                                                <span onClick={(e) => handleViewAllTags(e, template?.tags)}
                                                className="link-color cursor-hover text-decoration-none d-block f-12 font-weight-600 p-l-5"
                                                style={{ width: "64px" }}>
                                                    {`+${template?.tags?.length - 3} more`}
                                                </span>
                                            }
                                        </span>
                                    </Grid>
                                </Grid>
                </Grid>
                ))}
                 <Popover
                            id="simple-popover-list"
                            open={open}
                            anchorEl={anchorE}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                            }}
                        >
                            <Grid item sx={{ p: 1 }}>
                                <Box className="align-between m-b-5">
                                    <Grid item xs={12}>
                                        <Typography
                                            id="modal-title"
                                            className="d-flex align-between p-10"
                                        >
                                            <Typography variant="h5" className="f-16 font-weight-600 main-text-color">
                                                Tags
                                            </Typography>
                                            <Typography
                                                align="right"
                                                className="cursor-hover sub-text-color icon-button"
                                            >
                                                <CloseIcon onClick={handleClosePopover} onKeyPress={handleClosePopover} aria-label="Close icon" tabIndex={0} />
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Box>
                                <Grid item
                                    className="p-10"
                                    style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
                                >
                                    <Box>
                                        {templateTags.map((tag, index) => {
                                            return (
                                                <span key={index} className="skills">
                                                    {tag}
                                                </span>
                                            );
                                            })}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Popover>
                        <Popper id={id} open={open2} anchorEl={anchorEl2} placement={placement} className='assess-update'>
                <Paper>
                  <ClickAwayListener onClickAway={() => setAnchorEl2(null)} tabIndex={0} onKeyPress={() => setAnchorEl2(null)}>
                    <MenuList
                      autoFocusItem={open1}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={() => {
                            viewTemplate(optionsData); setAnchorEl2(null);
                        }}
                        className="p-0"
                        tabIndex={0}
                        onKeyPress={() => {
                            viewTemplate(optionsData); setAnchorEl2(null);
                        }}
                      >
                        <Typography className="d-flex  f-12 font-weight-400 p-5 pl-10 align-center">
                          <Eye className="fill-option-icons" width="14px"/>
                          <span className="gray9" style={{ paddingLeft: "12px" }}>View</span>
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                            setAnchorEl2(null);
                        }}
                        className="p-0"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            setAnchorEl2(null);
                        }}
                      ><Link
                      style={{ textDecoration: "None" }}
                      className="gray9"
                      to={{
                          pathname: '/candidate/template',
                          search: `template_id=${optionsData?.template_id}`
                      }}
                      target="_blank"
                  >
                         <Typography className="d-flex  f-12 font-weight-400 p-5 pl-10 align-center">
                         <Edit className="fill-option-icons" width="12px"/>
                         <span className="gray9" style={{ paddingLeft: "14px" }}>Use Template</span>
                        </Typography></Link>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
            </Popper>
            </Grid>
            {templates != 0 &&
            <>
                {" "}
                <Grid
                    container
                    spacing={1}
                    className="align-between align-center pagenation-design"
                    style={{ marginLeft: "10px" }}
                >
                    <Grid item lg={6} md={6} xs={12} className="pagenation-table pl-0">

                        <Typography className="d-flex f-14 align-center">
                            <Box className='gray7'>Showing</Box>
                            <Box className="count-jobs mx-5">9</Box>
                            <Box className='gray7'>items per page</Box>
                        </Typography>
                    </Grid>
                    {/* <Grid className="justify-end d-flex pagenation-table navbarUser pt-0">
                        <Stack spacing={2} className="pagenation-joblist">
                            <Pagination count={totalPage} page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" showFirstButton showLastButton/>
                        </Stack>
                    </Grid> */}
                     <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        className="justify-end d-flex pagenation-table pt-8"
                      >
                        <Stack spacing={2} className="pagenation-joblist">
                          <Pagination
                            shape="rounded"
                            // color="primary"
                            // rowsperpage={rowsperpage}
                            count={totalPage}
                            page={pageNumber}
                            onChange={handlePageChange}
                            renderItem={(item) => (
                              <PaginationItem
                                components={{
                                  last: (props) => (
                                    <button
                                      className="btn primary-button profileButton"
                                      aria-label="button"
                                      {...props}
                                    >
                                      Last
                                    </button>
                                  ),

                                  next: (props) => (
                                    <Box
                                      className="d-flex align-center cursor-hover"
                                      {...props}
                                    >
                                      <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                        Next
                                      </Box>
                                      <ArrowForwardIcon
                                        height="13px"
                                        width="13px"
                                      />{" "}
                                    </Box>
                                  ),

                                  first: (props) => (
                                    <Box
                                      className="d-flex align-center cursor-hover"
                                      {...props}
                                    >
                                      <ArrowBackIcon
                                        height="13px"
                                        width="13px"
                                      />{" "}
                                      <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                        Previous
                                      </Box>
                                    </Box>
                                  ),

                                  previous: (props) => (
                                    <Box
                                      className="d-flex align-center cursor-hover"
                                      {...props}
                                    >
                                      <ArrowBackIcon
                                        height="13px"
                                        width="13px"
                                      />{" "}
                                      <Box className="gray7 f-14 font-weight-500 ml-10 mr-20">
                                        Previous
                                      </Box>
                                    </Box>
                                  ),
                                }}
                                {...item}
                              />
                            )}
                            showFirstButton={false}
                            showLastButton={false}
                          />
                        </Stack>
                      </Grid>
                </Grid>
            </>}
            </>}
            {(templates.length == 0 || noSearchResultsFound) && (
                   <Grid container spacing={1} item lg={12} md={12} xs={12}>
                   <Grid
                       item lg={4} md={4} xs={12}
                       className="m-auto text-center">
                       <Typography className='mt-10'>
                           <img src={EmptyScreen} alt="something went wrong"></img>
                           <Typography className="f-16 font-weight-700 mt-20">{noSearchResultsFound ? "No templates found" : "Create your first career site"}</Typography>
                           {!noSearchResultsFound && <Button class="btn primary-button pt-10 pb-10 mb-10 mt-20" aria-label="button" onClick={(e) => setOpenGetStarted(true)}
                               tabindex={0} type="button" disableRipple="true">Get Started</Button>}
                       </Typography>
                   </Grid>
               </Grid>
            )}
        </Grid>
    );
};

export default Templates;

