import axios from "axios";
import { logout } from "../Views/helpers/helper";
import KeycloakService from "../keycloak";
import { danger } from "../Views/helpers/message-box";
import store from "../redux/store";
import { getPageNotFoundRecode } from "../redux/pageNotFoundReducer";

let baseURL = process.env.REACT_APP_EDITOR_URL_GRAPEJS;

const exclude404ForApis = ["/default-templates", "/templates"];

const api = axios.create({
  baseURL,
  // Increase timeout to 10 minutes
  timeout: 600000,
});
// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    config.headers["Authorization"] = `${KeycloakService.getToken()}`;
    config.headers["enterprise-id"] = localStorage.getItem("client_name");

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
            signOut();
        } else if (status === 404 &&
          !exclude404ForApis.some((api) => err?.response?.config?.url.includes(api))
        ) {
          store.dispatch(getPageNotFoundRecode(true));
        } else if (status === 500) {
          danger("Oops! Something went wrong. Please try again later.");
        }
        return Promise.reject(error);
    }
);

const signOut = async () => {
    // await logout();
    // window.location.href = "/";
    try {
        if (KeycloakService.getToken()) {
            await KeycloakService.doLogout({
                redirectUri: `${window.location.origin}/`,
            });
        }
        localStorage.clear();
    } catch {
        return;
    }
};

export default class EditorApiCalls {
  static post(url, payload) {
    return api.post(url, payload).then((response) => {
      return response;
    });
  }

  static patch(url, payload) {
    return api.patch(url, payload).then((response) => {
      return response;
    });
  }

  static put(url, payload) {
    return api.put(url, payload).then((response) => {
      return response;
    });
  }

  static get(url) {
    return api.get(url).then((response) => {
      return response;
    });
  }

  static delete(url) {
    return api.delete(url).then((response) => {
      return response;
    });
  }
}
