import KeycloakService from "../../keycloak";

const Auth = {};

export const signIn = (username, password) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, password)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const signUp = (
  enterpriseName,
  username,
  password,
  name,
  isUserInvited,
  inviteUserLocation,
  profileTitle
) => {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username,
      password,
      attributes: {
        name,
        "custom:isEnterprise": "Y",
        "custom:enterpriseName": enterpriseName,
        "custom:profile_title": profileTitle,
        "custom:isUserInvited": isUserInvited,
        "custom:location": inviteUserLocation
      },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const confirmSignup = (username, otp) => {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(username, otp)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

// Send confirmation code to user's email
export const forgotPassword = (username) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(username)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

// Collect confirmation code and new password, then
export const forgotPasswordSubmit = (username, code, password) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const resendSignup = (username) => {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(username)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const isSessionValid = async () => {
  return KeycloakService.isLoggedIn();
};

export const logout = async () => {
  try {
    if (KeycloakService.getToken()) {
      localStorage.clear();
      await KeycloakService.doLogout({
        redirectUri: `${window.location.origin}/`,
      });
    } else {
      localStorage.clear();
    }
  } catch {
    return;
  }
};

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const getEnterpriseLogo = () => {
  const eLogo = getFromLocalStorage("enterpriseLogo");
  const enterpriseLogo = eLogo
    ? eLogo !== "undefined" && eLogo !== undefined
      ? eLogo
      : "https://daas-prog-test.s3.amazonaws.com/default-logo.png"
    : "https://daas-prog-test.s3.amazonaws.com/default-logo.png";
  return enterpriseLogo;
};

export const functiondisable = () => {
  // To get the scroll position of current webpage
  let TopScroll = window.pageYOffset || document.documentElement.scrollTop;
  let LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;
  // if scroll happens, set it to the previous value
  window.onscroll = function () {
    window.scrollTo(LeftScroll, TopScroll);
  };
};

export const functionenable = () => {
  window.onscroll = function () {};
};
