import { useEffect, useState, useRef } from "react";
import grapesjs from 'grapesjs';
import searchIcon from "../../assets/images/search.svg";

export default grapesjs.plugins.add('search-jobs', (editor, options) => {
    editor.BlockManager.add('search', {
        label: `<div>Search Bar</div>`,
        attributes: {
          class: 'fa fa-search search-icon'
        },
        content: `<script class="search-script" type="text/javascript" myscript>
        // let dataUrl = "${process.env.REACT_APP_API_URL.replace('api/v1', 'public/api/v1')}candidates/jobs/0/6?validDomainNames=${localStorage.getItem('validDomainNames')}&keyword="
        var searchBar = document.getElementsByClassName("search")[0];
        var list = document.querySelector(".user-list");
        
        // if(window.location.host.includes("localhost")){
        //     document.getElementsByClassName("user-list")[0].style.display = "none"
        // } else {
        //     document.getElementsByClassName("user-list")[0].style.display = "block"
        // }
        async function getData(search) {
          try {
            let response = await fetch("${process.env.REACT_APP_API_URL.replace('api/v1', 'public/api/v1')}candidates/jobs/0/6?validDomainNames=${localStorage.getItem('validDomainNames')}&domain=${localStorage.getItem("appOrigin") === "N" ? "2" : localStorage.getItem("appOrigin") === "H" ? "1" : "0"}&keyword=" + search);
            let data = await response.json();
            return data?.responsePayload?.map(item => item)
          } catch(error) {
            throw Error(error);
          }
        }
        //close on outer click
        window.onclick = function(event) {
            if (!event.target.matches('.user-list')) {
                document.getElementsByClassName("user-list")[0].style.display = "none"
            }
            if(!event.target.matches('.list-item') && !event.target.matches('.spanc')){
              searchBar.value = ""
            }
            
        }
        
        // Keyup eventlistener for search inpout
        searchBar.addEventListener('keyup', (event) => {
          let searchTerm = event.target.value;
          if((event.target.value != "" && document.getElementsByClassName("preview")?.length != 0) || document.getElementById("live-jobs-check") != null){
            getData(searchTerm).then(loadElements);
            document.getElementsByClassName("user-list")[0].style.display = "block"
          } else {
            document.getElementsByClassName("user-list")[0].style.display = "none"
          }
          if(event.target.value == ""){
            document.getElementsByClassName("user-list")[0].style.display = "none"
          }
        });
              function loadElements(users) {
             if(users?.length > 0){
                let str = '<div id="search-list" style="display: none; height: 100%;z-index: 999999;background: #fff;box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 1px 3px rgb(0 0 0 / 10%);border-radius: 4px;border-bottom: 1px solid #e4e4e7;padding: 10px 20px 0px;position:relative; right: 0px;">';
                // Load elements on DOM
                  let listElements = users.slice(0, 5).map((user) => {
                  str += '<div id="list-item-div"><div id='+user.id+' class="list-item" style="display: flex; justify-content: flex-start; align-items: center; padding: 15px 0px; border-bottom: 1px solid #e4e4e7; cursor: pointer;"><svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">'
                  str += '<path d="M21.3333 18.6667H2.66659V14.2133C2.17008 13.9755 1.71887 13.6529 1.33325 13.26V18.6667C1.33325 19.0203 1.47373 19.3594 1.72378 19.6095C1.97382 19.8595 2.31296 20 2.66659 20H21.3333C21.6869 20 22.026 19.8595 22.2761 19.6095C22.5261 19.3594 22.6666 19.0203 22.6666 18.6667V13.26C22.2838 13.6545 21.8319 13.9753 21.3333 14.2067V18.6667Z" fill="#696871"/>'
                  str += '<path d="M16.6666 14.9334C16.8434 14.9334 17.013 14.8631 17.138 14.7381C17.263 14.6131 17.3333 14.4435 17.3333 14.2667V10.6267H15.9999V12H9.33325V13.3334H15.9999V14.2667C15.9999 14.4435 16.0702 14.6131 16.1952 14.7381C16.3202 14.8631 16.4898 14.9334 16.6666 14.9334Z" fill="#696871"/>'
                  str += '<path d="M21.9999 4.00005H15.9999V2.92005C15.9929 2.49332 15.8171 2.08675 15.511 1.78937C15.2048 1.492 14.7933 1.32803 14.3666 1.33338H9.63325C9.2065 1.32803 8.79501 1.492 8.48889 1.78937C8.18276 2.08675 8.00694 2.49332 7.99992 2.92005V4.00005H1.99992C1.82311 4.00005 1.65354 4.07028 1.52851 4.19531C1.40349 4.32033 1.33325 4.4899 1.33325 4.66671V10C1.33325 10.8841 1.68444 11.7319 2.30956 12.3571C2.93468 12.9822 3.78253 13.3334 4.66658 13.3334H6.66658V14.2667C6.66658 14.4435 6.73682 14.6131 6.86185 14.7381C6.98687 14.8631 7.15644 14.9334 7.33325 14.9334C7.51006 14.9334 7.67963 14.8631 7.80466 14.7381C7.92968 14.6131 7.99992 14.4435 7.99992 14.2667V10.6267H6.66658V12H4.66658C4.13615 12 3.62744 11.7893 3.25237 11.4143C2.8773 11.0392 2.66659 10.5305 2.66659 10V5.33338H21.3333V10C21.3333 10.5305 21.1225 11.0392 20.7475 11.4143C20.3724 11.7893 19.8637 12 19.3333 12H18.6666V13.3334H19.3333C20.2173 13.3334 21.0652 12.9822 21.6903 12.3571C22.3154 11.7319 22.6666 10.8841 22.6666 10V4.66671C22.6666 4.4899 22.5963 4.32033 22.4713 4.19531C22.3463 4.07028 22.1767 4.00005 21.9999 4.00005ZM14.6666 4.00005H9.33325V2.95338C9.33669 2.87615 9.3698 2.80322 9.4257 2.74981C9.48159 2.69641 9.55594 2.66664 9.63325 2.66671H14.3733C14.4109 2.66583 14.4484 2.67237 14.4835 2.68596C14.5186 2.69955 14.5507 2.71994 14.5779 2.74594C14.6052 2.77194 14.627 2.80305 14.6422 2.8375C14.6574 2.87195 14.6657 2.90906 14.6666 2.94671V4.00005Z" fill="#696871"/>'
                  str += '</svg><div style="font-weight: 400;font-size: 16px; margin-right: 18px;margin-left: 11px; color: black;"><span id='+user.id+' class="spanc" style="font-family: Roboto, sans-serif;" className="sub-text-font">'+user.jobTitle+'</span></div></div></div>'
                  list.innerHTML = str;
                  })
                  str += users.length >= 5 ? '<div style="padding: 15px 0px"><span id="seeall" style="display: flex; justify-content: center; color: ${getComputedStyle(document.documentElement).getPropertyValue('--primary-color')}; cursor: pointer; font-size: 16px;font-family: ${getComputedStyle(document.documentElement).getPropertyValue('--font-family')};">See All</span></div>':''
                  list.innerHTML = str;
                  if(users.length > 0) {
                       document.querySelector("#search-list").style.display = "block";
                  } else {
                      document.querySelector("#search-list").style.display = "none";
                  }
                } else {
                        let str1 = '<div style="display: block; height: 100%; z-index: 99; box-shadow: rgb(0 0 0 / 6%) 0px 1px 2px, rgb(0 0 0 / 10%) 0px 1px 3px; border-radius: 4px;border-bottom: 1px solid rgb(228, 228, 231);padding: 1px 20px 0px;position: relative;right: 21px;background: rgb(255, 255, 255);"><div style="margin: 15px 0px;">No results found</div></div>'
                        list.innerHTML = str1;
                }
                };

       document.addEventListener('click', function (e) {
        if (e.target.id.length > 10 || e.target.id == "seeall"){
            let preivew_api_url = "${process.env.REACT_APP_CANDIDATE_PORTAL_URL}"
            let publish_api_url = document.querySelector('meta[name="publish"]')
            let url = preivew_api_url ? preivew_api_url : publish_api_url.content
            if(e.target.id == "seeall") {
              window.open(url + "/job-list?paramsKey=${localStorage.getItem('encoded_domain')}&keyword="+searchBar.value)
            } else{
              if(searchBar?.value?.length > 0 && e.target.id != "see-all-jobs"){
                window.open(url +'/job/'+ e.target.id + '?paramsKey=${localStorage.getItem('encoded_domain')}')
            }
            }
        }
    });
        
    </script><div class="has-search">
    <input type="text" class="search" placeholder="Search by job title"
      style="border: none; box-shadow: none;width: 100%;padding: 12px 34px;margin: 8px 0;display: inline-block;border: 1px solid #ccc;border-radius: 4px;box-sizing: border-box;line-height: 1.80857;"/>
      <div style="margin-top: -42px;margin-left: 10px;width: 20px;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4733 13.5276L11.9999 11.0742C12.96 9.87719 13.4249 8.35778 13.2991 6.82845C13.1733 5.29911 12.4664 3.8761 11.3236 2.85201C10.1808 1.82792 8.68914 1.28059 7.15522 1.32256C5.62129 1.36453 4.16175 1.99262 3.0767 3.07767C1.99164 4.16273 1.36356 5.62227 1.32158 7.15619C1.27961 8.69012 1.82694 10.1818 2.85103 11.3246C3.87512 12.4674 5.29814 13.1743 6.82747 13.3001C8.3568 13.4259 9.87621 12.961 11.0733 12.0009L13.5266 14.4542C13.5886 14.5167 13.6623 14.5663 13.7436 14.6002C13.8248 14.634 13.9119 14.6514 13.9999 14.6514C14.0879 14.6514 14.1751 14.634 14.2563 14.6002C14.3376 14.5663 14.4113 14.5167 14.4733 14.4542C14.5934 14.3299 14.6606 14.1638 14.6606 13.9909C14.6606 13.818 14.5934 13.6519 14.4733 13.5276ZM7.33327 12.0009C6.41029 12.0009 5.50804 11.7272 4.74061 11.2144C3.97318 10.7017 3.37504 9.97282 3.02183 9.1201C2.66862 8.26738 2.57621 7.32907 2.75627 6.42382C2.93634 5.51858 3.38079 4.68706 4.03344 4.03441C4.68608 3.38177 5.5176 2.93731 6.42285 2.75725C7.32809 2.57718 8.2664 2.6696 9.11913 3.02281C9.97185 3.37602 10.7007 3.97416 11.2135 4.74158C11.7262 5.50901 11.9999 6.41127 11.9999 7.33425C11.9999 8.57192 11.5083 9.75891 10.6331 10.6341C9.75793 11.5092 8.57095 12.0009 7.33327 12.0009Z" fill="rgba(0, 0, 0, 0.54)"/>
      </svg></div>
      <ul class="user-list" style="display: none; position: absolute; width: calc(100% - 60%);margin-top: 15px;padding-left: 0px;"></ul>
      </div>
  <div>
  <div class="has-search1"></div>`,
    });
});
