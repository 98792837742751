import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { Grid, Typography, Card, Button, Box, MenuItem, Popper, Paper, Grow, Chip, InputBase, TextField, Popover, ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ReactComponent as UploadSvg } from "../../assets/images/upload.svg";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import uploadJobIcon from "../../assets/images/upload-job-icon.svg";
import { ReactComponent as Eye } from "../../assets/images/eye.svg";
import { ReactComponent as Edit } from "../../assets/images/edit-new-icon.svg";
import Modal from "@mui/material/Modal";
import { useDropzone } from 'react-dropzone';
import { success, danger } from "../helpers/message-box";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from '../shared/ConfirmDialog';
import ConfirmTemplateDeleteDialog from '../shared/ConfirmDialog';
import ConfirmTemplateResetDialog from '../shared/ConfirmDialog';
import EmptyScreen from "../../assets/images/undraw_blank_canva1.svg";
import { ReactComponent as Delete } from "../../assets/images/delete.svg";
import Loader from "../Loader";
import Input from "@mui/material/Input";
import { ReactComponent as Reset } from "../../assets/images/reset.svg";
import { handleSpaces } from "../helpers/helper-data";
import { functiondisable, functionenable } from "../helpers/helper";
import ClearIcon from "@mui/icons-material/Clear";
import EditorApiCalls from "../../api/customAxiosEditor";
import ApiCalls from "../../api/customAxios";
import InfoIcon from "@mui/icons-material/Info";
import PaginationItem from "@mui/material/PaginationItem";
import { ReactComponent as ArrowBackIcon } from "../../assets/images/arrow-left.svg";
import { ReactComponent as ArrowForwardIcon } from "../../assets/images/arrow-right.svg";
import { ReactComponent as ActionIcon } from "../../assets/images/action-cta-icon.svg";
import MenuList from "@mui/material/MenuList";
import KeycloakService from '../../keycloak';

export default function MyCareerSite(props) {
    const navigate = useNavigate();

    let url = process.env.REACT_APP_EDITOR_URL_GRAPEJS;
    let baseUrl = process.env.REACT_APP_EDITOR_BASE_URL_GRAPEJS;

    const [openUploadTemplates, setOpenUploadTemplates] = useState(props?.openUploadScreen == true ? true : false);
    const [openGetStarted, setOpenGetStarted] = useState(false);
    const [filesArrays, setfilesArrays] = useState([]);
    const [deleteConfi, setDeleteConfi] = useState(false);
    const [index, setIndex] = useState();

    const [publishedTemplate, setPublishedTemplate] = useState(null);
    const [savedTemplates, setSavedTemplates] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [_error, setError] = useState();

    const [deleteTemplateDiolog, setDeleteTemplateDiolog] = useState(false);
    const [resetTemplateDiolog, setResetTemplateDiolog] = useState(false);
    const [deleteTemplateId, setDeleteTemplateId] = useState('');

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [templateCategory, setTemplateCategory] = useState('');
    const [templateOverview, setTemplateOverview] = useState('');
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [nameError, setNameError] = useState('');
    const [_templateNameError, setTemplateNameError] = useState();
    const [_tagError, setTagError] = useState();
    const [enterpriseTemplateId, setEnterpriceTemplateId] = useState('');
    const [tag, setTag] = useState("");
    const [chipData, setChipData] = useState([]);
    const [templateTags, setTemplateTags] = React.useState([]);
    const [anchorE, setAnchorE] = React.useState(null);
    const [domain, setDomain] = React.useState();
    const [open1, setOpen1] = React.useState(false);
    const [optionsData, setOptionsData] = React.useState();
    const open = Boolean(anchorE);
    const anchorRef = React.useRef(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [placement, setPlacement] = React.useState();

    const handleClick = (event, data, newPlacement) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setOptionsData(data);
    setPlacement(newPlacement);
    };

    const handleClick1 = (event, newPlacement) => {
        setAnchorEl2(anchorEl2 ? null : event.currentTarget);
      setPlacement(newPlacement);
      };
    const open2 = Boolean(anchorEl);
    const open3 = Boolean(anchorEl2);
    const id = open2 ? 'simple-popper' : undefined;
    const id1 = open3 ? 'simple-popper1' : undefined;

    useEffect(() => {
        document.addEventListener("scroll", () => {
            setAnchorEl(null);
            setAnchorEl2(null);
        });
    });
    const handleClose = (event) => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (props?.myCareerSite_data) {
            setSavedTemplates(props?.myCareerSite_data.data);
            setPublishedTemplate(props.myCareerSite_data.published_data);
            setPageNumber(props.myCareerSite_data.page_number);
            setPageCount(props.myCareerSite_data.page_count);
            setTotalCount(props.myCareerSite_data.total_count);
            setTotalPage(props.myCareerSite_data.total_pages);
        }
    }, [props?.myCareerSite_data]);
    useEffect(() => {
        // getEnterpriceTemplates();
        getWebsiteDetails();
    }, []);
    const getEnterpriceTemplates = () => {
        // setIsLoading(true);
        EditorApiCalls.get(`/templates?page=${pageNumber}`)
            .then((res) => {
                setSavedTemplates(res.data.data.data);
                setPublishedTemplate(res.data.data.published_data);

                setPageNumber(res.data.data.page_number);
                setPageCount(res.data.data.page_count);
                setTotalCount(res.data.data.total_count);
                setTotalPage(res.data.data.total_pages);
                // setIsLoading(false);
            }).catch(function (error) {
                if (error?.statusCode != 500) {
                    danger(error);
                }
            });
    };

    const handlePageChange = (event, value) => {
        setIsLoading(true);
        EditorApiCalls.get(`/templates?page=${value}`)
            .then((res) => {
                setSavedTemplates(res.data.data.data);
                setPublishedTemplate(res.data.data.published_data);

                setPageNumber(res.data.data.page_number);
                setPageCount(res.data.data.page_count);
                setTotalCount(res.data.data.total_count);
                setTotalPage(res.data.data.total_pages);
                window.scrollTo({ top: 0, behavior: "smooth" });
                setIsLoading(false);
            }).catch(function (error) {
                if (error?.statusCode != 500) {
                    danger(error);
                }
                setIsLoading(false);
            });
    };

    const getWebsiteDetails = () => {
        let header = {
            authorization: "Bearer " + KeycloakService.getToken(),
            'x-access-token': KeycloakService.getIdToken(),
            "enterpriseId": localStorage.getItem("enterprise_id") ? localStorage.getItem("enterprise_id") : null,
        };
        let chid_header = {
            authorization: "Bearer " + KeycloakService.getToken(),
            'x-access-token': KeycloakService.getIdToken(),
            'x-enterprise-id': localStorage.getItem('childEnterpriseId'),
            "enterpriseId": localStorage.getItem("enterprise_id") ? localStorage.getItem("enterprise_id") : null,
          };
        axios.get(`${process.env.REACT_APP_API_URL}enterprises/career-portal`, {
            headers: localStorage.getItem('childEnterpriseId') ? chid_header : header
          })
            .then((res) => {
                setDomain(res.data.responsePayload.careerPortalDomain);
            }).catch(function (error) {
                if (error?.statusCode != 500) {
                    danger(error);
                }
            });
    };

    const viewTemplate = (template) => {
        window.open(`${baseUrl}${template.saved_path}`, "cache-control=no-cache");
        setAnchorEl(null);
    };

    const viewPublishedTemplate = (template) => {
        window.open(domain ? "https://" + domain : `${baseUrl}${template.saved_path}`, "cache-control=no-cache");
        setAnchorEl2(null);
    };

    const resetTemplate = (template) => {
        setIsLoading(true);
        EditorApiCalls.get(`/templates/reset/${deleteTemplateId}`)
            .then((res) => {
                setResetTemplateDiolog(false);
                success('Template reset successfully.');
                props.getEnterpriceTemplates();
                setIsLoading(false);
            }).catch(function (error) {
                setIsLoading(false);
                if (error?.statusCode != 500) {
                    danger(error);
                }
            });
        setAnchorEl(null);
    };

    function fetchHtml(template) {
        setIsLoading(true);
        EditorApiCalls.get(`/templates/${template.enterprise_template_id}`)
            .then((res) => {
                localStorage.setItem('enterprise_template_id', template?.enterprise_template_id);
                localStorage.setItem('template_id', template?.template);
                localStorage.removeItem('create_from_scratch');
                const { data } = res.data;
                navigate('../template', {
                    state: {
                        "html": data.html,
                        'template_name': template?.template_name,
                        "css": data.css,
                        "template_overview": template.overview,
                        "mode": "Templates",
                        "status": template.status
                    }
                });
                setIsLoading(false);
            }).catch(function (error) {
                setIsLoading(false);
                if (error?.statusCode != 500) {
                    danger(error);
                }
            });
            setAnchorEl(null);
            setAnchorEl2(null);
    }

    const handleCloseUploadTemplates = () => {
        setOpenUploadTemplates(false);
        setIsSubmitted(false);
        setfilesArrays([]);
        setTemplateCategory('');
        setTemplateOverview('');
        props.setOpenUploadScreen(false);
        setError("");
        setNameError("");
        setChipData([]);
        setTag("");
        setTagError("");
        setTemplateName("");
    };
    const handleOpenUploadTemplates = () => {
        setOpenUploadTemplates(true);
    };

    const handleDeleteDocumentWithIndex = (documentIndex) => {
        setfilesArrays(
            filesArrays.filter((file, _index) => _index !== documentIndex)
        );
        success("Template deleted successfully.");
    };
    const deleteTemplate = () => {
        handleDeleteDocumentWithIndex(index);
        setDeleteConfi(false);
    };

    const handleDeleteTemplate = (template) => {
        setDeleteTemplateDiolog(true);
        setDeleteTemplateId(template.enterprise_template_id);
        setAnchorEl(null);
    };

    const handleResetTemplate = (template) => {
        setResetTemplateDiolog(true);
        setDeleteTemplateId(template.enterprise_template_id);
        setAnchorEl(null);
    };

    const deleteSavedTemplate = () => {
        setIsLoading(true);
        EditorApiCalls.delete(`/templates/${deleteTemplateId}`)
            .then((res) => {
                success("Template deleted successfully.");
                props.getEnterpriceTemplates();
                setDeleteTemplateDiolog(false);
                setIsLoading(false);
            })
            .catch(function (error) {
                if (error?.statusCode != 500) {
                    danger(error);
                }
                setIsLoading(false);
            });
    };

    const uploadHandler = async (event) => {
        setIsSubmitted(true);
        setIsLoading(true);
        event.preventDefault();
        var formData = new FormData();
        formData.append("file", filesArrays[0].file);
        formData.append("folder_name", templateName?.split(" ")?.join(""));
        formData.append("category", "Others");
        formData.append("overview", templateOverview.trim());
        formData.append("tags", chipData);
        EditorApiCalls.post(`/templates/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(function (response) {
                success('Template uploaded successfuly.');
                props.getEnterpriceTemplates();
                handleCloseUploadTemplates();
                setIsLoading(false);
            })
            .catch(function (error) {
                if (error.response.status == 400) {
                   danger("Error on uploading file, please try with different template");
                }
                setIsSubmitted(false);
                setIsLoading(false);
            });
    };

    const onDrop = useCallback((acceptedFiles) => {
        var validFileExtensions = ["zip"];
        for (let index = 0; index < acceptedFiles.length; index++) {
            var file = acceptedFiles[index];
            if (file) {
                var extension = file.name.split(".").pop();
                if (validFileExtensions.includes(extension)) {
                    setfilesArrays((oldArray) => [
                        ...oldArray,
                        { filename: file.name, extension: extension, file: file },
                    ]);
                    setTimeout(() =>
                        document.getElementById("modal-description")?.scrollTo({
                            top: document.getElementById("modal-description").scrollHeight,
                            behavior: 'smooth',
                        }), 600
                    );
                } else {
                    danger(
                        "Please upload only ZIP files!"
                    );
                }
            }
        }
    }, []);

    const templateCategoryOptions = [
        { value: 'Construction', label: 'Construction' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'IT', label: 'IT' },
    ];

    // START Todo Shahid For Tags
    const handleUpdateTemplateModal = (template) => {
        setTag('');
        setOpenSaveModal(true);
        setTemplateName(template?.template_name);
        if (template.tags) {
            setChipData(template.tags);
        } else {
            setChipData(null);
        }
        setEnterpriceTemplateId(template.enterprise_template_id);
    };

    const handleInputChange = (event) => {
        setTag(event.target.value);
        if (event.target.value.length >= 256) {
            // setTagError("Exceeded maximum character length of 256.");
            setTagError("");
        } else if (event.target.value.length == 0) {
            setTagError("Tag is required.");
        } else {
            setTagError("");
        }
    };

    const handleInputKeyDown = (event) => {
        if (event.key === "Enter" && tag) {
            setChipData([...new Set([...chipData, tag])]);
            setTag("");
            setTimeout(() =>
                document.getElementById("modal-description")?.scrollTo({
                    top: document.getElementById("modal-description").scrollHeight,
                    behavior: 'smooth',
                }), 600
            );
        }
    };

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) =>
            chips.filter((chip) => chip !== chipToDelete)
        );
    };

    const handleUpdateTemplate = async () => {
        setIsLoading(true);

        var payload = {
            "name": templateName,
            "tags": chipData
        };

        await EditorApiCalls.put(`/templates/${enterpriseTemplateId}`, payload)
            .then(function (response) {
                let object = savedTemplates.filter((template) => template.enterprise_template_id == enterpriseTemplateId);
                object[0].template_name = templateName;
                object[0].tags = chipData;
                success('Template updated successfuly.');
                setIsLoading(false);
                setOpenSaveModal(false);
            })
            .catch(function (error) {
                if (error?.statusCode != 500) {
                    danger(error);
                }
                setIsLoading(false);
            });
    };
    // End Todo Shahid For Tags

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: filesArrays.length >= 1, maxFiles: 1 });

    const handleViewAllTags = (event, tags) => {
        setTemplateTags(tags);
        setAnchorE(event.currentTarget);
        functiondisable();
    };
    const handleClosePopover = () => {
        setTemplateTags([]);
        setAnchorE(null);
        functionenable();
    };
    function handleListKeyDown(event) {
        if (event.key === "Tab") {
          event.preventDefault();
          setOpen1(false);
        } else if (event.key === "Escape") {
          setOpen1(false);
        }
    }

    return (
        <Grid>
            {isLoading && <Loader />}
            <Grid>
                {publishedTemplate != null && <Grid container lg={12} md={12} xs={12} className="mb-20">
                    <Grid item lg={12} md={12} xs={12} className="px-10 d-flex justify-between align-center">
                        <Typography className="f-24 mt-0 font-weight-500 heading-text-color" style={{ letterSpacing: "-0.750316px" }}>
                            Published
                        </Typography>
                        <Button
                                aria-label="Menu icon"
                                className="pr-0 upload-status"
                                disableRipple="true"
                            >
                                <Typography className="btn upload-button justify-between post-button ml-10 bulk-upload-btn job-detail-matching" onClick={handleOpenUploadTemplates}>
                                    <UploadSvg style={{ height: "19px" }} className="mr-10 svg-icons" />
                                    <span className="small-text-font position-issue postJob-btn font-weight-700">Upload Template</span>
                                </Typography>
                            </Button>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} className='d-flex justify-between'>
                        <Grid item lg={12} md={6} xs={6} className='d-flex pt-15' sx={{ flexWrap: 'wrap' }}>

                            <Grid item lg={12} md={12} xs={12} style={{ marginBottom: "25px" }}>
                                <Grid class="container-section p-10 pb-0">
                                    <Card className="image">
                                        <CardMedia
                                            sx={{ minHeight: 200 }}
                                            aria-label="image"
                                            image={baseUrl + publishedTemplate.screenshot_url} />
                                        {/* <CardActions sx={{ display: 'flex', justifyContent: 'space-around' }} className="overlay">
                                            <Button className="btn view-button" onClick={() => viewPublishedTemplate(publishedTemplate)}><Eye className="mr-5 fill-svg-icons" />View</Button>
                                            <Button className="btn use-button" onClick={() => fetchHtml(publishedTemplate)}><Edit className="mr-5 mb-3"/>Edit</Button>
                                        </CardActions> */}
                                    </Card>
                                    <Grid className='font-weight-600 d-flex justify-between mt-10'>
                                <Typography className='font-weight-600 main-text-color' style={{ alignItems: "flex-start" }}>
                                    <span>{publishedTemplate?.template_name}</span>
                                </Typography>
                                <Button aria-describedby={id1} type="button" aria-label="button" disableRipple="true" className="d-flex justify-end p-0" onClick={(e) => handleClick1(e, 'bottom-end')}>
                                <Typography className='secondary-bg-color border-radius-4 p-10 align-right font-weight-600 d-flex justify-end cursor-hover link-color publish-options' style={{ alignItems: "flex-start" }}>
                                        <ActionIcon className="svg-icons"/>
                                    </Typography>
                                </Button>
                                </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} xs={12} align="left" className="mt-5 ml-10" style={{ width: "450px" }}>
                                    <Grid className="d-flex align-center">
                                        {publishedTemplate?.tags?.map((tag, index) => {
                                            return index < 3 ? (<span key={index} className="skills textEllipse" title={tag}>{tag}</span>) : null;
                                        })}
                                        <span>
                                            {publishedTemplate?.tags?.length > 3 &&
                                                <span onClick={(e) => handleViewAllTags(e, publishedTemplate?.tags)}
                                                className="link-color cursor-hover text-decoration-none d-block f-12 font-weight-600 p-l-5"
                                                style={{ width: "64px" }}>
                                                    {`+${publishedTemplate?.tags?.length - 3} more`}
                                                </span>
                                            }
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item lg={12} md={6} xs={6} className="d-flex justify-flex-end align-baseline">
                        </Grid>
                    </Grid>
                </Grid>}
                <Popover
                    id="simple-popover-list"
                    open={open}
                    anchorEl={anchorE}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right",
                    }}
                >
                    <Grid item sx={{ p: 1 }}>
                        <Box className="align-between m-b-5">
                            <Grid item xs={12}>
                                <Typography
                                    id="modal-title"
                                    className="d-flex align-between p-10"
                                >
                                    <Typography variant="h5" className="f-16 font-weight-600 main-text-color">
                                        Tags
                                    </Typography>
                                    <Typography
                                        style={{ marginLeft: "228px" }}
                                        align="right"
                                        className="cursor-hover sub-text-color icon-button"
                                    >
                                        <CloseIcon onClick={handleClosePopover} onKeyPress={handleClosePopover} aria-label="Close icon" tabIndex={0} />
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Box>
                        <Grid item
                            className="p-10"
                            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
                        >
                            <Box>
                                {templateTags.map((tag, index) => {
                                    return (
                                        <span key={index} className="skills">
                                            {tag}
                                        </span>
                                    );
                                })}
                            </Box>
                        </Grid>
                    </Grid>
                </Popover>
                <Popper id={id1} open={open3} anchorEl={anchorEl2} placement={placement} className='assess-update'>
                <Paper>
                  <ClickAwayListener onClickAway={() => setAnchorEl2(null)} tabIndex={0} onKeyPress={() => setAnchorEl2(null)}>
                    <MenuList
                    //   autoFocusItem={open1}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={() => viewPublishedTemplate(publishedTemplate)}
                        className="p-0"
                        tabIndex={0}
                        onKeyPress={() => viewPublishedTemplate(publishedTemplate)}
                      >
                        <Typography className="d-flex  f-12 font-weight-400 p-5 pl-10 align-center">
                          <Eye className="fill-option-icons" width="14px"/>
                          <span className="gray9" style={{ paddingLeft: "12px" }}>View</span>
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => fetchHtml(publishedTemplate)}
                        className="p-0"
                        tabIndex={0}
                        onKeyPress={(e) => fetchHtml(publishedTemplate)}
                      >
                         <Typography className="d-flex  f-12 font-weight-400 p-5 pl-10 align-center">
                         <Edit className="fill-option-icons" width="12px"/>
                          <span className="gray9" style={{ paddingLeft: "14px" }}>Edit</span>
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
            </Popper>
                <Popper id={id} open={open2} anchorEl={anchorEl} placement={placement} className='assess-update'>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose} tabIndex={0} onKeyPress={handleClose}>
                    <MenuList
                      autoFocusItem={open1}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={() => viewTemplate(optionsData)}
                        className="p-0"
                        tabIndex={0}
                        onKeyPress={() => viewTemplate(optionsData)}
                      >
                        <Typography className="d-flex  f-12 font-weight-400 p-5 pl-10 align-center">
                          <Eye className="fill-option-icons" width="14px"/>
                          <span className="gray9" style={{ paddingLeft: "12px" }}>View</span>
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => fetchHtml(optionsData)}
                        className="p-0"
                        tabIndex={0}
                        onKeyPress={(e) => fetchHtml(optionsData)}
                      >
                         <Typography className="d-flex  f-12 font-weight-400 p-5 pl-10 align-center">
                         <Edit className="fill-option-icons" width="12px"/>
                          <span className="gray9" style={{ paddingLeft: "14px" }}>Edit</span>
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                            handleDeleteTemplate(optionsData);
                        }}
                        className="p-0"
                        tabIndex="0"
                        onKeyPress={(e) => {
                            handleDeleteTemplate(optionsData);
                        }}
                      >
                         <Typography className="d-flex  f-12 font-weight-400 p-5 pl-10 align-center">
                         <Delete className="fill-option-icons" width="14px"/>
                          <span className="gray9" style={{ paddingLeft: "12px", paddingTop: "1px" }}>Delete</span>
                        </Typography>
                      </MenuItem>
                      {optionsData?.is_resettable &&
                      <MenuItem
                        onClick={() => {
                            handleResetTemplate(optionsData);
                        }}
                        className="p-0"
                        tabIndex="0"
                        onKeyPress={(e) => {
                            handleResetTemplate(optionsData);
                        }}
                      >
                         <Typography className="d-flex  f-12 font-weight-400 p-5 pl-10 align-center">
                         <Reset className="fill-option-icons" width="14px"/>
                          <span className="gray9" style={{ paddingLeft: "12px" }}>Reset Template</span>
                        </Typography>
                      </MenuItem>
                    }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
            </Popper>
                {savedTemplates?.length > 0 && <Grid>
                    <Grid item lg={12} md={12} xs={12} className="px-10 d-flex justify-between align-center">
                        <Typography className="f-24 t-0 font-weight-500 container-section heading-text-color" style={{ letterSpacing: "-0.750316px" }}>
                            Edited & Saved
                        </Typography>
                        {publishedTemplate != null ? "" : <Grid item lg={12} md={6} xs={6} className="d-flex justify-flex-end align-baseline">
                            <Button
                                aria-label="Menu icon"
                                className="pr-0 upload-status"
                                disableRipple="true"
                            >
                                <Typography className="btn upload-button justify-between post-button ml-10 bulk-upload-btn job-detail-matching" onClick={handleOpenUploadTemplates}>
                                    <UploadSvg style={{ height: "19px" }} className="mr-10 svg-icons" />
                                    <span className="small-text-font position-issue postJob-btn font-weight-700">Upload Template</span>
                                </Typography>
                            </Button>
                        </Grid>}
                    </Grid>
                    <Grid container item lg={12} md={12} xs={12} className='d-flex pt-20' sx={{ flexWrap: 'wrap' }}>
                        {savedTemplates && savedTemplates.map((template, index) => (
                            <Grid item lg={4} md={4} xs={12} key={index} style={{ marginBottom: "25px" }}>
                                <Grid class="container-section p-10 pb-0">
                                    <Card className="image">
                                        <CardMedia
                                            sx={{ minHeight: 200 }}
                                            image={baseUrl + template.screenshot_url} />
                                        {/* <CardActions className="overlay d-block align-end">
                                            <Typography className='align-right mt-10 mr-10 font-weight-600 d-flex justify-end cursor-hover link-color' style={{ alignItems: "flex-start" }}>
                                                <Delete className="mr-5 fill-svg-icons"/>
                                                <span className="mr-10" onClick={() => { handleDeleteTemplate(template); }}>Delete</span>
                                            </Typography>
                                            <Grid sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Button className="btn view-button mr-5" onClick={() => viewTemplate(template)}><Eye className="mr-5 fill-svg-icons" />View</Button>
                                                <Button className="btn use-button" onClick={() => fetchHtml(template)}><img src={Edit} className="mr-5 mb-3"></img>Edit</Button>
                                            </Grid>
                                            {template.is_resettable &&
                                                <Typography onClick={() => { handleResetTemplate(template); }} className='text-center mt-15 font-weight-600 cursor-hover link-color'>
                                                    <Reset className="mr-5 fill-svg-icons" />Reset Template
                                                </Typography>
                                            }
                                        </CardActions> */}
                                    </Card>
                                    <Grid className='font-weight-600 d-flex justify-between mt-10'>
                                <Typography className='font-weight-600 d-flex justify-between main-text-color' style={{ alignItems: "flex-start" }}>
                                    <span>{template?.template_name}</span>
                                </Typography>
                                <Button aria-describedby={id} type="button" aria-label="button" className='p-0 d-flex justify-end' disableRipple="true" onClick={(e) => handleClick(e, template, 'bottom-end')}>
                                <Typography className='secondary-bg-color border-radius-4 p-10 align-right font-weight-600 d-flex justify-end cursor-hover link-color publish-options' style={{ alignItems: "flex-start" }}>
                                        <ActionIcon className="svg-icons"/>
                                    </Typography>
                                </Button>
                                </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} xs={12} align="left" sx={{ pr: 5 }} className="mt-5 ml-10">
                                    <Grid className="d-flex align-center">
                                        {template?.tags?.map((tag, index) => {
                                            return index < 3 ? (<span key={index} className="skills textEllipse" title={tag}>{tag}</span>) : null;
                                        })}
                                        <span>
                                            {template?.tags?.length > 3 &&
                                                <span onClick={(e) => handleViewAllTags(e, template?.tags)}
                                                className="link-color cursor-hover text-decoration-none d-block f-12 font-weight-600 p-l-5"
                                                style={{ width: "64px" }}>
                                                    {`+${template?.tags?.length - 3} more`}
                                                </span>
                                            }
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>}
                {(totalCount === 0 || totalCount === undefined) ? "" : <>
                    {" "}
                    <Grid
                        container
                        item
                        spacing={1}
                        className="align-between align-center pagenation-design mt-30">
                        <Grid item lg={6} md={6} xs={12} className="pagenation-table pl-0">
                            <Typography className="d-flex f-14 align-center">
                                <Box className='gray7'>Showing</Box>
                                <Box className="count-jobs mx-5">9</Box>
                                <Box className='gray7'>items per page</Box>
                            </Typography>
                        </Grid>
                        {/* <Grid className="justify-end d-flex pagenation-table navbarUser pt-0">
                            <Stack spacing={2} className="pagenation-joblist"> */}
                                {/* <Pagination count={totalPage} page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" showFirstButton showLastButton /> */}
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        className="justify-end d-flex pagenation-table pt-8"
                      >
                        <Stack spacing={2} className="pagenation-joblist">
                          <Pagination
                            shape="rounded"
                            // color="primary"
                            // rowsperpage={rowsperpage}
                            count={totalPage}
                            page={pageNumber}
                            onChange={handlePageChange}
                            renderItem={(item) => (
                              <PaginationItem
                                components={{
                                  last: (props) => (
                                    <button
                                      className="btn primary-button profileButton"
                                      aria-label="button"
                                      {...props}
                                    >
                                      Last
                                    </button>
                                  ),

                                  next: (props) => (
                                    <Box
                                      className="d-flex align-center cursor-hover"
                                      {...props}
                                    >
                                      <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                        Next
                                      </Box>
                                      <ArrowForwardIcon
                                        height="13px"
                                        width="13px"
                                      />{" "}
                                    </Box>
                                  ),

                                  first: (props) => (
                                    <Box
                                      className="d-flex align-center cursor-hover"
                                      {...props}
                                    >
                                      <ArrowBackIcon
                                        height="13px"
                                        width="13px"
                                      />{" "}
                                      <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                        Previous
                                      </Box>
                                    </Box>
                                  ),

                                  previous: (props) => (
                                    <Box
                                      className="d-flex align-center cursor-hover"
                                      {...props}
                                    >
                                      <ArrowBackIcon
                                        height="13px"
                                        width="13px"
                                      />{" "}
                                      <Box className="gray7 f-14 font-weight-500 ml-10 mr-20">
                                        Previous
                                      </Box>
                                    </Box>
                                  ),
                                }}
                                {...item}
                              />
                            )}
                            showFirstButton={false}
                            showLastButton={false}
                          />
                        </Stack>
                      </Grid>
                            {/* </Stack>
                        </Grid> */}
                    </Grid>
                </>}

            </Grid>
            {publishedTemplate == null && savedTemplates?.length == 0 && <Grid container spacing={1} item lg={12} md={12} xs={12}>
                <Grid item lg={12} md={6} xs={6} className="d-flex justify-flex-end align-baseline">
                    <Button
                        aria-label="Menu icon"
                        className="pr-0 upload-status"
                        disableRipple="true"
                    >
                        <Typography className="btn upload-button justify-between post-button ml-10 bulk-upload-btn job-detail-matching" onClick={handleOpenUploadTemplates}>
                            <UploadSvg style={{ height: "19px" }} className="mr-10 svg-icons" />
                            <span className="small-text-font position-issue postJob-btn font-weight-700">Upload Template</span>
                        </Typography>
                    </Button>
                </Grid>
                <Grid
                    item lg={4} md={4} xs={12}
                    className="m-auto text-center">
                    <Typography className='mt-10'>
                        <img src={EmptyScreen} alt="something went wrong"></img>
                        <Typography className="mt-30 mb-30 f-25 font-weight-600 light-text">Create your first career site</Typography>
                        <Button class="btn primary-button pt-10 pb-10 mb-10 mt-10" aria-label="button" onClick={(e) => setOpenGetStarted(true)}
                            tabindex={0} type="button" disableRipple="true">Get Started</Button>
                    </Typography>
                </Grid>
            </Grid>}
            <Modal
                open={openUploadTemplates}
                onClose={(e) => handleCloseUploadTemplates}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-content inputcontrasts">
                    <Typography id="modal-title" className="d-flex align-between">
                        <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                            Upload Template
                        </Typography>
                        <Typography align="right" className="cursor-hover sub-text-color" aria-label="Close icon" role="button">
                            <CloseIcon onClick={handleCloseUploadTemplates} onKeyPress={handleCloseUploadTemplates} tabIndex={0} aria-label="Close icon" />
                        </Typography>
                    </Typography>
                    <Typography className="f-16 font-weight-400 mt-10 mb-20">
                        Upload a zip file of your template to create your career site
                    </Typography>
                    <Grid item>
                        <Grid item id="modal-description" className="p-0">
                            <Grid item {...getRootProps({ className: 'text-center bg-input pt-20 pb-40 dropzone disabled inputcontrasts' })}>
                                <input {...getInputProps()} />
                                <Box><img src={uploadJobIcon} style={{ width: '25px' }} alt="upload image" /></Box>
                                <Box className="sub-text-color mt-10">
                                    <span>Drop File here</span>
                                    <Box>or use upload button (only .zip files)</Box>
                                </Box>
                                <Box className="mt-20">
                                    <Button className="btn profile-button" aria-label="EUpload" disabled={filesArrays?.length >= 1} disableRipple="true">Upload</Button>
                                </Box>
                            </Grid>
                            <Grid container spacing={1} style={{ marginTop: "6px" }}>
                                {/* <Grid item lg={6} md={6} xs={6}>
                                    <Box className='m-b-model-label'>
                                        <Typography className='small-text-font font-weight-500'>
                                            Select Template Category<span className="text-black">*</span>{" "}
                                        </Typography>
                                        <Box>
                                            <Select
                                                style={{ width: "100%", marginTop: "7px" }}
                                                name="category"
                                                id="category"
                                                value={templateCategory ? templateCategory : "default"}
                                                onChange={(event) => setTemplateCategory(event.target.value) }
                                                defaultValue={"default"}
                                                placeholder="Select Category"
                                                className=""
                                                >
                                                <MenuItem value={"default"} >
                                                    --Select--
                                                </MenuItem>
                                                {templateCategoryOptions && templateCategoryOptions.map(({ value, label }, index) => <MenuItem value={value} >{label}</MenuItem>)}
                                            </Select>
                                        </Box>
                                    </Box>
                                </Grid> */}
                                <Grid item lg={6} md={6} xs={6}>
                                    <Box className='m-b-model-label'>
                                        <Typography className="f-14">
                                        Template Name<span className="text-black">*</span>{" "}
                                        </Typography>
                                        <Input
                                            type="text"
                                            id="folder_name"
                                            name="folder_name"
                                            value={templateName}
                                            onChange={(event) => {
                                                setTemplateName(event.target.value);
                                                if (event.target.value?.length > 128) {
                                                    setNameError("Exceeded maximum character length of 128.");
                                                } else if (event.target.value?.length == 0) {
                                                    setNameError("Template name is required.");
                                                } else {
                                                    setNameError("");
                                                }
                                            }}
                                            onKeyPress={handleSpaces}
                                            className={`form-control input-group ${nameError ? "is-invalid" : ""
                                                }`}
                                            margin="normal"
                                            placeholder="Enter template name"
                                        />
                                    </Box>
                                    <span className="danger-color error-msg">
                                        {" "}
                                        {nameError}</span>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Box className='m-b-model-label'>
                                        <Typography className='f-14'>
                                            Template Overview<span className="text-black">*</span>{" "}
                                        </Typography>
                                        <Input
                                            type="text"
                                            id="overview"
                                            name="overview"
                                            value={templateOverview}
                                            onChange={(event) => {
                                                setTemplateOverview(event.target.value);
                                                if (event.target.value?.length > 256) {
                                                    setError("Exceeded maximum character length of 256.");
                                                } else if (event.target.value?.length == 0) {
                                                    setError("Template overview is required.");
                                                } else {
                                                    setError("");
                                                }
                                            }}
                                            onKeyPress={handleSpaces}
                                            className={`form-control input-group m-b-0 inputcontrasts ${_error ? "is-invalid" : ""
                                                }`}
                                            margin="normal"
                                            placeholder="Enter template overview"
                                        />
                                    </Box>
                                    <span className="danger-color error-msg">
                                        {" "}
                                        {_error}
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "10px" }}>
                                <Grid item lg={12} md={12} xs={12}>
                                    <Box className='m-b-model-label'>
                                        <Typography className="f-14 d-flex align-center">
                                            Tags<span className="text-black">*</span>{" "}
                                            <Tooltip title="Use relevant tags to describe your template and press Enter" placement="right">
                                            <Box>
                                                <IconButton className="d-block p-0" style={{ marginLeft: '3px' }} aria-label="Info icon">
                                                    <InfoIcon aria-label="Info icon" style={{ width: '0.7em', height: '0.7em' }}/>
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                        </Typography>
                                        <Input
                                            style={{ height: "47px" }}
                                            type="text"
                                            name="tags"
                                            id="tags"
                                            className={`form-control input-group ${_tagError ? "is-invalid" : ""
                                                        }`}
                                            margin="normal"
                                            placeholder="Enter tags"
                                            value={tag}
                                            onChange={handleInputChange}
                                            onKeyDown={handleInputKeyDown}
                                            onKeyPress={handleSpaces}
                                        />
                                    </Box>
                                    <span className="danger-color error-msg text-left">
                                        {" "}{_tagError}
                                    </span>
                                </Grid>
                                <Grid item lg={12} md={12} xs={12} align="left">
                                    <Box className="mt-5 campaign-create">
                                        {chipData && chipData.map((chip) => (
                                            <Chip
                                                key={chip}
                                                label={chip}
                                                onDelete={handleDelete(chip)}
                                            />
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                            {filesArrays?.length > 0 &&
                                <Grid item lg={12} md={12} xs={12} className="mt-20 border-bottom-1 mb-10">
                                    <Grid item className="justify-between">
                                        <span className="f-12 sub-text-color">Added File</span>
                                    </Grid>
                                    {filesArrays && filesArrays.map((file, index) => {
                                        return (
                                            <Grid item className="justify-between mt-10 d-flex" key={index}>
                                                <Grid item className="d-flex workDocument align-center">
                                                    {file.filename && <p>{file.filename}</p>}
                                                </Grid>
                                                <Grid item className="d-flex sub-text-color font-13 align-center d-flex justify-center">
                                                    <span className="d-flex"
                                                        onClick={() => {
                                                            setDeleteConfi(true);
                                                            setIndex(index);
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                        onKeyPress={() => {
                                                            setDeleteConfi(true);
                                                            setIndex(index);
                                                        }}
                                                    >
                                                        <img
                                                            className="sub-text-font m-r-10"
                                                            src={deleteIcon}
                                                            alt="delete icon"
                                                            width="18px"
                                                            height="18px"
                                                            tabIndex={0}
                                                        />
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>}
                        </Grid>

                        <Grid item id="modal-footer" className=" mt-20">
                            <Typography className="justify-center d-flex">
                                <Button
                                    type="submit"
                                    className="btn primary-button profileButton"
                                    variant="contained"
                                    onClick={uploadHandler}
                                    disabled={filesArrays?.length === 0 || !chipData?.length || isSubmitted || !templateOverview || _error || !templateName}
                                    aria-label="Submit"
                                >
                                    Submit
                                </Button>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openGetStarted}
                onClose={(e) => setOpenGetStarted(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="career-modal-content inputcontrasts">
                    <Typography id="modal-title" className="d-flex align-between">
                        <Typography variant="h5" className="f-20 font-weight-600">
                            Choose an option to start
                        </Typography>
                        <Typography align="right" className="cursor-hover sub-text-color" aria-label="Close icon">
                            <CloseIcon onClick={(e) => setOpenGetStarted(false)} onKeyPress={(e) => setOpenGetStarted(false)} tabIndex={0} aria-label="Close icon" />
                        </Typography>
                    </Typography>
                    <Grid item>
                        <Grid item id="modal-description" className="text-center mt-40 mb-30 p-0">
                        <Typography className="post-button ml-10 mb-20 job-detail-matching">
                                <button
                                    type="button"
                                    className="btn profile-button small-text-font position-issue postJob-btn modal-buttons"
                                    onClick={() => window.location.reload()}
                                    aria-label="Upload job"
                                >
                                    Select From Templates
                                </button>
                            </Typography>
                            <Typography className="post-button ml-10 mb-20 job-detail-matching">
                                <button
                                    type="button"
                                    className="btn profile-button small-text-font position-issue postJob-btn modal-buttons"
                                    onClick={() => navigate('../template')}
                                    aria-label="Upload job"
                                >
                                    Create From Scratch &nbsp;&nbsp;&nbsp;
                                </button>
                            </Typography>
                            <Typography className="post-button ml-10 job-detail-matching">
                                <button
                                    type="button"
                                    className="btn profile-button small-text-font position-issue postJob-btn modal-buttons"
                                    onClick={() => {
                                        setOpenGetStarted(false); handleOpenUploadTemplates();
                                    }}
                                    aria-label="Upload job"
                                >
                                    Upload Template&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </button>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openSaveModal}
                onClose={(e) => {
                    setOpenSaveModal(false); setTemplateName(""); setTag(""); setTemplateNameError(""); setTagError("");
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid item className="modalStyle p-20 inputcontrasts" style={{ width: '480px' }}>
                    <Grid container item spacing={1} lg={12} md={12} xs={12}>
                        <Grid item lg={6} md={6} xs={6} align="left">
                            <Typography className="f-20 font-weight-600 modalTitle heading-text-color" variant="h5">
                                Template Update
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={6} align="right" className="cursor-hover sub-text-color">
                            <Typography className="icon-button">
                                <CloseIcon onClick={(e) => {
                                    setOpenSaveModal(false);
                                    setTemplateName("");
                                    setTag("");
                                    setTemplateNameError("");
                                    setTagError("");
                                }}
                                    onKeyPress={(e) => {
                                        setOpenSaveModal(false);
                                        setTemplateName("");
                                        setTag("");
                                        setTemplateNameError("");
                                        setTagError("");
                                    }} tabIndex={0}
                                    aria-label="Close icon" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className="mt-15">
                        <Box>
                            <Typography className="small-text-font mb-0 mt-20" align="left">
                            Template Name
                            </Typography>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                className={`form-control input-group m-b-0 inputcontrasts ${_templateNameError ? "is-invalid" : ""
                                    }`}
                                margin="normal"
                                placeholder="Enter name"
                                value={templateName}
                                onChange={(event) => {
                                    setTemplateName(event.target.value);
                                    if (event.target.value?.length > 128) {
                                        setTemplateNameError("Exceeded maximum character length of 128.");
                                    } else if (event.target.value?.length == 0) {
                                        setTemplateNameError("Template name is required.");
                                    } else {
                                        setTemplateNameError("");
                                    }
                                }}
                                onKeyPress={handleSpaces}
                            />
                        </Box>
                        <span className="danger-color error-msg text-left">
                            {" "}{_templateNameError}
                        </span>
                        <Box>
                            <Typography className="small-text-font mb-0 mt-20" align="left">
                                Tag
                            </Typography>
                            <Input
                                type="text"
                                name="tags"
                                id="tags"
                                className={`form-control input-group m-b-0 inputcontrasts ${_tagError ? "is-invalid" : ""
                                    }`}
                                margin="normal"
                                placeholder="Enter Tag"
                                value={tag}
                                onChange={handleInputChange}
                                onKeyDown={handleInputKeyDown}
                                onKeyPress={handleSpaces}
                            />
                        </Box>
                        <span className="danger-color error-msg text-left">
                            {" "}{_tagError}
                        </span>
                        <Grid item lg={12} md={12} xs={12} align="left">
                            <Box className="mt-5 campaign-create">
                                {chipData && chipData.map((chip) => (
                                    <Chip
                                        key={chip}
                                        label={chip}
                                        onDelete={handleDelete(chip)}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        <Typography className="mt-35 justify-end d-flex align-center">
                            <Button
                                type="submit"
                                className="btn profile-button mr-10"
                                variant="contained"
                                aria-label="Submit"
                                onClick={(e) => setOpenSaveModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className="btn primary-button profileButton"
                                variant="contained"
                                aria-label="Submit"
                                disabled={!templateName || !chipData?.length || _templateNameError}
                                onClick={handleUpdateTemplate}
                            >
                                Save
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Modal>
            <ConfirmDialog
                open={deleteConfi}
                setOpen={setDeleteConfi}
                dialogTitle="Delete Template"
                description={"Are you sure?"}
                handleConfirmYes={deleteTemplate}
            />
            <ConfirmTemplateDeleteDialog
                open={deleteTemplateDiolog}
                setOpen={setDeleteTemplateDiolog}
                dialogTitle="Delete Template"
                description={"Are you sure?"}
                handleConfirmYes={deleteSavedTemplate}
            />
            <ConfirmTemplateResetDialog
                open={resetTemplateDiolog}
                setOpen={setResetTemplateDiolog}
                dialogTitle="Reset Template"
                description={"Are you sure?"}
                handleConfirmYes={resetTemplate}
            />
        </Grid>
    );
}
