import { useEffect, useRef, useState } from "react";
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs/dist/grapes.min.js';
import preset from 'grapesjs-preset-webpage';
import basic from 'grapesjs-blocks-basic';
import exportPlugin from "grapesjs-plugin-export";
import custom from 'grapesjs-custom-code';
import forms from "grapesjs-plugin-forms";
import pluginTooltip from "grapesjs-tooltip";
import navbar from "grapesjs-navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { danger, success } from "../helpers/message-box";
import 'react-toastify/dist/ReactToastify.css';
import projectManager from 'grapesjs-project-manager';
import scriptEditor from 'grapesjs-script-editor';
import Modal from "@mui/material/Modal";
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    TextareaAutosize,
    TextField,
    Tooltip,
    Typography,
    Radio,
    RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import myPlugin from "./Jobs";
import Search from "./search";
import talentpool from "./TalentPool";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleSpaces } from "../helpers/helper-data";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import ApiCalls from "../../api/customAxios";
import Loader from "../Loader";
import EditorApiCalls from "../../api/customAxiosEditor";
import CodeEditor from 'grapesjs-component-code-editor';
import 'grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css';
import axios from "axios";
import { Helmet } from "react-helmet";
import KeycloakService from "../../keycloak";
const Constant = require("../helpers/constants");
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

let apiUrl = process.env.REACT_APP_EDITOR_URL_GRAPEJS;
/***
0.1
This scripts read images element from the web page and the css file (backgrounds)
***/
/*
get image src from CSS file
Just local urls
param ed: editor
*/

function imgCSS(ed) {
    var code = ed.getCss();
    var imags;
    var local = document.location.host.replace(/[-\/\\^$*+?.()|[\]{ }]/g, '\\$&');
    var regex = new RegExp('(http:|https:){0, 1}\/\/' + local, 'g');
    code = code.replace(regex, ''); //limpiar url locales
    code = code.replace(/(http:|https:){0, 1}\/\//g, '#'); //marcar url no locales
    imags = code.match(/\("{0, 1}[^#^\(]+?\.(gif|jpg|png|jpeg|tif|tiff|webp|svg|ico)"{0, 1}\)/gi);
    if (imags !== null) {
        imags = imags.map(function (x) {
            return x.replace(/\("{0,1}(.*){0,1}"\)/, '$1');
        });
    } else {
        imags = [];
    }
    return imags;
}
/*
get image src from HTML file
just local urls
*/
function imgHTML() {
    var imags = [];
    var src;
    var code = document.querySelector('.gjs-frame').contentDocument;
    code = code.getElementsByTagName('img');
    for (var i = 0; i < code.length; i++) {
        src = code[i].src;
        src = src.replace(window.location.origin, '');
        if (!src.includes('http')) {
            imags.push(src);
        }
    };

    return imags;
}



function arrayBufferToBinary(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return binary;
};

/*
Leer los archivos de imagen
param: ed  el editor
*/
async function readImgs(ed) {
    var imgData;
    var listaImgs;
    var imagsSet;
    var peticion;
    var archivo;
    var content = [];
    listaImgs = imgHTML(ed).concat(imgCSS(ed));
    imagsSet = new Set(listaImgs);
    listaImgs = [...imagsSet];
    for (var i = 0; i < listaImgs.length; i++) {
        try {
            peticion = await fetch(listaImgs[i]);
            imgData = await peticion.arrayBuffer();
            archivo = peticion.url.match(/[^\/\.]*\.[^\.]*$/, '$&')[0];
            content[archivo] = arrayBufferToBinary(imgData);
        } catch (e) {
            console.log("error " + e.message);
        }
    };
    return content;
}// JavaScript Document
function BlankTemplate() {
    let saveContentEditor;
    let location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    let saveZipCommandEditor;
    let URLPath = location.pathname;
    const [openPublishModal, setOpenPublishModal] = useState(false);
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState();
    const [faviconError, setFaviconError] = useState(false);
    const [details, setDetails] = useState({});
    const fileRef = useRef(null);
    const [templateName, setTemplateName] = useState(location.state?.template_name);
    const [templateOverview, setTemplateOverview] = useState(location.state?.template_overview);
    const [templatehtml, setTemplateHtml] = useState("");
    const [templateCss, setTemplateCss] = useState("");
    const [_error, setError] = useState();
    const [_overviewError, setOverviewError] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEmptyHtml, setIsEmptyHtml] = useState(false);
    const [tag, setTag] = useState("");
    const [_tagError, setTagError] = useState();
    const [chipData, setChipData] = useState([]);
    const [published_html, setPublished_html] = useState();
    const [publishMode, setPublishMode] = useState("N");
    const [username, setUsername] = useState("");
    const [username_error, setUsername_error] = useState("");
    const [password, setPassword] = useState("");
    const [password_error, setPassword_error] = useState("");
    const handleCloseSaveModal = () => {
        setOpenSaveModal(false);
        setTemplateName("");
        setTemplateOverview("");
        setError("");
        setOverviewError("");
        setChipData([]);
        setTag("");
        setTagError("");
    };
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    function checkChildTags(editor) {
        const domParser = new DOMParser();
        const parsedHtml = domParser.parseFromString(editor.getHtml(), 'text/html');
        const body = parsedHtml.querySelector('body');
        const childTags = Array.from(body.children).filter((child) => child.tagName !== 'SCRIPT');
        const numChildTags = childTags.length;
        if (numChildTags === 0) {
            setIsEmptyHtml(true);
        } else {
            setIsEmptyHtml(false);
        }
    }

    const websiteSettings = {
        title: "",
        url: "",
        description: "",
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required("Website title is required")
            .max(128, "Exceeded maximum character length of 128")
            .matches(/^\w+/, "Please enter valid website title"),
        pixelKey: Yup.string()
            .nullable()
            .max(128, "Exceeded maximum character length of 128"),
        url: Yup.string()
            .required("Website URL is required")
            .max(128, "Exceeded maximum character length of 128")
            .matches(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(?:\.[a-zA-Z0-9]{2,})+$/, "Please enter valid domain"),
        description: Yup.string()
            .nullable().notRequired()
            .max(256, "Exceeded maximum character length of 256")
            // .matches(/^\w+/, "Please enter valid description"),
    });
    const {
        register: register,
        handleSubmit: handleSubmit,
        watch,
        reset,
        getValues,
        clearErrors,
        setValue: setValue,
        formState: { errors: _errors, isValid: _isValid },
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
        defaultValues: websiteSettings,
    });

    function getStructuredHtml(bodyHtml) {
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = bodyHtml;

        var metaTags = tempDiv.getElementsByTagName('meta');
        var linkTags = tempDiv.getElementsByTagName('link');
        var scriptTags = tempDiv.getElementsByTagName('script');
        var titleTags = tempDiv.getElementsByTagName('title');

        var titleTagsHtml = '';
        for (var i = 0; i < titleTags.length; i++) {
            titleTagsHtml += titleTags[i].outerHTML;
        }

        var metaTagsHtml = '';
        for (var i = 0; i < metaTags.length; i++) {
            metaTagsHtml += metaTags[i].outerHTML;
        }

        var linkTagsHtml = '';
        for (var i = 0; i < linkTags.length; i++) {
            linkTagsHtml += linkTags[i].outerHTML;
        }

        var scriptTagsHtml = '';
        for (var i = 0; i < scriptTags.length; i++) {
            if (scriptTags[i]?.outerHTML?.includes("analytics") == false) {
                scriptTagsHtml += scriptTags[i].outerHTML;
            }
        }

        tempDiv.querySelectorAll('meta').forEach(function (metaTag) {
            metaTag.remove();
        });

        tempDiv.querySelectorAll('link').forEach(function (linkTag) {
            linkTag.remove();
        });

        tempDiv.querySelectorAll('script').forEach(function (scriptTag) {
            scriptTag.remove();
        });

        tempDiv.querySelectorAll('title').forEach(function (titleTg) {
            titleTg.remove();
        });
        var googleScript = "";
        if (getValues("pixelKey") != undefined && getValues("pixelKey") != null && getValues("pixelKey")?.length != 0) {
        // googleScript += `<script async name="analytics" src="https://www.googletagmanager.com/gtag/js?id=${getValues("pixelKey")}"></script>\n`
        // googleScript += `<script name="analytics">
        // window.dataLayer = window.dataLayer || [];
        // function gtag() { dataLayer.push(arguments); }
        // gtag('js', new Date());
        // gtag('config', '${getValues("pixelKey")}');
        // </script>`
        googleScript += `<script name="analytics">(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.name='analytics';j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${getValues("pixelKey")}');</script>`;
        }

        var completeHtml = '<!DOCTYPE html>\n<html>\n<head>\n' + metaTagsHtml + titleTagsHtml + linkTagsHtml + googleScript + '\n</head>\n<body><div id="live-jobs-check"></div><div id="live-pools-check"></div>\n' + tempDiv.innerHTML + scriptTagsHtml + '\n</body>\n</html>';
        return completeHtml;
    }

    const updateTemplate = async (editor, publish=false) => {
        var completeHtml = getStructuredHtml(editor.getHtml());
        // saveZipCommandEditor.runCommand('gjs-export-zip');
        checkChildTags(editor);
        setIsLoading(true);
        var formData = new FormData();
        let html = completeHtml;
        formData.append("html", html);
        formData.append("css", editor.getCss());
        if (localStorage.getItem('template_id')) {
            formData.append("template_id", localStorage.getItem('template_id'));
        }
        if (localStorage.getItem('enterprise_template_id')) {
            formData.append("enterprise_template_id", localStorage.getItem('enterprise_template_id'));
        }

        formData.append("name", templateName !== undefined ? templateName?.split(" ")?.join("") : '');
        formData.append("overview", templateOverview !== undefined ? templateOverview : '');
        await EditorApiCalls.post(`/templates/save`, formData, {
            headers: {
                'enterprise-id': localStorage.getItem('client_name')
              }
        })
        .then(function (response) {
            localStorage.setItem('isTemplateSaved', 1);
            localStorage.setItem('enterprise_template_id', response.data.data.enterprise_template_id);
            localStorage.setItem('template_id', response.data.data.template_id);
            window.onbeforeunload = null;
            if (!publish) {
                success('Template is saved.');
                setIsLoading(false);
            } else {
                typeof(file) != "object" ? SaveDetails() : getFaviconUrl();
            }
        })
        .catch(function (error) {
            setIsLoading(false);
            if (error?.statusCode != 500) {
                danger(error);
            }
            console.log(error);
        });
    };

    const saveTemplate = async () => {
        // saveZipCommandEditor.runCommand('gjs-export-zip');
        setIsSubmitted(true);
        setIsLoading(true);
        var formData = new FormData();
        formData.append("html", templatehtml);
        formData.append("css", templateCss);
        if (localStorage.getItem('template_id')) {
            formData.append("template_id", localStorage.getItem('template_id'));
        }
        if (localStorage.getItem('create_from_scratch')) {
            formData.append("category", 'Others');
            formData.append("create_from_scratch", 1);
        }
        if (localStorage.getItem('enterprise_template_id')) {
            formData.append("enterprise_template_id", localStorage.getItem('enterprise_template_id'));
        }
        formData.append("name", templateName?.split(" ")?.join(""));
        formData.append("overview", templateOverview.trim());
        formData.append("tags", chipData);
        await EditorApiCalls.post(`/templates/save`, formData, {
            headers: {
                'enterprise-id': localStorage.getItem('client_name')
              }
        })
        .then(function (response) {
            localStorage.setItem('isTemplateSaved', 1);
            localStorage.setItem('enterprise_template_id', response.data.data.enterprise_template_id);
            localStorage.setItem('template_id', response.data.data.template_id);
            localStorage.removeItem('create_from_scratch');
            handleCloseSaveModal();
            success('Template is saved.');
            // navigate('/MyCareerSite');
            setIsLoading(false);
            setIsSubmitted(false);
            window.onbeforeunload = null;
        })
        .catch(function (error) {
            setIsLoading(false);
            setIsSubmitted(false);
            if (error?.statusCode != 500) {
                danger(error);
            }
            console.log(error);
        });
    };

    const uploadFavicon = () => {
        fileRef.current?.click();
    };
    const handleOnChange = (e) => {
        if (!isValidFileType(e.target.files[0].name)) {
            danger("Please upload only JPEG, JPG, PNG, SVG and GIF file formats!");
            return false;
        }
        setFile(e.target.files[0]);
    };
    const changeFile = () => {
        fileRef.current.value = null;
    };
    const isValidFileType = (fName) => {
        let extensionLists = ["jpeg", "jpg", "png", "JPG", "JPEG", "PNG", "gif", "svg"];
        return extensionLists.indexOf(fName.split(".").pop()) > -1;
    };

    // function myCustomPluginForSearch(editor) {
    //     editor.BlockManager.add('my-second-block', {
    //         label: `<br/><img src=${searchIcon}/><br/><br/><div>Search Bar</div>`,
    //         content: '<div class="my-block">This is a simple block</div>'
    //     })
    // };

    // function myCustomPluginForJobs(editor) {
    //     editor.BlockManager.add('my-first-block', {
    //         label: `<img src=${jobsIcon}/><br/><div>Jobs Card</div>`,
    //         content: '<div class="my-block">This is a simple block</div>',
    //     });
    // }

    const handleInputChange = (event) => {
        setTag(event.target.value);
        // if (event.target.value.length >= 256) {
        //     setTagError("Exceeded maximum character length of 256.");
        // } else if (event.target.value.length == 0) {
        //     setTagError("Tag is required.");
        // } else {
        //     setTagError("");
        // }

        if (event.target.value.length == 0) {
            setTagError("Tag is required.");
        } else {
            setTagError("");
        }
    };

    const handleInputKeyDown = (event) => {
        if (event.key === "Enter" && tag) {
          setChipData([...new Set([...chipData,  tag])]);
          setTag("");
          setTimeout(() =>
                document.getElementById("modal-description")?.scrollTo({
                    top: document.getElementById("modal-description").scrollHeight,
                    behavior: 'smooth',
                }), 600
            );
        }
    };
    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) =>
          chips.filter((chip) => chip !== chipToDelete)
        );
    };

    useEffect(() => {
        localStorage.setItem('isTemplateSaved', 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    function fetchHtmlCss(template_id, editor) {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('enterprise_template_id');
            localStorage.removeItem('create_from_scratch');
            localStorage.setItem('template_id', template_id);
            setIsLoading(true);
            EditorApiCalls.get(`/default-templates/${template_id}`)
                .then((res) => {
                    const { data } = res.data;
                    setTemplateName(data?.folder_name);
                    setTemplateOverview(data?.overview);
                    // editor.setComponents(data?.html);
                    // editor.setStyle(data?.css);
                    resolve(data);
                })
                .catch(function (error) {
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    function replaceVariables(htmlString) {
        const replacements = {
            validDomainNames: localStorage.getItem('encoded_domain'),
            origin: localStorage.getItem("appOrigin") === "N" ? "2" : localStorage.getItem("appOrigin") === "H" ? "1" : "0",
        };

        const regex = /var\s+(\w+)\s*(?:=\s*(".*?"|\S*))?;/g;

        const replacedString = htmlString.replace(regex, (match, variableName, oldValue) => {
            const newValue = replacements[variableName];
            return newValue !== undefined ? `var ${variableName} = "${newValue}";` : match;
        });

        return replacedString;
    }

    function updateValidDomainName(htmlContent) {
        var updatedValue = localStorage.getItem('encoded_domain');
        var pattern = /(var validDomainNames(?:\s*=\s*[^;]+)?)/;
        return htmlContent.replace(pattern, "$1 = '" + updatedValue + "';");
    }

    function updateOriginName(htmlContent) {
        let appOrigin = localStorage.getItem("appOrigin") === "N" ? "2" : localStorage.getItem("appOrigin") === "H" ? "1" : "0";
        var pattern = /(var origin(?:\s*=\s*[^;]+)?)/;
        return htmlContent.replace(pattern, "$1 = '" + appOrigin + "';");
    }

    useEffect(() => {
        var editor = grapesjs.init({
            container: '#gjs',
            height: '100vh',
            width: '100%',
            allowScripts: 1,
            // noticeOnUnload: 0,
            pageManager: true,
            fromElement: true,
            plugins: ['gjs-preset-webpage', //to get blank web page view with default option
                'grapesjs-blocks-basic', // to get basic options like image, link
                'grapesjs-plugin-export', //export to zip option
                'grapesjs-plugin-forms', // forms blocks
                'grapesjs-custom-code', // extra custom code block
                'grapesjs-tooltip',
                'grapesjs-navbar',
                'myPlugin',
                // 'primaryColor',
                'grapesjs-preset-webpage',
                'grapesjs-assets-manager',
                'grapesjs-project-manager',
                'grapesjs-script-editor',
                 CodeEditor,
                "grapesjs-parser-postcss",
                'job-cards',
                'search-jobs',
                'talent-pool',
                (editor) =>
                    scriptEditor(editor, {
                        /* options */
                    }),
                (editor) =>
                    preset(editor, {
                        /* options */
                        modalImportTitle: 'Import Template',
                        importViewerOptions: true,
                        modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
                        modalImportContent: function (editor) {
                            return editor.getHtml() + '<style>' + editor.getCss() + '</style>';
                        },
                    }, saveContentEditor = editor),
                (editor) =>
                    basic(editor, {
                        /* options */
                    }),
                (editor) =>
                    exportPlugin(editor, {
                        btnLabel: 'Export as ZIP',
                        root: {
                            css: {
                                'style.css': (editor) => editor.getCss(),
                            },
                            img: async (editor) => {
                                const images = await readImgs(editor);
                                return images;
                            },
                            'index.html': (editor) =>
                                `<!doctype html>
                <html lang="en">
                    <head>
                        <meta charset="utf-8">
                            <link rel="stylesheet" href="./css/style.css">
                            </head>
                            <body><div id="live-jobs-check"></div><div id="live-pools-check"></div>${editor.getHtml()}</body>
                        </html>`,
                        },
                    }),
                (editor) =>
                    forms(editor, {
                        /* options */
                    }),
                (editor) =>
                    pluginTooltip(editor, {
                        /* options */

                    }),
                (editor) =>
                    navbar(editor, {
                        /* options */
                    }),
                (editor) =>
                    custom(editor, {
                        /* options */
                    }),
                // (editor) =>
                //     myCustomPluginForJobs(editor, {
                //         /* options */
                //     }),
                // (editor) =>
                //     myCustomPluginForSearch(editor, {
                //         /* options */
                //     }),
                (editor) =>
                    projectManager(editor, {
                        /* options */
                    }),
            ],
            // assetManager: {
            //     assets: assets,
            //     upload: false,
            //     custom: true
            // },
            storageManager: {
                id: 'gjs-',
                type: 'local',
                autosave: false,
                storeComponents: false,
                storeStyles: false,
                storeHtml: false,
                storeCss: false,
                saveContentEditor: false,
                autoload: false,
            },
            canvas: {
                scripts: [
                  "https://code.jquery.com/jquery-3.6.4.js"
                ],
              },
            deviceManager: {
                devices:
                    [
                        {
                            id: 'desktop',
                            name: 'Desktop',
                            width: '',
                        },
                        {
                            id: 'tablet',
                            name: 'Tablet',
                            width: '768px',
                            widthMedia: '992px',
                        },
                        {
                            id: 'mobilePortrait',
                            name: 'Mobile portrait',
                            width: '320px',
                            widthMedia: '575px',
                        },
                    ]
            },
            pluginsOpts: {
                'grapesjs-preset-webpage': {
                    blocksBasicOpts: {
                        blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video'],
                        flexGrid: 1,
                    },
                    blocks: ['link-block', 'quote', 'text-basic'],
                },
                [CodeEditor]: {
                    /* Test here your options  */
                }
            },
        });
        editor.getModel().set('dmode', 'translate');

        function hideJobCardLoader() {
            let elem = editor.Canvas.getElement().getElementsByTagName('iframe')[0]?.contentDocument?.body;
            const loader = elem.getElementsByClassName("lottie-container")[0];

            console.log('loader ----------------');
            console.log(loader);
            if (loader) {
                loader.style.display = "none";
            }
        }

        function removeLoaderFromDom(dom) {
            dom.getElementById("spinner")?.remove();
            dom.getElementById("load")?.remove();
            dom?.querySelector(".load")?.remove();
            dom?.querySelector(".loader_bg")?.remove();
            dom?.getElementById("live-jobs-check")?.remove();
            dom?.getElementById("live-pools-check")?.remove();
        }

        editor.on('load', function () {
            setIsLoading(true);
            const template_id = searchParams.get('template_id');
            if (template_id) {
                fetchHtmlCss(template_id, editor)
                    .then((data) => {
                        // Assuming you have the HTML content stored in a variable called 'htmlContent'
                        var htmlContent = data?.html; // Replace this with your HTML content
                        if (data?.folder_name == 'CP00201') {
                            htmlContent = replaceVariables(htmlContent);
                        }
                        // Removing loader and live jobs once template loaded into editor
                        let dom = new DOMParser().parseFromString(htmlContent, 'text/html');
                        removeLoaderFromDom(dom);
                        editor.setComponents(dom.documentElement.innerHTML.toString());
                        if (data?.css) {
                            editor.setStyle(data?.css);
                        }

                        // Hidding job card loader in editor
                        hideJobCardLoader();

                        console.log(editor.getCss());
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                        setIsLoading(false);
                    });
            } else {
                // Assuming you have the HTML content stored in a variable called 'htmlContent'
                var html = location.state?.html ? location.state?.html : ''; // Replace this with your HTML content

                if (location.state?.template_name == 'CP00201') {
                    html = replaceVariables(html);
                }
                // removing loader and live jobs once template loaded into editor
                let dom = new DOMParser().parseFromString(html, 'text/html');
                removeLoaderFromDom(dom);
                editor.setComponents(dom.documentElement.innerHTML.toString());
                if (location.state?.css) {
                    editor.setStyle(location.state?.css);
                }

                // Hidding job card loader in editor
                hideJobCardLoader();

                setIsLoading(false);
            }
            //theme changes
            const new_style_element = document.createElement("style");
            new_style_element.textContent = `.gjs-selected {  outline: 2px solid ${localStorage.getItem("primaryColor") ? localStorage.getItem("primaryColor") : getComputedStyle(document.documentElement).getPropertyValue('--primary-color')} !important; }`;
            document.getElementsByClassName("gjs-frame")[0].contentDocument.body.appendChild(new_style_element);
        });

        editor.on('component:update', function() {
            localStorage.setItem('isTemplateSaved', 0);
        });

        // editor.Panels.addButton('options', {
        //     id: 'change-colors',
        //     className: 'fa fa-paint-brush',
        //     // command: 'change-colors',
        //     attributes: { title: 'Change Colors' },
        //     command: function(editor, sender, options) {
        //         // Show a form or color picker to input new color values
        //         const newColors = {
        //             'primary-color': '#00ff00',
        //             'secondary-color': '#ff00ff',
        //             'accent-color': '#ffff00',
        //         };
        //         console.log(newColors);
        //         alert('here');
        //         Object.entries(newColors).forEach(([name, color]) => {
        //             editor.setStyle(`--${name}`, color);
        //         });
        //         // navigate("/candidate/homepage");
        //     },
        // });

        // Access the Style Manager instance
        const styleManager = editor.StyleManager;

        // Define the custom sectors
        const customSectors = [
            {
                name: 'Color',
                properties: [
                    {
                        label: 'Font Color',
                        property: 'color',
                        type: 'color',
                        value: '#000000' // Set the default color value here
                    },
                    'background-color'
                ],
            }
        ];

        // Function to update the sectors based on the selected element
        function updateStyleManager(selectedElement) {
            // Clear existing sectors
            // styleManager.clear();

            // Add the custom sectors
            customSectors.forEach((sector) => {
                styleManager.addSector(sector.name, sector);
            });
        }


        //  Create a UI button for the custom plugin
        editor.Panels.addButton('options', {
            id: 'change-colors',
            className: 'fa fa-paint-brush',
            command: function(editor, sender, options = {}) {
                const newPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color'); // Example value, replace with your desired primary color
                const newSecondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'); // Example value, replace with your desired secondary color
                console.log("newPrimaryColor", newPrimaryColor);
                console.log("newSecondaryColor", newSecondaryColor);

                const classToAdd = 'custom-color-class'; // Replace with your desired class name

                // Get the HTML content from the editor
                const htmlContent = editor.getHtml();

                // Create a temporary DOM element to parse the HTML
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = htmlContent;

                // Find all button and anchor elements
                const buttons = tempDiv.querySelectorAll('button');
                const anchors = tempDiv.querySelectorAll('a');

                // Add the custom class to button elements
                buttons.forEach((button) => {
                    button.classList.add(classToAdd);
                });

                // Add the custom class to anchor elements
                anchors.forEach((anchor) => {
                    anchor.classList.add(classToAdd);
                });

                // Get the CSS content from the editor
                const cssContent = editor.getCss();

                // Create the CSS rule for the custom class
                const cssRule = `.${classToAdd} { color: ${newSecondaryColor} !important; background-color: ${newPrimaryColor} !important; }`;

                // Append the CSS rule to the CSS content
                const updatedCss = cssContent + cssRule;

                // Set the updated HTML and CSS back to the editor
                editor.setComponents(tempDiv.innerHTML);
                editor.setStyle(updatedCss);
            },
            attributes: { title: 'Change Colors' },
        });

        // editor.on('load', function(){
        //     // removing loader and live jobs once template loaded into editor
        //     let dom = new DOMParser().parseFromString(editor.getHtml(), 'text/html');
        //     dom.getElementById("spinner")?.remove()
        //     dom?.querySelector(".loader_bg")?.remove()
        //     dom?.getElementById("live-jobs-check")?.remove()
        //     editor.setComponents(dom.documentElement.innerHTML.toString());
        //     editor.setStyle(location.state?.css);
        // });


        // if (location.state?.mode == 'Templates') {
        //     editor.setComponents(location.state?.html);
        //     editor.setStyle(location.state?.css);
        // } else {
        //     const template_id = searchParams.get('template_id');
        //     if (template_id) {
        //         fetchHtmlCss(template_id, editor)
        //     } else {
        //         editor.setComponents(location.state?.html);
        //         editor.setStyle(location.state?.css);
        //     }
        // }
        //for adding extra font options
        // const prop = editor.StyleManager.getProperty('typography', 'font-family');
        // prop.set('options', [
        //     {value: "'Oswald', sans-serif", name: 'Oswald'},
            // {value: 'Roboto, sans-serif', name: 'Roboto'},
        // ])

        editor.on('component:selected', (model) => {
            const selectedComponent = editor.getSelected();
            let defaultToolbar = selectedComponent.get('toolbar');

            const element = selectedComponent.getEl();
            const isJobCard = element.classList.contains('job-card-parent');
            const isJobsSection = element.classList.contains('jobs_section');
            const isJobCardList = element.classList.contains('job_list');
            const ispoolCard = element.classList.contains('pool-card-parent');
            const ispoolsSection = element.classList.contains('pool_section');
            const ispoolCardList = element.classList.contains('pools_list');
            const isSearchBlock = element.classList.contains('has-search');
            const isSearch = element.classList.contains('search');

            // Find the copy option in the toolbar items
            const copyOptionIndex = defaultToolbar.findIndex((item) => item.command === 'tlb-clone');

            // Remove the copy option if the job card or search block selected
            if ((isJobCard || isJobsSection || isJobCardList || ispoolCard || ispoolsSection || ispoolCardList || isSearchBlock || isSearch) && copyOptionIndex !== -1) {
                defaultToolbar.splice(copyOptionIndex, 1);
            }

            // For job card loader no need to show toolbar
            if (element.tagName.toLowerCase() == "lottie-player") {
                defaultToolbar = [];
            }

            selectedComponent.set({
                toolbar: defaultToolbar.map((list) => {
                    return {
                        ...list,
                        attributes: list.command === "edit-script" ? { title: "Edit Script" } :
                            list.command === "tlb-move" ? { title: "Move" } :
                                list.command === "tlb-clone" ? { title: "Copy" } :
                                list.command === "tlb-delete" ? { title: "Delete" } :
                                    list.id === "custom-code" ? { title: "Custom Code" } :
                                        { title: "Previous Block" }
                    };
                })
            });

            const selectedElement = model.view.el;

            // Update the Style Manager based on the selected element
            updateStyleManager(selectedElement);
        });
        editor.on("run:preview", () => { //hiding static data and showing dynamic onclick preview
            let elem = editor.Canvas.getElement().getElementsByTagName('iframe')[0]?.contentDocument?.body;

            document.getElementsByClassName("gjs-off-prv")[0]?.setAttribute("title", "Back to editor");
            if (elem.getElementsByClassName("has-search1")[0] != undefined) {
                elem.getElementsByClassName("has-search1")[0].style.display = "block";
                elem.getElementsByClassName("has-search1")[0].classList.add("preview");
            }
            if (elem.getElementsByClassName("jobs-preview")[0] != undefined) {
                for (let i = 0; i <  elem.getElementsByClassName("jobs-preview").length; i++) {
                    elem.getElementsByClassName("jobs-preview")[i].style.display = "block";
                    elem.getElementsByClassName("jobs-preview")[i].classList.add("jobsPreview");
                }
            }
            if (elem.getElementsByClassName("pools-preview")[0] != undefined) {
                for (let i = 0; i <  elem.getElementsByClassName("pools-preview").length; i++) {
                    elem.getElementsByClassName("pools-preview")[i].style.display = "block";
                    elem.getElementsByClassName("pools-preview")[i].classList.add("jobsPreview");
                }
            }
            if (elem.getElementsByClassName("jobs_section")[0] != undefined && elem.getElementsByClassName("jobs_section")[0]?.childNodes?.length != 0) {
                for (let i = 0; i <  elem.getElementsByClassName("jobs_section").length; i++) {
                    elem.getElementsByClassName("jobs_section")[i].style.display = "none";
                }

                for (let i = 0; i <  elem.getElementsByClassName("job-list-live").length; i++) {
                    elem.getElementsByClassName("job-list-live")[i].style.display = "block";
                }

                for (let i = 0; i <  elem.getElementsByClassName("custom-job-card-content").length; i++) {
                    elem.getElementsByClassName("custom-job-card-content")[i].style.display = "block";
                }
            } else {
                elem.getElementsByClassName("jobs_section")[0]?.remove();
            }
            if (elem.getElementsByClassName("pool_section")[0] != undefined && elem.getElementsByClassName("pool_section")[0]?.childNodes?.length != 0) {
                for (let i = 0; i <  elem.getElementsByClassName("pool_section").length; i++) {
                    elem.getElementsByClassName("pool_section")[i].style.display = "none";
                }

                for (let i = 0; i <  elem.getElementsByClassName("pool-list-live").length; i++) {
                    elem.getElementsByClassName("pool-list-live")[i].style.display = "block";
                }

                for (let i = 0; i <  elem.getElementsByClassName("custom-pool-card-content").length; i++) {
                    elem.getElementsByClassName("custom-pool-card-content")[i].style.display = "block";
                }
            } else {
                elem.getElementsByClassName("pool_section")[0]?.remove();
            }
            if (elem.getElementsByClassName("pool_section")[0] != undefined && elem.getElementsByClassName("pool_section")[0]?.childNodes?.length != 0) {
                for (let i = 0; i <  elem.getElementsByClassName("pool_section").length; i++) {
                    elem.getElementsByClassName("pool_section")[i].style.display = "none";
                }

                for (let i = 0; i <  elem.getElementsByClassName("pool-list-live").length; i++) {
                    elem.getElementsByClassName("pool-list-live")[i].style.display = "block";
                }

                for (let i = 0; i <  elem.getElementsByClassName("custom-pool-card-content").length; i++) {
                    elem.getElementsByClassName("custom-pool-card-content")[i].style.display = "block";
                }
            } else {
                elem.getElementsByClassName("pool_section")[0]?.remove();
            }
        });

        editor.on('run:core:component-delete', (data) => {
            //after delete job or search components from editor,
            // removing components details here and updating editor html

            var componentElement = data[0].view.el; // Get the DOM element of the deleted component
            console.log(componentElement);

            const hasJobSection = componentElement.classList.contains('jobs_section');
            const hasStaticJobs = componentElement.classList.contains('static-jobs');

            const haspoolSection = componentElement.classList.contains('pool_section');
            const hasStaticpools = componentElement.classList.contains('static-pools');

            let editor_html = document.createElement("div");
            editor_html.innerHTML = editor.getHtml();
            editor_html.css = editor.getCss();

            if (hasStaticJobs || hasJobSection) {
                editor_html.getElementsByClassName("job-card-parent")[0]?.remove();
                const scriptList = editor_html.getElementsByClassName("job-list-script");
                scriptList[0]?.remove();
                editor_html.getElementsByClassName("job-list-live")[0]?.remove();
                editor_html.getElementsByClassName("jobs-preview")[0]?.remove();
                editor_html.getElementsByClassName("jobs_section")[0]?.remove();
            }
            if (hasStaticpools || haspoolSection) {
                editor_html.getElementsByClassName("pool-card-parent")[0]?.remove();
                const scriptList = editor_html.getElementsByClassName("pool-list-script");
                scriptList[0]?.remove();
                editor_html.getElementsByClassName("pool-list-live")[0]?.remove();
                editor_html.getElementsByClassName("pools-preview")[0]?.remove();
                editor_html.getElementsByClassName("pool_section")[0]?.remove();
            }

            if (editor_html.getElementsByClassName("search")[0] == undefined) {
                const scriptList = editor_html.getElementsByClassName("search-script");
                scriptList[0]?.remove();
                editor_html.getElementsByClassName("has-search")[0]?.remove();
                editor_html.getElementsByClassName("has-search1")[0]?.remove();
                editor_html.getElementsByClassName("user-list")[0]?.remove();
            }
            editor.setComponents(editor_html.outerHTML.toString());
            editor.setStyle(editor_html.css);
        });
        editor.on('block:drag:stop', (block) => {
            const updatedHTML = editor.getHtml(); // Get the HTML from the editor
            // Create a new DOMParser instance
            const parser = new DOMParser();
            // Parse the HTML string into a DOM document
            const doc = parser.parseFromString(updatedHTML, 'text/html');
            // Custom code to execute when a block is dropped
            if (block) {
                console.log('A block was dropped:', block);
                let view = '';
                if (block.length > 0) {
                    view = block[0].view;
                } else {
                    view = block.view;
                }
                if (view) {
                    // You can access the block properties and perform further actions
                    const element = view.el;
                    console.log('Element:', element);

                    const isJobCard = element.classList.contains('job-card-parent');
                    if (isJobCard) {
                        // Find the <div> element by class
                        const isJobCardAlreadyExists = doc.getElementsByClassName('job-card-parent').length > 1;
                        // Check if the job cards element exists
                        // condition is not met, prevent the drop
                        if (isJobCardAlreadyExists) {
                            danger("Job card is already added");
                            block.remove();
                        }

                        // Findout the lottie container to remove the loader
                        const loader = element.getElementsByClassName("lottie-container")[0];

                        if (loader) {
                            element.getElementsByClassName("lottie-container")[0].style.display = "none";
                        }

                        // Find the desired div within the block view
                        const loaderDiv = element.querySelector('lottie-player');

                        // Set draggable: false for the div element
                        if (loaderDiv) {
                            loaderDiv.setAttribute('draggable', 'false');
                        }
                    }
                    const ispoolCard = element.classList.contains('pool-card-parent');
                    if (ispoolCard) {
                        // Find the <div> element by class
                        const ispoolCardAlreadyExists = doc.getElementsByClassName('pool-card-parent').length > 1;
                        // Check if the job cards element exists
                        // condition is not met, prevent the drop
                        if (ispoolCardAlreadyExists) {
                            danger("Talent pool card is already added");
                            block.remove();
                        }

                        // Findout the lottie container to remove the loader
                        const loader = element.getElementsByClassName("lottie-container")[0];

                        if (loader) {
                            element.getElementsByClassName("lottie-container")[0].style.display = "none";
                        }

                        // Find the desired div within the block view
                        const loaderDiv = element.querySelector('lottie-player');

                        // Set draggable: false for the div element
                        if (loaderDiv) {
                            loaderDiv.setAttribute('draggable', 'false');
                        }
                    }

                    const isSearchBlock = element.classList.contains('has-search');
                    if (isSearchBlock) {
                        // Find the <div> element by class
                        const isSearchAlreadyExists = doc.getElementsByClassName('has-search').length > 1;
                        // Check if the job cards element exists
                        // condition is not met, prevent the drop
                        if (isSearchAlreadyExists) {
                            danger("Job search bar is already added");
                            block.forEach( function (item) {
                                item.remove();
                            });
                            // block.remove();
                        }
                    }
                }
            }
        });
        editor.on("stop:preview", () => {
            let elem = editor.Canvas.getElement().getElementsByTagName('iframe')[0]?.contentDocument?.body;

            if (elem.getElementsByClassName("has-search1")[0] != undefined) {
                elem.getElementsByClassName("has-search1")[0].style.display = "none";
                elem.getElementsByClassName("has-search1")[0].classList.remove("preview");
                elem.getElementsByClassName("user-list")[0].style.display = "none";
            }
            if (elem.getElementsByClassName("jobs-preview")[0] != undefined) {
                for (let i = 0; i <  elem.getElementsByClassName("jobs-preview").length; i++) {
                    elem.getElementsByClassName("jobs-preview")[i].style.display = "none";
                    elem.getElementsByClassName("jobs-preview")[i].classList.remove("jobsPreview");
                }
            }
            if (elem.getElementsByClassName("pools-preview")[0] != undefined) {
                for (let i = 0; i <  elem.getElementsByClassName("pools-preview").length; i++) {
                    elem.getElementsByClassName("pools-preview")[i].style.display = "none";
                    elem.getElementsByClassName("pools-preview")[i].classList.remove("poolsPreview");
                }
            }

            if (elem.getElementsByClassName("jobs_section")[0] != undefined) {
                for (let i = 0; i <  elem.getElementsByClassName("jobs_section").length; i++) {
                    elem.getElementsByClassName("jobs_section")[i].style.display = "block";
                }

                for (let i = 0; i <  elem.getElementsByClassName("job-list-live").length; i++) {
                    elem.getElementsByClassName("job-list-live")[i].style.display = "none";
                }

                for (let i = 0; i <  elem.getElementsByClassName("custom-job-card-content").length; i++) {
                    elem.getElementsByClassName("custom-job-card-content")[i].style.display = "block";
                }
            }
            if (elem.getElementsByClassName("pool_section")[0] != undefined) {
                for (let i = 0; i <  elem.getElementsByClassName("pool_section").length; i++) {
                    elem.getElementsByClassName("pool_section")[i].style.display = "block";
                }

                for (let i = 0; i <  elem.getElementsByClassName("pool-list-live").length; i++) {
                    elem.getElementsByClassName("pool-list-live")[i].style.display = "none";
                }

                for (let i = 0; i <  elem.getElementsByClassName("custom-pool-card-content").length; i++) {
                    elem.getElementsByClassName("custom-pool-card-content")[i].style.display = "block";
                }
            }
        });

        editor.render();
        editor.Panels.addPanel({
            id: 'basic-actions',
            el: '.panel__basic-actions',
            buttons: [
                {
                    id: 'alert-button',
                    className: 'btn-alert-button',
                    label: 'Click my butt(on)',
                    command(editor) {
                        alert('Hello World');
                    }
                }
            ]
        });

        editor.Panels.addButton('devices-c', [{
            id: 'dashboard',
            className: 'fa fa-home font-22',
            command: function(editor, sender) {
                if (localStorage.getItem('isTemplateSaved') == 1) {
                    navigate("/candidate/homepage");
                    localStorage.removeItem('isTemplateSaved');
                } else {
                    let text = "Reload Site?\nChanges you made may not be saved.";
                    if (URLPath === '/candidate/template') {
                        const leavePage = window.confirm(text);
                        if (leavePage) {
                            navigate("/candidate/homepage");
                            localStorage.removeItem('isTemplateSaved');
                        }
                    }
                }
                // navigate("/candidate/homepage");
            },
            attributes: { title: 'Dashboard' } }]);
        // const am = editor.AssetManager;
        // am.open({
        //     types: ['image'], // This is the default option
        //     // Without select, nothing will happen on asset selection
        //     select(asset, complete) {
        //       const selected = editor.getSelected();

        //       if (selected && selected.is('image')) {
        //         selected.addAttributes({src: asset.getSrc() });
        //         // The default AssetManager UI will trigger `select(asset, false)`
        //         // on asset click and `select(asset, true)` on double-click
        //         complete && am.close();
        //       }
        //     }
        //    });
        const pn = editor.Panels;
        const assets = editor.AssetManager;
        assets.add(['http://localhost:3001/images/**/*.{jpg, png, gif}']);
        const panelOpts = pn.addPanel({
            id: 'options'
        });
        const panelViews = pn.addPanel({
            id: "views"
          });
          panelViews.get("buttons").add([
            {
              attributes: {
                title: "Open Code"
              },
              className: "fa fa-file-code-o",
              command: "open-code",
              togglable: true,
              id: "open-code"
            }
          ]);
        // setting tooltips to icons
        pn.getButton('options', 'sw-visibility').set('attributes', { title: "View Components" });
        pn.getButton('options', 'export-template').set('attributes', { title: "View Code" });
        pn.getButton('options', 'redo').set('attributes', { title: "Redo" });
        pn.getButton('options', 'undo').set('attributes', { title: "Undo" });
        pn.getButton('options', 'canvas-clear').set('attributes', { title: "Delete" });
        pn.getButton('options', 'gjs-open-import-webpage').set('attributes', { title: "Import" });
        pn.getButton('devices-c', 'set-device-desktop').set('attributes', { title: "Desktop" });
        pn.getButton('devices-c', 'set-device-tablet').set('attributes', { title: "Tablet" });
        pn.getButton('devices-c', 'set-device-mobile').set('attributes', { title: "Mobile" });
        // console.log(editor.Panels.getPanels().forEach(item => console.log(item.get('id'))))
        // pn.addButton('options', {
        //     id: 'open-templates',
        //     className: 'fa fa-folder-o',
        //     attributes: {
        //         title: 'Open projects and templates'
        //     },
        //     command: 'open-templates', //Open modal
        // });
        // pn.addButton('views', {
        //     id: 'open-pages',
        //     className: 'fa fa-file-image-o',
        //     attributes: {
        //         title: 'Take Screenshot'
        //     },
        //     command: 'open-pages',
        //     togglable: false
        // });
        panelOpts.get('buttons').add([{
            attributes: {
                title: 'Save As Template'
            },
            className: 'fa fa-floppy-o',
            command(editor) {
                if (localStorage.getItem('template_id')) {
                    updateTemplate(editor);
                } else {
                    setOpenSaveModal(true);
                    setTemplateCss(editor.getCss());
                    setTemplateHtml(getStructuredHtml(editor.getHtml()));
                }
            }, //Save page as template
            id: 'save-as-template'
        }, {
            attributes: {
                title: 'Publish'
            },
            className: 'fa fa-rocket',
            // command: 'publish',//Take an image of the canvas
            id: 'publish',
            command(editor) {
                if (localStorage.getItem('template_id')) {
                    // updateTemplate(editor, true);
                    getWebsiteDetails(true);
                    setPublished_html(editor);
                    // setOpenPublishModal(true);
                }  else {
                    getWebsiteDetails(true);
                    // setOpenPublishModal(true);
                }
            },
        }]);
    }, []);

    const handleClosePublish = () => {
        setOpenPublishModal(false);
        getWebsiteDetails();
        setUsername("");
        setPassword("");
        setUsername_error("");
        setPassword_error("");
        setPublishMode("N");
        reset();
    };
    const SaveDetails = (url) => {
        setIsLoading(true);
        let data = {
            careerPortalDescription: getValues("description"),
            careerPortalTitle: getValues("title"),
            id: localStorage.getItem("client_name"),
            careerPortalFavicon: typeof(file) != "object" ? file : url,
            establishmentName: localStorage.getItem("enterprise_name"),
            careerPortalGtmCode: getValues("pixelKey")
        };
        if (publishMode == "N") {
            data["careerPortalDomain"] = getValues("url");
        }
        // let formData = new FormData();
        // formData.append("", data);
        ApiCalls.put(`enterprises/career-portal`, data)
            .then((res) => {
                var formData = new FormData();
                if (publishMode == "N") {
                    formData.append("domain", data?.careerPortalDomain?.trim());
                } else {
                    formData.append("domain", getValues("url").trim());
                }
                formData.append("enterprise_template_id", localStorage.getItem('enterprise_template_id'));
                formData.append("title", data.careerPortalTitle.trim());
                formData.append("description", data.careerPortalDescription);
                formData.append("favicon_url", data.careerPortalFavicon);
                formData.append("candidate_portal_url", process.env.REACT_APP_CANDIDATE_PORTAL_URL);
                formData.append("careerPortalGtmCode", data.careerPortalGtmCode);
                formData.append("isPreview", publishMode);
                formData.append("username", username);
                formData.append("password", password);
                if (!isEmptyHtml) {
                    EditorApiCalls.post(`/templates/publish`, formData)
                    .then((response) => {
                        getWebsiteDetails();
                        setIsLoading(false);
                        navigate('../PublishSucess', {
                            state: {
                                "published_url": response.data.data.published_url
                            }
                        });
                        localStorage.setItem('is_publishing', true);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (!localStorage.getItem('enterprise_template_id')) {
                            danger('Please save template before publish.');
                            handleClosePublish();
                        } else {
                            danger('Something went wrong');
                        }
                    });
                } else {
                    danger('You have not created any template. Please create and then try to publish.');
                    handleClosePublish();
                }
            }).catch(function (error) {
                if (error?.statusCode != 500) {
                    danger(error);
                }
                setIsLoading(false);
            });
    };
    const getFaviconUrl = () => {
        if (file) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("file", file);
            formData.append("type", "CAREER_PORTAL_FAVICON");
            ApiCalls.post(`upload-file/enterprises/${localStorage.getItem("client_name")}`, formData)
                .then((response) => {
                    SaveDetails(response.url);
                })
                .catch(function (error) {
                    danger("Maximum upload size exceeded. Try to upload a file within 10MB");
                    setIsLoading(false);
                });
        } else {
            setFaviconError(true);
        }
    };
    const getWebsiteDetails = (isPublish) => {
        setIsLoading(true);
        let header = {
            authorization: "Bearer " + KeycloakService.getToken(),
            'x-access-token': KeycloakService.getIdToken(),
            "enterpriseId": localStorage.getItem("enterprise_id") ? localStorage.getItem("enterprise_id") : null,
        };
        let chid_header = {
            authorization: "Bearer " + KeycloakService.getToken(),
            'x-access-token': KeycloakService.getIdToken(),
            'x-enterprise-id': localStorage.getItem('childEnterpriseId'),
            "enterpriseId": localStorage.getItem("enterprise_id") ? localStorage.getItem("enterprise_id") : null,
          };
        axios.get(`${process.env.REACT_APP_API_URL}enterprises/career-portal`, {
            headers: localStorage.getItem('childEnterpriseId') ? chid_header : header
          })
            .then((res) => {
                setDetails(res.data.responsePayload);
                setValue("title", res.data.responsePayload.careerPortalTitle);
                setValue("url", res.data.responsePayload.careerPortalDomain);
                setValue("description", res.data.responsePayload.careerPortalDescription);
                setValue("pixelKey", res.data.responsePayload.careerPortalGtmCode);
                setFile(res.data.responsePayload.careerPortalFavicon);
                if (isPublish == true) {
                    setOpenPublishModal(true);
                }
                setIsLoading(false);
            }).catch(function (error) {
                if (error?.statusCode != 500) {
                    danger(error);
                }
                setIsLoading(false);
            });
    };
    // useEffect(() => {
    //     if (openPublishModal) {
    //         getWebsiteDetails();
    //     }
    // }, [openPublishModal]);
    return (
        <>
        <Helmet>
        {localStorage.getItem("appOrigin") === "N" ? <title>Career Portal | {Constant.APP_NAME_NEURODIVERSIFY}</title>:
            localStorage.getItem("appOrigin") === "H" ? <title>Career Portal | {Constant.APP_NAME_DIRECT_HEALTH}</title> : <title>Career Portal | {Constant.APP_NAME_GENERAL}</title>}
            <meta name="description" content={"Career Portal" | `${Constant.APP_NAME_GENERAL}`} />
        <link
          rel={Constant.APP_NAME_GENERAL}
          href="https://candidate-dsaas.simplifycareers.com/"
        />
      </Helmet>
            {isLoading && <Loader />}
            <div id="gjs"></div>
            {/* Publish modal */}
            <Modal
                open={openPublishModal}
                onClose={(e) => handleClosePublish}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-content inputcontrasts">
                    <Typography id="modal-title" className="d-flex align-between">
                        <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                            Publish your career site {publishMode == "Y" ? "(preview mode)" : ""}
                        </Typography>
                        <Typography align="right" className="cursor-hover sub-text-color" aria-label="Close icon">
                            <CloseIcon onClick={handleClosePublish} onKeyPress={handleClosePublish} tabIndex={0} aria-label="Close icon" />
                        </Typography>
                    </Typography>
                    <Typography className="f-16 font-weight-400 mt-10 mb-20">
                        Add title, domain, description and the favicon below for your website.
                    </Typography>
                    <Grid item>
                        <Grid item id="modal-description" className="p-0">
                            <Grid container >
                                <Grid item xs={12} lg={12} md={12}>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box>
                                            <Typography className="small-text-font mb-0 mt-10 font-weight-500">
                                                Website Title*
                                            </Typography>
                                            <Input
                                                type="text"
                                                name="title"
                                                id="title"
                                                className={`form-control input-group m-b-0 inputcontrasts ${_errors.title ? "is-invalid" : ""
                                                    }`}
                                                margin="normal"
                                                placeholder="Enter title"
                                                onKeyPress={handleSpaces}
                                                {...register("title")}
                                            />
                                            <span className="danger-color error-msg" style={{ textAlign: "left" }}>
                                                {" "}
                                                {_errors.title && _errors.title.message}
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid container spacing={1} lg={12} md={12} xs={12} className="">
                                        <Box className="mt-15" style={{ "paddingLeft": "8px" }}>
                                            <Typography className="small-text-font mb-5 mt-15 font-weight-500">
                                                Publish Mode
                                            </Typography>
                                            <Typography className="f-12 gray7 mt-5 font-weight-400">
                                                Choose 'Preview' for restricted access with credentials, or 'Live' for a publicly accessible page.
                                            </Typography>
                                        </Box>
                                        <Grid item lg={9} md={9} xs={9} className="pt-5" style={{ "paddingLeft": "9px" }}>
                                            <Box>
                                                <Typography className="mr-10 candidate-list-align sort-filter-center talent-poolFilter">
                                                    <Box className="inputGroup d-flex align-center matching-details-align">
                                                    <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    className=""
                                                    value={publishMode}
                                                    onChange={(event)=> {
                                                        setPublishMode(event.target.value);
                                                        if (event.target.value == "N") {
                                                            setUsername("");
                                                            setPassword("");
                                                            setUsername_error("");
                                                            setPassword_error("");
                                                            setValue("url", details?.careerPortalDomain);
                                                            clearErrors('url');
                                                        } else {
                                                            // setTimeout(() =>
                                                            //     document.getElementById("modal-description")?.scrollTo({
                                                            //         top: document.getElementById("modal-description").scrollHeight,
                                                            //         behavior: 'smooth',
                                                            //     }), 600
                                                            // );
                                                            setValue("url", "");
                                                            clearErrors('url');
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value="Y"
                                                        className="notes-radio gray11"
                                                        control={
                                                            <Radio
                                                            tabindex={0}
                                                            sx={{
                                                                color: "#98A2B3",
                                                                "&.Mui-checked": {
                                                                color: getComputedStyle(
                                                                    document.documentElement
                                                                ).getPropertyValue("--primary-color"),
                                                                },
                                                            }}
                                                            />
                                                        }
                                                        label="Preview"
                                                        />
                                                        <FormControlLabel
                                                        value="N"
                                                        className="notes-radio gray11"
                                                        control={
                                                            <Radio
                                                            tabindex={0}
                                                            sx={{
                                                                color: "#98A2B3",
                                                                "&.Mui-checked": {
                                                                color: getComputedStyle(
                                                                    document.documentElement
                                                                ).getPropertyValue("--primary-color"),
                                                                },
                                                            }}
                                                            onKeyDown={(e) => {
                                                                // if (e.key === "Enter") {
                                                                //   ThemeHandler({ target: { value: "Default" } }, true);
                                                                // }
                                                            }}
                                                            />
                                                        }
                                                        label="Live"
                                                        />
                                                    </RadioGroup>
                                                    </Box>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {publishMode == "Y" && <Grid container spacing={1} lg={12} md={12} xs={12} id="auth-fields">
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Box>
                                            <Typography className="small-text-font mb-0 mt-10 font-weight-500">
                                                Username*
                                            </Typography>
                                            <Input
                                                type="text"
                                                name="username"
                                                id="username"
                                                className={`form-control input-group m-b-0 inputcontrasts ${username_error ? "is-invalid" : ""
                                                    }`}
                                                margin="normal"
                                                placeholder="Enter username"
                                                onChange={(e) => {
                                                    setUsername(e.target.value);
                                                    if (e.target.value.length == 0) {
                                                        setUsername_error("Username is required");
                                                    } else if (e.target.value.length > 128) {
                                                        setUsername_error("Exceeded maximum character length of 128");
                                                    } else {
                                                        setUsername_error("");
                                                    }
                                                }}
                                                onKeyPress={handleSpaces}
                                            />
                                            <span className="danger-color error-msg" style={{ textAlign: "left" }}>
                                                {" "}
                                                {username_error}
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Box>
                                            <Typography className="small-text-font mb-0 mt-10 font-weight-500">
                                               Password*
                                            </Typography>
                                            <Input
                                                name="password"
                                                id="password"
                                                className={`form-control input-group m-b-0 inputcontrasts ${password_error ? "is-invalid" : ""
                                                    }`}
                                                margin="normal"
                                                placeholder="Enter password"
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                    if (e.target.value.length == 0) {
                                                        setPassword_error("Please enter your password");
                                                    } else if (e.target.value.length < 8) {
                                                        setPassword_error("The password must be at least 8 characters.");
                                                    } else {
                                                        setPassword_error("");
                                                    }
                                                }}
                                                onKeyPress={handleSpaces}
                                                type={showPassword ? "text" : "password"}
                                                endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="show-hide-password"
                                                                onClick={handleClickShowPassword}
                                                                onKeyPress={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                sx={{
                                                                    '& .MuiTouchRipple-root': { position: "static" }
                                                                }}
                                                                tabIndex="0"
                                                            >
                                                                {showPassword ? (
                                                                    <Visibility aria-label="Visibility icon" tabIndex="-1" />
                                                                ) : (
                                                                    <VisibilityOffIcon aria-label="Visibilityoff icon" tabIndex="-1" />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                            />
                                            <span className="danger-color error-msg" style={{ textAlign: "left" }}>
                                                {" "}
                                                {password_error}
                                            </span>
                                        </Box>
                                    </Grid>
                                    </Grid>}
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box>
                                            <Typography className="small-text-font mb-0 mt-20 font-weight-500">
                                                Website URL*
                                            </Typography>
                                            <Input
                                                type="text"
                                                name="Websiteurl"
                                                id="Websiteurl"
                                                className={`form-control input-group m-b-0 inputcontrasts ${_errors.url ? "is-invalid" : ""
                                                    }`}
                                                autoComplete="off"
                                                margin="normal"
                                                placeholder="Enter URL"
                                                onKeyPress={handleSpaces}
                                                {...register("url")}
                                            />
                                            <span className="danger-color error-msg" style={{ textAlign: "left" }}>
                                                {" "}
                                                {_errors.url && _errors.url.message}
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box>
                                            <Typography className="small-text-font mb-0 mt-20 font-weight-500 f-14 d-flex align-center">
                                            Google Tag Manager - Analytics ID
                                            <Tooltip title="Enter the analytics ID that Google Tag Manager provided. We will insert that ID into the pixel on your portal. Instead of adding the complete script, enter the ID, for example. GTM-TDC0QQM0."
                                            placement="right"
                                            arrow>
                                            <Box>
                                                <IconButton className="d-block p-0" style={{ marginLeft: '3px' }} aria-label="Info icon">
                                                    <InfoIcon aria-label="Info icon" style={{ width: '0.7em', height: '0.7em' }}/>
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                            </Typography>
                                            <Input
                                                type="text"
                                                name="pixelKey"
                                                id="pixelKey"
                                                className={`form-control input-group m-b-0 inputcontrasts ${_errors.pixelKey ? "is-invalid" : ""
                                                    }`}
                                                margin="normal"
                                                placeholder="Enter analytics Id"
                                                onKeyPress={handleSpaces}
                                                {...register("pixelKey")}
                                            />
                                            <span className="danger-color error-msg" style={{ textAlign: "left" }}>
                                                {" "}
                                                {_errors.pixelKey && _errors.pixelKey.message}
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} xs={12} className="portfolio-zoom-align">
                                        <Typography className="input-focus-none placeholder-text">
                                            <p className="small-text-font mb-0 mt-20 font-weight-500 align-left">
                                                Website Description
                                            </p>
                                            <TextareaAutosize
                                                minRows={3}
                                                maxRows={4}
                                                aria-label="maximum height"
                                                className={`form-control input-group inputcontrasts ${_errors.description ? "is-invalid" : ""
                                                    }`}
                                                maxLength={1000}
                                                inputProps={{ maxLength: 1000 }}
                                                {...register("description")}
                                                //   onChange={(e) => {
                                                //     if (e.target.value.length === 1) {
                                                //       setAboutMeInfo(e.target.value.toUpperCase());
                                                //       return;
                                                //     }
                                                //     setAboutMeInfo(e.target.value);
                                                //   }}
                                                onKeyPress={handleSpaces}
                                                name="description"
                                                id="description"
                                                //   defaultValue={aboutMe}
                                                placeholder="Enter description"
                                            />
                                        </Typography>
                                        <span className="danger-color error-msg" style={{ textAlign: "left" }}>
                                            {" "}
                                            {_errors.description && _errors.description.message}
                                        </span>
                                    </Grid>
                                    <Grid container spacing={1} lg={12} md={12} xs={12} className="">
                                        <Grid item lg={9} md={9} xs={9}>
                                            <Box>
                                                <Typography className="small-text-font mb-10 mt-10 font-weight-500">
                                                    Favicon*
                                                </Typography>
                                                <Typography className="mr-10 candidate-list-align sort-filter-center talent-poolFilter" onClick={() => uploadFavicon()}>
                                                    <Box className="inputGroup d-flex align-center matching-details-align">
                                                        <TextField
                                                            type="search"
                                                            name="searhKeyword"
                                                            className="inputcontrasts position-issue postJob-search search-id w-100"
                                                            variant="outlined"
                                                            margin="normal"
                                                            placeholder="Search favicons"
                                                            inputProps={{ autoComplete: 'off' }}
                                                            sx={{ minWidth: "280px" }}
                                                            // careerPortalFavicon
                                                            value={file?.name ? file.name : file && decodeURIComponent(
                                                                file?.split("/").pop().split("?")[0])}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Typography className="search-byFavicon">
                                                                            <input type="file"
                                                                                accept=".png, .jpg, .jpeg, .gif, .svg"
                                                                                ref={fileRef}
                                                                                style={{ display: "none" }}
                                                                                onChange={handleOnChange}
                                                                                onClick={changeFile}
                                                                            />
                                                                            <Button className="btn primary-button" aria-label="Search icon" disableRipple={true}>Browse
                                                                            </Button>
                                                                        </Typography>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                    <span className="danger-color error-msg" style={{ textAlign: "left" }}>{" "} {faviconError && "Favicon is required"}</span>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item id="modal-footer" className=" mt-30">
                            <Typography className="justify-center d-flex">
                                <Button
                                    type="submit"
                                    className="btn primary-button profileButton"
                                    variant="contained"
                                    aria-label="Submit"
                                    autoCorrect="off"
                                    disabled={file == undefined || watch("url") == "" || !_isValid || (publishMode == "Y" && (password_error || username_error || !password || !username))}
                                    onClick={() => {
                                        localStorage.getItem('template_id') ? updateTemplate(published_html, true) : (typeof(file) != "object" ? SaveDetails() : getFaviconUrl());
                                    }}
                                    // onClick={() => {typeof(file) != "object" ? SaveDetails() : getFaviconUrl()}}
                                >
                                    {publishMode == "Y" ? "Preview" : "Publish"}
                                </Button>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        {/* Save modal */}
            <Modal
                open={openSaveModal}
                onClose={handleCloseSaveModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid item className="modal-content p-20 inputcontrasts">
                    <Grid container item spacing={1} lg={12} md={12} xs={12}>
                        <Grid item lg={6} md={6} xs={6} align="left">
                            <Typography className="f-20 font-weight-600 modalTitle heading-text-color" variant="h5">
                                Save As
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={6} align="right" className="cursor-hover sub-text-color">
                            <Typography className="icon-button">
                                <CloseIcon onClick={handleCloseSaveModal} onKeyPress={handleCloseSaveModal} tabIndex={0} aria-label="Close icon" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className="mt-15">
                        <Grid container spacing={1}>
                            <Grid item lg={6} md={6} xs={6}>
                                <Box>
                                    <Typography className="small-text-font mb-0 mt-20" align="left">
                                    Template Name*
                                    </Typography>
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className={`form-control input-group m-b-0 inputcontrasts ${_error ? "is-invalid" : ""
                                                    }`}
                                        margin="normal"
                                        placeholder="Enter template name"
                                        value={templateName}
                                        onChange={(event) => {
                                            setTemplateName(event.target.value);
                                            if (event.target.value.length > 128) {
                                                setError("Exceeded maximum character length of 128.");
                                            } else if (event.target.value.length == 0) {
                                                setError("Template name is required.");
                                            } else {
                                                setError("");
                                            }
                                        }}
                                        onKeyPress={handleSpaces}
                                    />
                                </Box>
                                <span className="danger-color error-msg text-left">
                                    {" "}{_error}
                                </span>
                            </Grid>
                            <Grid item lg={6} md={6} xs={6}>
                                <Box>
                                    <Typography className="small-text-font mb-0 mt-20" align="left">
                                        Template Overview*
                                    </Typography>
                                    <Input
                                        type="text"
                                        name="overview"
                                        id="overview"
                                        className={`form-control input-group m-b-0 inputcontrasts ${_overviewError ? "is-invalid" : ""
                                                    }`}
                                        margin="normal"
                                        placeholder="Enter template overview"
                                        value={templateOverview}
                                        onChange={(event) => {
                                            setTemplateOverview(event.target.value);
                                            if (event.target.value.length > 256) {
                                                setOverviewError("Exceeded maximum character length of 256.");
                                            } else if (event.target.value.length == 0) {
                                                setOverviewError("Template overview is required.");
                                            } else {
                                                setOverviewError("");
                                            }
                                        }}
                                        onKeyPress={handleSpaces}
                                    />
                                </Box>
                                <span className="danger-color error-msg text-left">
                                    {" "}{_overviewError}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container className="mt-15">
                            <Grid item lg={12} md={12} xs={12}>
                                <Box className='m-b-model-label'>
                                    <Typography className="f-14 d-flex align-center">
                                        Tags <span className="text-black">*</span>{" "}
                                        <Tooltip title="Use relevant tags to describe your template and press Enter" placement="right">
                                            <Box>
                                                <IconButton className="d-block p-0" style={{ marginLeft: '3px' }} aria-label="Info icon">
                                                    <InfoIcon aria-label="Info icon" style={{ width: '0.7em', height: '0.7em' }}/>
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </Typography>
                                    <Input
                                        style={{ height: "47px" }}
                                        type="text"
                                        name="tags"
                                        id="tags"
                                        className={`form-control input-group ${_tagError ? "is-invalid" : ""
                                                    }`}
                                        margin="normal"
                                        placeholder="Enter tags"
                                        value={tag}
                                        onChange={handleInputChange}
                                        onKeyDown={handleInputKeyDown}
                                        onKeyPress={handleSpaces}
                                    />
                                </Box>
                                <span className="danger-color error-msg text-left">
                                    {" "}{_tagError}
                                </span>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12} align="left">
                                <Box className="mt-5 campaign-create">
                                    {chipData && chipData.map((chip) => (
                                        <Chip
                                            key={chip}
                                            label={chip}
                                            onDelete={handleDelete(chip)}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography className="mt-35 justify-end d-flex align-center">
                            <Button
                                type="submit"
                                className="btn profile-button mr-10"
                                variant="contained"
                                aria-label="Submit"
                                onClick={handleCloseSaveModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className="btn primary-button profileButton"
                                variant="contained"
                                aria-label="Submit"
                                disabled={!templateName || !chipData.length || !templateOverview || _error || _overviewError || isSubmitted}
                                onClick={saveTemplate}
                            >
                                Save
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
}
export default BlankTemplate;
